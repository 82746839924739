import { useState, useEffect } from 'react';
import { Card, Input, Button, Form, message, List } from 'antd';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import UserService from '../../../../services/user.service';
const { Content } = Layout;

const AdminQuestionTopicEntry = () => {
  const [form] = Form.useForm();
  const [topics, setTopics] = useState([]);

  const handleFormSubmit = (values: any) => {
    try {
      const endpoint = UserService.PostRecord(values, 'quize/topic');
      endpoint.then(
        (response) => {
          message.success('Question added successfully');
          form.resetFields();
          fetchTopics();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
        }
      );
    } catch (error) {
      console.error(error);
      message.error('Failed to add question');
    }
  };
  const fetchTopics = async () => {
    try {
      const endpoint = UserService.GetRecord('quize/topic');
      endpoint.then(
        (response) => {
          setTopics(response.data.data);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
          setTopics([]);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteTopic = async (topicId: number) => {
    try {
      const endpoint = UserService.DeleteRecord(`quize/topic/${topicId}`);
      endpoint.then(
        (response) => {
          message.success('Question topic deleted successfully');
          fetchTopics();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
          setTopics([]);
        }
      );
      fetchTopics(); // Fetch updated topics after deleting one
    } catch (error) {
      console.error(error);
      message.error('Failed to delete question topic');
    }
  };
  useEffect(() => {
    fetchTopics();
  }, []);
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Quize</h1>
            <Card
              title="Subjects Entry"
              style={{ width: '600px', margin: '20px auto' }}
            >
              <Form form={form} onFinish={handleFormSubmit}>
                <Form.Item
                  label="Question Topic"
                  name="topic"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a question topic',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
            <Card
              title="List of Topics"
              style={{ width: '600px', margin: '20px auto' }}
            >
              <List
                dataSource={topics}
                renderItem={(item: any) => (
                  <List.Item>
                    {item.name}
                    <Button type="link">
                      <Link to={`/admin-quize/${item.name.replace(/ /g, '-')}`}>
                        Add Question
                      </Link>
                    </Button>
                    <Button
                      type="link"
                      danger
                      onClick={() => handleDeleteTopic(item.id)}
                    >
                      Delete Topic
                    </Button>
                  </List.Item>
                )}
              />
            </Card>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminQuestionTopicEntry;
