// WarehouseLocationPage.tsx

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Card, Layout,message } from 'antd';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import UserService from '../../../services/user.service';

const { Content } = Layout;

interface Warehouse {
  id: number;
  name: string;
  location: string;
}

const WarehouseLocationPage: React.FC = () => {
  const [data, setData] = useState<Warehouse[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [warehouseID, setWarehouseID] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // const response = await axios.get<{ data: Warehouse[] }>('/api/warehouse');
      UserService.GetRecord('inventory').then(
        (result: any) => {
          if (result.data) {
            setData(result.data.data);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            message.error(resMessage)
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (record: Warehouse) => {
    form.setFieldsValue(record);
    setModalVisible(true);
    setWarehouseID(record.id)

  };

  const add = async () => {
    setWarehouseID(0)
    setModalVisible(true)
  }
    const handleDelete = async (id: number) => {
    try {
      UserService.DeleteRecord(`inventory/${id}`).then(
        (result: any) => {
          message.success(result.data.message)
            fetchData();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            message.error(resMessage)

        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleModalOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as Warehouse;
      if(warehouseID===0){
        UserService.PostRecord(values,`inventory`).then(
          (result: any) => {
            message.success(result.data.message)
            setModalVisible(false);
            fetchData()
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              message.error(resMessage)

          }
        );
      }else{
        UserService.UpdateRecord(values,`inventory/${warehouseID}`).then(
          (result: any) => {
            message.success(result.data.message)
            setModalVisible(false);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              message.error(resMessage)

          }
        );
      }

      fetchData();
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  return (
    <div>
      <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <CustomSideBar />
          <Layout className="site-layout">
            <TopBar />
            <Content style={{ margin: '20px' }}>
              <div className="centered-container">
                <Button onClick={() => add()}>Add Warehouse</Button>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '16px' }}>
                  {data.map((warehouse) => (
                    <Card
                      key={warehouse.id}
                      title={warehouse.name}
                      style={{ width: 300, margin: '16px' }}
                    >
                      <p>Location: {warehouse.location}</p>

                      <Button onClick={() => handleDelete(warehouse.id)}>Delete</Button>
                      <Button onClick={() => handleEdit(warehouse)}>Edit</Button>
                    </Card>
                  ))}
                </div>
                <Modal
                  title={warehouseID>0?"Edit Warehouse":"Add Warehouse"}
                  visible={modalVisible}
                  onOk={handleModalOk}
                  onCancel={() => setModalVisible(false)}
                >
                  <Form form={form} layout="vertical">

                    <Form.Item label="Name" name="name">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Location" name="location">
                      <Input />
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

export default WarehouseLocationPage;
