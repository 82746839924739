import React, { useState, useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  Alert,
  Select,
  List,
  Space,
  Popconfirm,
  theme,
  Pagination,
  Layout,
  Modal,
  Table,
} from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import type { ColumnsType } from 'antd/es/table';
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const { Search } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
interface DataTypeTable {
  key: React.Key;
  name: string;
  status: string;
  measurement: string;
  createdBy: string;
  price: string;
}
const ItemComponent: React.FC = (props) => {
  const [message, setMessage] = useState('');
  const [ActivitySubmitting, setActivitySubmitting] = useState(false);
  const [form] = Form.useForm();
  const [itemData, setItemData] = useState<DataTypeTable[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPageActivity] = useState(1);
  const [submitBadge, setSubmitBadge] = useState('error');
  const [PageLoaded, setPageLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const pageSize: number = 10;
  const { token } = theme.useToken();
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  const handleSubmit = (value: any, page: string) => {
    UserService.PostRecord(value, page).then(
      (response) => {
        form.resetFields();
        LoadData();
        setMessage(page + ' added Successfully');
        setSubmitBadge('success');
        setIsOpen(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSubmitBadge('error');
      }
    );
  };
  const handleEdit = (data: any) => {
    setIsOpen(true);
    setSelectedItem(data.key);
    form.setFieldsValue({
      name: data.name,
      status: data.status,
      measurement: data.measurement,
      price:data.price,
    });
  };
  const handleDelete = (key: any) => {
    UserService.DeleteRecord('items/' + key).then(
      (response) => {
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSubmitBadge('error');
      }
    );
  };

  const handleUpdate = (value: any, page: string) => {
    UserService.UpdateRecord(value, page + selectedItem).then(
      (response) => {
        form.resetFields();
        setMessage(value + ' updated');
        setSubmitBadge('success');
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSubmitBadge('error');
      }
    );
  };
  const LoadData = (search?: string) => {
    const searchurl = search ? 'items?search=' + search : 'items';
    UserService.GetRecord(searchurl).then(
      (result) => {
        if (result.data) {
          setTotalData(result.data.data.length);
          setItemData(
            result.data.data.map((option: any) => ({
              key: option.id,
              name: option.name,
              status: option.status,
              price: option.price,
              measurement: option.measurement,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setItemData([]);
      }
    );
  };
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  useEffect(() => {
    LoadData();
    setPageLoaded(true);
  }, []);
  const searchItem: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const search = e.target.value;
    LoadData(search);
  };
  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel header={option.name} key={index} style={panelStyle}>
            <List
              size="small"
              footer={
                <>
                  <Space size="middle">
                    <Button type="primary" onClick={() => handleEdit(option)}>
                      Edit
                    </Button>
                    <Button danger onClick={() => handleDelete(option.key)}>
                      Delete
                    </Button>
                  </Space>
                </>
              }
              dataSource={[
                <>
                  <p>Measurment: {option.measurement}</p>
                  <p>Status: {option.status}</p>
                </>,
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  const columns: ColumnsType<DataTypeTable> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Measurement',
      dataIndex: 'measurement',
      key: 'measurement',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      key: 'key',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button danger onClick={() => handleDelete(record.key)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Space>
                  {message && submitBadge === 'success' && (
                    <Alert
                      message="Success"
                      description={message}
                      type="success"
                      closable
                      onClose={onClose}
                    />
                  )}
                  {message && submitBadge === 'error' && (
                    <Alert
                      message="Error"
                      description={message}
                      type="error"
                      closable
                      onClose={onClose}
                    />
                  )}
                </Space>
                <Button onClick={showModal}>Add Item</Button>
                <>
                  <Modal
                    title="Manage Commodity"
                    open={isOpen}
                    onCancel={handleCancel}
                    footer={[
                      <Button onClick={handleCancel}>Cancel</Button>,
                      <Button
                        loading={ActivitySubmitting}
                        key="submit"
                        type="primary"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              selectedItem === 0
                                ? handleSubmit(values, 'items')
                                : handleUpdate(values, 'items/');
                            })
                            .catch((info) => {});
                        }}
                      >
                        Save
                      </Button>,
                    ]}
                  >
                    <Form
                      {...formItemLayout}
                      form={form}
                      scrollToFirstError
                      style={{ maxWidth: 600 }}
                      className="formEdit"
                    >
                      <Form.Item
                        label="Item Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: 'Please input Name!',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Price"
                        name="price"
                        rules={[
                          {
                            required: true,
                            message: 'Please input Price!',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                          { required: true, message: 'Please select Status!' },
                        ]}
                      >
                        <Select placeholder="select Item Status">
                          <Option value="Enable">Enable</Option>
                          <Option value="Disable">Disable</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="measurement"
                        label="Measurement"
                        rules={[
                          {
                            required: true,
                            message: 'Please select measurement!',
                          },
                        ]}
                      >
                        <Select placeholder="select Item Measurement">
                          <Option value="Unit">Unit</Option>
                          <Option value="Kilogram">Kilogram</Option>
                          <Option value="Litre">Litre</Option>
                          <Option value="Gram">Gram</Option>
                          <Option value="Meter">Meter</Option>
                          <Option value="Inches">Inches</Option>
                          <Option value="Yard">Yard</Option>
                          <Option value="Gallon">Gallon</Option>
                          <Option value="Ounces">Ounces</Option>
                          <Option value="Centimeter">Centimeter</Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <Input
                    style={{ margin: '20px' }}
                    className="selcteAccountNo"
                    placeholder="Search Item"
                    onChange={searchItem}
                  />
                  {isMobile && (
                    <>
                      {renderPage(
                        itemData.slice(
                          (currentPage - 1) * pageSize,
                          currentPage * pageSize
                        ),
                        'items'
                      )}
                      <Pagination
                        total={totalData}
                        pageSize={pageSize}
                        current={currentPage}
                        onChange={(page) => setCurrentPageActivity(page)}
                      />
                    </>
                  )}
                  {!isMobile && (
                    <Table columns={columns} dataSource={itemData} />
                  )}
                </>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default ItemComponent;
