import {
  Button,
  Popconfirm,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Select,
  Modal,
  Alert,
  theme,
  Collapse,
  Layout,
  List,
  Pagination,message as alertmessage
} from 'antd';
import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import { CaretRightOutlined } from '@ant-design/icons';
import { formatDate } from '../../../common/utility';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { Link } from 'react-router-dom';
const { Content } = Layout;

const { Option } = Select;
const { Column, ColumnGroup } = Table;
interface DataType {
  key: React.Key;
  firstName: string;
  lastName: string;
  phone: number;
  email: string;
  status: string;
  roleID: string;
  AgentID: string;
  farmers?: number;
  FarmerMonitor?: number;
  state?: string;
  warehouse?:string;
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
interface Warehouse {
  value: string;
  label: string;
}
const UserPage: React.FC = () => {
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [roles, setRoles] = useState<{ value: string; label: string }[]>([]);
  const [state, setState] = useState<{ value: string; label: string }[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const [defaultdata, setDefaultData] = useState();
  const { token } = theme.useToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [iseditModalVisible, setIseditModalVisible] = useState(false);
  const [submitTitle, setSubmitTitle] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [userID, setUserID] = useState(0);
  const [isLoadingContent, SetisLoadingContent] = useState(true);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [loadingBank, setLoadingBank] = useState(true);
  const [bank, setBank] = useState<{ value: string; label: string }[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [warehoseData, setWarehoseData] = useState<Warehouse[]>([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const showModal = () => {
    setOpen(true);
  };
  const { Panel } = Collapse;
  const getLocation = async () => {
    try {
      // const response = await axios.get<{ data: Warehouse[] }>('/api/warehouse');
      UserService.GetRecord('inventory').then(
        (result: any) => {
          if (result.data) {
            setWarehoseData(
              result.data.data.map((option: any) => ({
                value: option.id,
                label: option.name,
              }))
            );
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            alertmessage.error(resMessage)
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const submitForm = (values: any) => {
    setLoading(true);

    UserService.PostRecord(values, 'users').then(
      (response) => {
        fetchRecord();
        setOpen(false);
        setLoading(false);
        form.resetFields();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    UserService.GetRecord('roles').then((result) => {
      if (result.data) {
        setRoles(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
    UserService.GetRecord('state').then((result) => {
      if (result.data) {
        setState(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
    UserService.GetRecord('banking/banks').then((result) => {
      setLoadingBank(false);
      if (result.data) {
        setBank(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });

    setPageLoaded(true);
    getLocation();
  }, []);
  useEffect(() => {
    fetchRecord();
  }, [currentPage, pageSize]);
  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const fetchRecord = (search = '') => {
    SetisLoadingContent(true);

    UserService.GetRecord(
      `users?page=${currentPage}&pageSize=${pageSize}&search=${search}`
    ).then((result) => {
      SetisLoadingContent(false);

      if (result.data) {
        setTotal(result.data.totalUsers);
        setData(
          result.data.data.map((option: any) => ({
            key: option.id,
            firstName: option.first_name,
            lastName: option.last_name,
            phone: option.phone,
            email: option.email,
            status: option.status,
            roleID: option.Role.name,
            createdAt: option.createdAt,
            AgentID: option.AgentID,
            warehouse:option?.WareHouse?.name,
            // farmers: option.Farmers.length,
            // FarmerMonitor: option.FarmerMonitors.length,
            state: option.State ? option.State.name : '',
          }))
        );
      } else {
        setData([]);
        setTotal(0);
      }
    });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleDelete = (key: any) => {
    UserService.DeleteRecord('users/' + key).then(
      (response) => {
        fetchRecord();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const handleEdit = (key: any) => {
    editform.resetFields();
    setUserID(key);
    UserService.GetRecord('users/' + key).then((result) => {
      if (result.data) {
        editform.setFieldsValue({
          first_name: result.data.data.first_name,
          last_name: result.data.data.last_name,
          email: result.data.data.email,
          phone: result.data.data.phone,
          roleID: result.data.data.roleID,
          status: result.data.data.status,
          AgentID: result.data.data.AgentID,
          stateID: result.data.data.stateID,
          bankID: result.data.data.bankID,
          houseNum: result.data.data.houseNum,
          streetName: result.data.data.streetName,
          city: result.data.data.city,
          address: result.data.data.address,
          warehouse_id:result.data.data.warehouse_id,
        });
        setIseditModalVisible(true);
      }
    });
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, [defaultdata]);
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  const UpdateForm = (values: any) => {
    setLoading(true);
    editform.validateFields().then((values) => {
      UserService.UpdateRecord(values, 'users/' + userID).then(
        (response) => {
          setLoading(false);
          // editform.resetFields();
          setSubmitMessage('Update was successful');
          setSubmitTitle('Record Success');
          setSubmitBadge('success');
          fetchRecord();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setSubmitMessage(resMessage);
          setSubmitTitle('Record failed');
          setSubmitBadge('error');
          setLoading(false);
        }
      );
    });
  };
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    fetchRecord(e.target.value);
  };
  // Define a function to render a page of data
  const renderPage = (pageData: any) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={
              option.firstName +
              ' ' +
              option.lastName +
              ' ' +
              formatDate(option.createdAt)
            }
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <a onClick={() => handleEdit(option.key)}>Edit</a>
                    <Popconfirm
                      title="Sure to delete this user?"
                      onConfirm={() => handleDelete(option.key)}
                    >
                      <a>Delete</a>
                    </Popconfirm>
                  </Space>
                </>
              }
              bordered
              //
              dataSource={[
                'First Name: ' + option.firstName,
                'Last Name: ' + option.lastName,
                'Email: ' + option.email,
                'Phone: ' + option.phone,
                'RoleID: ' + option.roleID,
                'Status: ' + option.status,
                'AgentID: ' + option.AgentID,
                'Warehouse: ' + option.warehouse,
                // 'Activity: ' + option.FarmerMonitor,
                'State: ' + option.state,
              ]}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '0 16px' }}>
                <h2>User Management</h2>
                <Space size="middle">
                  <Button type="primary" onClick={showModal}>
                    Add User
                  </Button>
                </Space>
                <Modal
                  open={open}
                  title="User Management"
                  onCancel={handleCancel}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            submitForm(values);
                          })
                          .catch((info) => {});
                      }}
                      loading={loading}
                    >
                      Submit
                    </Button>,
                  ]}
                >
                  {message && (
                    <>
                      <Alert
                        message="Error"
                        description={message}
                        type="error"
                        closable
                      />
                    </>
                  )}
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="AddUser"
                    initialValues={defaultdata}
                    style={{ maxWidth: 600 }}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your First Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Last Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="phone"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your phone number!',
                        },
                      ]}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="AgentID" label="Agent Number">
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      name="stateID"
                      label="state"
                      rules={[
                        { required: true, message: 'Please select State!' },
                      ]}
                    >
                      <Select placeholder="select your state">
                        {state.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="roleID"
                      label="Role"
                      rules={[
                        { required: true, message: 'Please select Role!' },
                      ]}
                    >
                      <Select placeholder="select your role">
                        {roles.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Bank"
                      name="bankID"
                      rules={[{ required: false }]}
                    >
                      <Select placeholder="Select Bank" loading={loadingBank}>
                        {bank.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="status"
                      label="Status"
                      rules={[
                        { required: true, message: 'Please select Status!' },
                      ]}
                    >
                      <Select placeholder="select your Status">
                        <Option value="Active">Active</Option>
                        <Option value="Disable">Disable</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="warehouse_id"
                      label="Assign Warehouse"
                      rules={[
                        { required: true, message: 'Please select Warehouse!' },
                      ]}
                    >
                      <Select options={warehoseData} />
                    </Form.Item>


                    <Form.Item
                      name="houseNum"
                      label="House Number"
                      rules={[
                        {
                          message: 'Please input your house number!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>

                    <Form.Item
                      name="streetName"
                      label="Street Name"
                      rules={[
                        {
                          message: 'Please input your street name!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          message: 'Please input your city name!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        {
                          message: 'Please input your address!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                'The two passwords that you entered do not match!'
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form>
                </Modal>
                <Modal
                  title="Edit Farmer"
                  open={iseditModalVisible}
                  onOk={UpdateForm}
                  onCancel={() => {
                    editform.resetFields();
                    setIseditModalVisible(false);
                  }}
                >
                  <Form
                    form={editform}
                    name="EditFarmer"
                    style={{ maxWidth: 600 }}
                    scrollToFirstError
                  >
                    <Space>
                      {submitMessage && submitBadge === 'success' && (
                        <Alert
                          message={submitTitle}
                          description={submitMessage}
                          type="success"
                          closable
                          onClose={onClose}
                        />
                      )}
                      {submitMessage && submitBadge === 'error' && (
                        <Alert
                          message={submitTitle}
                          description={submitMessage}
                          type="error"
                          closable
                          onClose={onClose}
                        />
                      )}
                    </Space>
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your First Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Last Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your phone number!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="AgentID" label="Agent Number">
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      name="stateID"
                      label="state"
                      rules={[
                        { required: true, message: 'Please select State!' },
                      ]}
                    >
                      <Select placeholder="select your state">
                        {state.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="roleID"
                      label="Role"
                      rules={[
                        { required: true, message: 'Please select Role!' },
                      ]}
                    >
                      <Select placeholder="select your role">
                        {roles.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Bank"
                      name="bankID"
                      rules={[{ required: false }]}
                    >
                      <Select placeholder="Select Bank" loading={loadingBank}>
                        {bank.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="status"
                      label="Status"
                      rules={[
                        { required: true, message: 'Please select Status!' },
                      ]}
                    >
                      <Select placeholder="select your Status">
                        <Option value="Active">Active</Option>
                        <Option value="Disable">Disable</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="houseNum"
                      label="House Number"
                      rules={[
                        {
                          message: 'Please input your house number!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>

                    <Form.Item
                      name="streetName"
                      label="Street Name"
                      rules={[
                        {
                          message: 'Please input your street name!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          message: 'Please input your city name!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="warehouse_id"
                      label="Assign Warehouse"
                      rules={[
                        { required: true, message: 'Please select Warehouse!' },
                      ]}
                    >
                      <Select options={warehoseData} />
                    </Form.Item>
                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        {
                          message: 'Please input your address!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Form>

                  <Button type="primary">
                    <Link target="_blank" to={`/edit-banking-agent/${userID}`}>
                      Make Banking Agent
                    </Link>
                  </Button>
                </Modal>
                <Input
                  placeholder="Search Query..."
                  style={{ width: 250, margin: '30px' }}
                  onChange={handleSearch}
                />
                {isLoadingContent ? (
                  <ScaleLoader
                    color={'#000'}
                    loading={isLoadingContent}
                    // size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <>
                    {!isMobile && (
                      <Table
                        dataSource={data}
                        scroll={{ x: true }}
                        pagination={false}
                      >
                        <Column
                          title="First Name"
                          dataIndex="firstName"
                          key="firstName"
                        />
                        <Column
                          title="Last Name"
                          dataIndex="lastName"
                          key="lastName"
                        />
                        <Column title="Phone" dataIndex="phone" key="phone" />
                        <Column
                          title="Agent Number"
                          dataIndex="AgentID"
                          key="AgentID"
                        />
                        <Column title="Email" dataIndex="email" key="email" />
                        <Column
                          title="Role ID"
                          dataIndex="roleID"
                          key="roleID"
                        />
                        <Column
                          title="Ware House"
                          dataIndex="warehouse"
                          key="warehouse"
                        />

                        {/* <Column
                          title="Farmers"
                          dataIndex="farmers"
                          key="farmers "
                        />
                        <Column
                          title="Activity Upload"
                          dataIndex="FarmerMonitor"
                          key="FarmerMonitor"
                        /> */}
                        <Column title="State" dataIndex="state" key="state" />
                        <Column
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={(_: any, record: DataType) => (
                            <>
                              <Tag color="blue" key="status">
                                {record.status}
                              </Tag>
                            </>
                          )}
                        />
                        <Column
                          title="Action"
                          key="action"
                          render={(_: any, record: DataType) => (
                            <Space size="middle">
                              <a onClick={() => handleEdit(record.key)}>Edit</a>
                              <Popconfirm
                                title="Sure to delete this user?"
                                onConfirm={() => handleDelete(record.key)}
                              >
                                <a>Delete</a>
                              </Popconfirm>
                            </Space>
                          )}
                        />
                      </Table>
                    )}

                    {isMobile && (
                      <>
                        {/* Render the current page of data */}
                        {renderPage(data)}
                        {/* Render pagination component */}
                      </>
                    )}
                  </>
                )}
                <Pagination
                  total={total}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handlePaginationChange}
                />
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default UserPage;
