import React, { useRef, useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import { FileExcelFilled } from '@ant-design/icons';
import { ExportExcel } from '../../../library/Excelexport';
import {
  Select,Button,
message,Table,Card
  } from 'antd';
const { Option } = Select;

interface CardTableProps {
  data: {
    card_no?: string | null;
    accountno?: string | null;
    bvn?: string | null;
    markno?: number | null;
    name?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: number | null;
  }[];
}

const CardTable: React.FC = (props) => {
    const [data, setData] = useState<any>([]);
    const [total, setTotalData] = useState<number>(0);
  const [selectedCard, setSelectedCard] = useState(null);

    const LoadData = (url: string) => {
        UserService.GetRecord(url).then(
          (result) => {
            if (result.data) {
              message.success('data found!');
              setTotalData(result.data.data.length);
              setData(
                result.data.data.map((option: any, index: number) => ({
                  key: index + 1,
                  name: option.name,
                  card_no: option.card_no,
                  accountno: option.accountno,
                  bvn: option.bvn,
                  markno: option.markno,
                  status: (option.status ===0)?'Pending':(option.status ===1)?'Pending Collection':'Collected',
                  phone: option.phone,
                  email: option.email
                }))
              );
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              message.success(resMessage);

            setData([]);
          }
        );
      };
       const exportExcel = () => {
        ExportExcel({
          excelDelta: data,
          fileName: 'ATMCARD-Details',
        });
      };
      const columns = [
        {
          title: 'SN',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Card Number',
          dataIndex: 'card_no',
          key: 'card_no',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Account Number',
          dataIndex: 'accountno',
          key: 'accountno',
        },
        {
          title: 'BVN',
          dataIndex: 'bvn',
          key: 'bvn',
        },
        {
          title: 'Mark No',
          dataIndex: 'markno',
          key: 'markno',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Status No',
          dataIndex: 'status',
          key: 'status',
        },
      ];

      useEffect(() => {
        LoadData(`fcmb/fcmb-cards?status=${selectedCard}`);
      }, [selectedCard]);
    return (

      <>

      <Button icon={<FileExcelFilled rev="icon-rev" />} onClick={exportExcel}>Export Excel</Button>
      <Select
              style={{ width: '30%', marginTop: '1%' }} placeholder="Select card Status" onChange={setSelectedCard}>
              <Option key='0' value='0'>Pending</Option>
              <Option key='1' value='1'>Pending Collection</Option>
              <Option key='2' value='2'>Collected</Option>
            </Select>
      <Card>
      <Table dataSource={data}  columns={columns} />
      </Card>
      </>

    );
};

export default CardTable;
