import { Collapse, Layout, Row, Col, Button, Typography } from 'antd';
import React, { useState } from 'react';
import UserService from '../../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { formatDate } from '../../../../common/utility';
import { PlayCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Panel } = Collapse;
const API = `${process.env.REACT_APP_API_END_POINT}`;
interface ChildProps {
  propsList: any; // Adjust the type according to your data
}

const LoadRecordComponent: React.FC<ChildProps> = (props: any) => {
  const [listCalendarData, setListCalendarData] = useState<any>(
    props.propsList
  );
  const [isLoadingContent, SetisLoadingContent] = useState(false);
  const [PageLoaded, setPageLoaded] = useState(true);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const handleDelete = (key: any) => {
    UserService.DeleteRecord('farmers/' + key).then(
      (response) => {},
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Content style={{ margin: '50px' }}>
            <div style={{ margin: '20px 0' }} />

            {isLoadingContent ? (
              <ScaleLoader
                color={'#000'}
                loading={isLoadingContent}
                // size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div style={{ margin: '10%' }}>
                      {listCalendarData &&
                        listCalendarData.map((data: any) => (
                          <div style={{ margin: '5px', textAlign: 'left' }}>
                            <span style={{ marginRight: '8px' }}>
                              <Button
                                onClick={() => {
                                  setContent(data.content);
                                  setTitle(
                                    data.farmCalendarMetaDatum
                                      .FarmCalendarActivity.name
                                  );
                                }}
                              >
                                {
                                  data.farmCalendarMetaDatum
                                    .FarmCalendarActivity.name
                                }{' '}
                                - {data.numberOfHectare} - (
                                {formatDate(data.createdAt)}) View Details{' '}
                                <PlayCircleOutlined rev />
                              </Button>
                            </span>
                          </div>
                        ))}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h2>Data Content for {title}</h2>
                    <div style={{ overflowX: 'scroll' }}>
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Content>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default LoadRecordComponent;
