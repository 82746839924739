import React, { useState, useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  Alert,
  List,
  Space,
  theme,
  Pagination,
  Layout,
} from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
const { Content } = Layout;
const { Panel } = Collapse;
const { Search } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
interface DataTypeTable {
  key: React.Key;
  name: string;
  status: string;
}
const StateComponent: React.FC = (props) => {
  const [message, setMessage] = useState('');
  const [form] = Form.useForm();
  const [itemData, setItemData] = useState<DataTypeTable[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPageActivity] = useState(1);
  const [submitBadge, setSubmitBadge] = useState('error');
  const [PageLoaded, setPageLoaded] = useState(false);
  const [Submitting] = useState(false);
  const pageSize: number = 10;
  const { token } = theme.useToken();
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};

  const handleSubmit = (value: any, page: string) => {
    UserService.PostRecord(value, page).then(
      (response) => {
        form.resetFields();
        LoadData();
        setMessage(page + ' added Successfully');
        setSubmitBadge('success');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSubmitBadge('error');
      }
    );
  };

  const handleUpdate = (key: any, value: any, page: string) => {
    UserService.UpdateRecord({ name: value }, page + '/' + key).then(
      (response) => {
        form.resetFields();
        setMessage(value + ' updated');
        setSubmitBadge('success');
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSubmitBadge('error');
      }
    );
  };
  const LoadData = (search?: string) => {
    const searchurl = search ? 'state?search=' + search : 'state';
    UserService.GetRecord(searchurl).then(
      (result) => {
        if (result.data) {
          setTotalData(result.data.data.length);
          setItemData(
            result.data.data.map((option: any) => ({
              key: option.id,
              name: option.name,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);

        setItemData([]);
      }
    );
  };
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  useEffect(() => {
    LoadData();
    setPageLoaded(true);
  }, []);
  const searchItem: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const search = e.target.value;
    LoadData(search);
  };
  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel header={option.name} key={index} style={panelStyle}>
            <List
              size="small"
              dataSource={[
                <Search
                  placeholder="input Data"
                  enterButton="Save"
                  size="large"
                  defaultValue={option.name}
                  onSearch={(value) => handleUpdate(option.key, value, page)}
                />,
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Space>
                  {message && submitBadge === 'success' && (
                    <Alert
                      message="Success"
                      description={message}
                      type="success"
                      closable
                      onClose={onClose}
                    />
                  )}
                  {message && submitBadge === 'error' && (
                    <Alert
                      message="Error"
                      description={message}
                      type="error"
                      closable
                      onClose={onClose}
                    />
                  )}
                </Space>
                <>
                  <Form
                    {...formItemLayout}
                    form={form}
                    scrollToFirstError
                    style={{ maxWidth: 600 }}
                    className="formEdit"
                  >
                    <Form.Item
                      label="State Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        loading={Submitting}
                        key="submit"
                        type="primary"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              handleSubmit(values, 'state');
                            })
                            .catch((info) => {});
                        }}
                      >
                        Save
                      </Button>
                    </Form.Item>
                    <Input
                      style={{ margin: '20px' }}
                      placeholder="Search Name"
                      onChange={searchItem}
                    />
                    {/* Render the current page of data */}
                    {renderPage(
                      itemData.slice(
                        (currentPage - 1) * pageSize,
                        currentPage * pageSize
                      ),
                      'state'
                    )}
                    {/* Render pagination component */}
                    <Pagination
                      total={totalData}
                      pageSize={pageSize}
                      current={currentPage}
                      onChange={(page) => setCurrentPageActivity(page)}
                    />
                  </Form>
                </>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default StateComponent;
