import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_END_POINT}`;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  PostRecord(data = {}, url: string) {
    console.log(API_URL + url)
    return axios.post(API_URL + url, data, { headers: authHeader() });
  }

  DeleteRecord(url: string) {
    return axios.delete(API_URL + url, { headers: authHeader() });
  }

  UpdateRecord(data = {}, url: string) {
    return axios.put(API_URL + url, data, { headers: authHeader() });
  }

  GetRecord(url: string) {
    return axios.get(API_URL + url, { headers: authHeader() });
  }
}

const userServiceInstance = new UserService();
export default userServiceInstance;
