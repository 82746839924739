import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_END_POINT}`;

class AuthService {
  login(data: { password: string; remember: string; email: string }) {
    return axios.post(API_URL + 'auth/login', data).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(
    first_name: string,
    last_name: string,
    role: string,
    phone: string,
    email: string,
    password: string
  ) {
    return axios.post(API_URL + 'signup', {
      first_name,
      last_name,
      role,
      phone,
      email,
      password,
    });
  }
}
const authService = new AuthService();
export default authService;
