import React, { useState, useEffect, useRef } from 'react';
import {
  CaretRightOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import {
  Button,
  Collapse,
  Form,
  Input,
  Alert,
  Select,
  Space,
  Modal,
  Radio,
  Badge,
  theme,
  Pagination,
  Layout,
  Row,
  Col,
  Table,
  Timeline,
} from 'antd';
import { formatDate } from '../../../common/utility';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { List } from 'antd';
import { Checkbox, Divider } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Tooltip } from '@mui/material';
import { ExportExcel } from '../../../library/Excelexport';
import { generatePDF } from '../../../library/PDFexport';

import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import Highlighter from 'react-highlight-words';
const { TextArea } = Input;
type DataIndex = keyof orderData;

interface SelectOption {
  value: string;
  label: string;
}
const { Content } = Layout;
const { Panel } = Collapse;

interface orderData {
  key: number;
  farmerName: string;
  destinationAddress: string;
  phone: string;
  supplierName: string;
  itemName: string;
  agentName: string;
  orderReference: string;
  quantity: number;
  totalAmount: number;
  createdAt: string;
  status: string;
  TrackOrders: [];
  expectedDeliveryDate: string;
}
interface timeline {
  children: string;
  label: string;
}
const SupplierOrder: React.FC = (props) => {
  const [message, setMessage] = useState('');
  const [form] = Form.useForm();
  const [totalData, setTotalData] = useState(0);
  const [submitTitle, setSubmitTitle] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [currentPage, setCurrentPageActivity] = useState(1);
  const [submitBadge, setSubmitBadge] = useState('error');
  const [PageLoaded, setPageLoaded] = useState(false);
  const [isopenModal, setIsopenModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cannotSubmit, setCanNotSubmit] = useState(true);
  useState('new-address');
  const [optionsUser, setOptionsUser] = useState<SelectOption[]>([]);
  const [users, setUsers] = useState();
  const [selectedOrder, setSelectedOrder] = useState<number>(0);
  const [orderData, setOrderData] = useState<orderData[]>([]);
  const [checkedSearch, setCheckedSearch] = useState<CheckboxValueType[]>([]);
  const [status, setStatus] = useState('Pending'); // default is 'middle'
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [selectedOrderData, setSelectedOrderData] = useState<orderData>();
  const [orderTrack, setOrderTrack] = useState<timeline[]>([]);
  const [showDispatchForm, setShowDispatchForm] = useState(false);
  const [showDate, SetshowDate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const plainOptions = [
    { label: 'Item Name' },
    { label: 'Farmer Name' },
    { label: 'orderReference' },
    { label: 'order Phone' },
  ];
  const pageSize: number = 10;
  const { token } = theme.useToken();
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};

  const handleSubmit = () => {
    setSubmitting(true);
    form.validateFields().then((values) => {
      values.itemOrderID = selectedOrder;
      UserService.PostRecord(values, 'order/dispatch').then(
        (response) => {
          setMessage('Order Dispatched!');
          setSubmitBadge('success');
          setStatus('Dispatched');
          setSubmitting(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setSubmitBadge('error');
          setSubmitting(false);
          setSubmitTitle('Error');
          setSubmitMessage(resMessage);
        }
      );
    });
  };
  const LoadData = (search?: string) => {
    const searchParams = new URLSearchParams();
    checkedSearch.map((query: any) => searchParams.append('checked[]', query));
    const queryString = searchParams.toString();
    let searchurl = search
      ? `order?search=${search}&${queryString}&status=${status}`
      : `order?${queryString}`;
    const formattedStartDate = showDate
      ? format(dateRange[0].startDate, 'yyyy-MM-dd')
      : '';
    const formattedEndDate = showDate
      ? format(dateRange[0].endDate, 'yyyy-MM-dd')
      : '';
    searchurl = showDate
      ? `${searchurl}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      : searchurl;
    UserService.GetRecord(searchurl).then(
      (result) => {
        if (result.data) {
          setTotalData(result.data.data.length);
          setOrderData(
            result.data.data.map((option: any) => ({
              key: option.id,
              farmerName:
                option.Farmer.first_name + ' ' + option.Farmer.last_name,
              destinationAddress: option.OrderDetail.destinationAddress,
              phone: option.OrderDetail.phone,
              supplierName: option.supplierItem
                ? option.supplierItem.User.first_name +
                  ' ' +
                  option.supplierItem.User.last_name
                : '',
              itemName: option.Item.name,
              agentName: option.User.first_name + ' ' + option.User.last_name,
              orderReference: option.orderReference,
              quantity: option.quantity,
              totalAmount: option.totalAmount,
              TrackOrders: option.TrackOrders,
              expectedDeliveryDate: option.expectedDeliveryDate,
              createdAt: formatDate(option.createdAt),
              status: option.status,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);

        setOrderData([]);
      }
    );
  };

  const LoadDataDispatcher = async () => {
    UserService.GetRecord('users').then((result) => {
      if (result.data) {
        setOptionsUser(
          result.data.data.map((option: any) => ({
            value: option.id,
            label:
              option.first_name + ' ' + option.last_name + ' ' + option.phone,
          }))
        );
      }
    });
  };
  const handleCancel = () => {
    setIsopenModal(false);
  };
  const handleDispatch = (Order: any, viewdispatch = true) => {
    setIsopenModal(true);
    setSelectedOrder(Order.key);
    setShowDispatchForm(viewdispatch);
    setSelectedOrderData(Order);
    form.setFieldsValue({
      Rider: null,
      remarks: '',
    });
    if (Order.TrackOrders) {
      setOrderTrack(
        Order.TrackOrders.map((option: any) => ({
          children: option.address,
          label: option.arrivalTime,
        }))
      );
    }
  };
  const handleOrderStatus = (status: any, key: number) => {
    UserService.UpdateRecord({ status: status }, 'order/status/' + key).then(
      (response) => {
        setMessage('Order Status Updated!');
        setSubmitBadge('success');
        setStatus(status);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSubmitBadge('error');
      }
    );
  };
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const searchItem: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    LoadData();
  };

  useEffect(() => {
    LoadDataDispatcher();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    users ? setCanNotSubmit(false) : setCanNotSubmit(true);
  }, [users]);

  const onsearchChange = (checkedValues: any) => {
    setCheckedSearch(checkedValues);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={option.farmerName + ' ' + option.createdAt}
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <Button
                      onClick={() => {
                        if (option.status === 'Processing') {
                          handleDispatch(option);
                        } else if (option.status === 'Pending') {
                          handleOrderStatus('Processing', option.key);
                        } else if (option.status === 'Dispatched') {
                          handleDispatch(option, false);
                        } else {
                        }
                      }}
                    >
                      {option.status === 'Processing'
                        ? 'Dispatch'
                        : option.status === 'Pending'
                        ? 'Process Order'
                        : 'View Order'}
                    </Button>
                  </Space>
                </>
              }
              dataSource={[
                'Item : ' + option.itemName,
                'Quantity : ' + option.quantity,
                'Total Payable : ' + option.totalAmount,
                'Reference : ' + option.orderReference,
                'Phone : ' + option.phone,
                'Address : ' + option.destinationAddress,
                'Supplier :' + option.supplierName,
                'Agent :' + option.agentName,
                'Expected Delivery :' + option.expectedDeliveryDate,
                'Status : ' + (option.status === 'pending') ? (
                  <Badge status="warning" text={option.status} />
                ) : option.status === 'rejected' ? (
                  <Badge status="error" text={option.status} />
                ) : option.status === 'completed' ? (
                  <Badge text={option.status} status="success" />
                ) : (
                  <Badge text={option.status} status="processing" />
                ),
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<orderData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev="icon-rev" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev="icon-rev"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  const columns: ColumnsType<orderData> = [
    {
      title: 'Farmer Name',
      dataIndex: 'farmerName',
      key: 'farmerName',
      ...getColumnSearchProps('farmerName'),
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',

      ...getColumnSearchProps('itemName'),
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      key: 'supplierName',

      ...getColumnSearchProps('supplierName'),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',

      ...getColumnSearchProps('agentName'),
    },
    {
      title: 'Order Reference',
      dataIndex: 'orderReference',
      key: 'orderReference',

      ...getColumnSearchProps('orderReference'),
    },
    {
      title: 'Expected Delivery',
      dataIndex: 'expectedDeliveryDate',
      key: 'expectedDeliveryDate',

      ...getColumnSearchProps('expectedDeliveryDate'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',

      ...getColumnSearchProps('quantity'),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',

      ...getColumnSearchProps('totalAmount'),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',

      ...getColumnSearchProps('status'),
    },
    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',
      render: (_: any, record: orderData) => (
        <Space>
          <Button
            onClick={() => {
              if (record.status === 'Processing') {
                handleDispatch(record);
              } else if (record.status === 'Pending') {
                handleOrderStatus('Processing', record.key);
              } else if (record.status === 'Dispatched') {
                handleDispatch(record, false);
              }
            }}
          >
            {record.status === 'Processing'
              ? 'Dispatch'
              : record.status === 'Pending'
              ? 'Process Order'
              : 'View Order'}
          </Button>
        </Space>
      ),
    },
  ];
  const handleDateRangeChange = (ranges: any) => {
    setDateRange([ranges.selection]);
  };
  const useDateHandler = () => {
    SetshowDate(!showDate);
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <>
                  <Modal
                    title={
                      showDispatchForm ? 'Dispatch Order' : 'Order Information'
                    }
                    open={isopenModal}
                    onCancel={handleCancel}
                    footer={[
                      <Tooltip title="Export Excel">
                        <Button
                          onClick={() => {
                            generatePDF('orderDetail');
                          }}
                        >
                          Export PDF
                          <FilePdfOutlined rev="icon-rev" />
                        </Button>
                      </Tooltip>,
                      <Button
                        key="submit"
                        onClick={handleSubmit}
                        type="primary"
                        loading={submitting}
                        disabled={cannotSubmit}
                      >
                        {showDispatchForm ? 'Dispatch' : '-'}
                      </Button>,
                      <Button key="back" onClick={handleCancel}>
                        Return
                      </Button>,
                    ]}
                  >
                    <Space>
                      {message && submitBadge === 'success' && (
                        <Alert
                          message="Success"
                          description={message}
                          type="success"
                          closable
                          onClose={onClose}
                        />
                      )}
                      {message && submitBadge === 'error' && (
                        <Alert
                          message="Error"
                          description={message}
                          type="error"
                          closable
                          onClose={onClose}
                        />
                      )}
                    </Space>
                    <Form form={form}>
                      <Space>
                        {submitMessage && submitBadge === 'success' && (
                          <Alert
                            message={submitTitle}
                            description={submitMessage}
                            type="success"
                            closable
                            onClose={onClose}
                          />
                        )}
                        {submitMessage && submitBadge === 'error' && (
                          <Alert
                            message={submitTitle}
                            description={submitMessage}
                            type="error"
                            closable
                            onClose={onClose}
                          />
                        )}
                      </Space>
                      <div id="orderDetail">
                        <h2>Order Information</h2>
                        <p>
                          <b>Item</b> :{' '}
                          {selectedOrderData && selectedOrderData.itemName}
                        </p>
                        <p>
                          <b>Agent Name</b> :{' '}
                          {selectedOrderData && selectedOrderData.agentName}
                        </p>
                        <p>
                          <b>Supplier Name</b> :{' '}
                          {selectedOrderData && selectedOrderData.supplierName}
                        </p>
                        <p>
                          <b>Farmer Name</b> :{' '}
                          {selectedOrderData && selectedOrderData.farmerName}
                        </p>
                        <p>
                          <b>Order Reference</b> :{' '}
                          {selectedOrderData &&
                            selectedOrderData.orderReference}
                        </p>
                        <p>
                          <b>Quantity</b> :{' '}
                          {selectedOrderData && selectedOrderData.quantity}
                        </p>
                        <p>
                          <b>Total Amount</b> :{' '}
                          {selectedOrderData && selectedOrderData.totalAmount}
                        </p>
                        <p>
                          <b>Status</b> :{' '}
                          {selectedOrderData && selectedOrderData.status}
                        </p>
                        <p>
                          <b>Phone</b> :{' '}
                          {selectedOrderData && selectedOrderData.phone}
                        </p>
                        <p>
                          <b>Address</b> :{' '}
                          {selectedOrderData &&
                            selectedOrderData.destinationAddress}
                        </p>
                        <p>
                          <b>Delivery Time</b> :{' '}
                          {selectedOrderData &&
                            selectedOrderData.expectedDeliveryDate}
                        </p>
                        {orderTrack && <h2> Delivery Time Line</h2>}
                      </div>
                      <Timeline mode="left" items={orderTrack} />
                      {showDispatchForm && (
                        <>
                          <Form.Item
                            name="riderID"
                            label="Select Rider"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Rider!',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              style={{ width: '100%' }}
                              placeholder="Select Items"
                              options={optionsUser}
                              onChange={setUsers}
                            />
                          </Form.Item>
                          <Form.Item
                            name="remarks"
                            label="Leave Remarks"
                            rules={[
                              {
                                required: true,
                                message: 'Please input Remarks!',
                              },
                            ]}
                          >
                            <TextArea></TextArea>
                          </Form.Item>
                        </>
                      )}
                    </Form>
                  </Modal>

                  <Space>
                    {message && submitBadge === 'success' && (
                      <Alert
                        message="Success"
                        description={message}
                        type="success"
                        closable
                        onClose={onClose}
                      />
                    )}
                    {message && submitBadge === 'error' && (
                      <Alert
                        message="Error"
                        description={message}
                        type="error"
                        closable
                        onClose={onClose}
                      />
                    )}
                  </Space>
                  <div
                    id="range-picker-container"
                    className="range-picker-container"
                  >
                    {showDate && (
                      <>
                        <p>
                          <DateRange
                            ranges={dateRange}
                            onChange={handleDateRangeChange}
                          />
                        </p>
                      </>
                    )}
                    <Button type="primary" onClick={useDateHandler}>
                      {showDate ? 'Hide Date' : 'Search Date Range'}
                      <FieldTimeOutlined rev="icon-rev" />
                    </Button>
                  </div>
                  <Radio.Group
                    value={status}
                    onChange={(e: any) => setStatus(e.target.value)}
                  >
                    <Radio.Button value="Pending">Pending Orders</Radio.Button>
                    <Radio.Button value="Processing">
                      Processing Orders
                    </Radio.Button>
                    <Radio.Button value="Completed">
                      Completed Orders
                    </Radio.Button>
                    <Radio.Button value="Dispatched">Dispatched</Radio.Button>
                  </Radio.Group>
                  <Divider orientation="center" plain>
                    Preview
                  </Divider>
                  <div />
                  <p>
                    <div style={{ overflowX: 'auto' }}></div>
                    <Tooltip title="Export Excel">
                      <Button
                        onClick={() => {
                          ExportExcel({
                            excelDelta: orderData,
                            fileName: 'orderDetails',
                          });
                        }}
                      >
                        Export Excel
                        <FileExcelOutlined rev="icon-rev" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Export Excel">
                      <Button
                        onClick={() => {
                          generatePDF();
                        }}
                      >
                        Export PDF
                        <FilePdfOutlined rev="icon-rev" />
                      </Button>
                    </Tooltip>
                    {isMobile && (
                      <>
                        <Checkbox.Group
                          style={{ width: '100%' }}
                          onChange={onsearchChange}
                        >
                          <Row>
                            {plainOptions.map((plainOptionData, index) => (
                              <Col span={8} key={index}>
                                <Checkbox
                                  value={plainOptionData.label.replaceAll(
                                    ' ',
                                    ''
                                  )}
                                >
                                  {plainOptionData.label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                        <div />
                        <Input
                          style={{ margin: '5px' }}
                          placeholder="Search Order"
                          onChange={searchItem}
                        />
                      </>
                    )}
                    <div />
                  </p>
                  <div id="report">
                    {!isMobile ? (
                      <Table
                        columns={columns}
                        dataSource={orderData}
                        scroll={{ x: 'max-content', y: 400 }}
                      />
                    ) : (
                      <>
                        {/* Render the current page of data */}
                        {renderPage(
                          orderData.slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          ),
                          'order'
                        )}
                        {/* Render pagination component */}
                        <Pagination
                          total={totalData}
                          pageSize={pageSize}
                          current={currentPage}
                          onChange={(page) => setCurrentPageActivity(page)}
                        />
                      </>
                    )}
                  </div>
                </>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default SupplierOrder;
