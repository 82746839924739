import React, { useState, useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  Alert,
  Select,
  List,
  Card,
  Space,
  Popconfirm,
  theme,
  Pagination,
  Upload,
  Layout,
  InputNumber,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Tabs, TabsProps } from 'antd';
import UserService from '../../../services/user.service';
import type { UploadFile } from 'antd/es/upload/interface';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import CardTable from '../Card/CardComponent'
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const { Search } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
interface selectOptions {
  value: number;
  label: string;
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
interface DataTypeTable {
  key: React.Key;
  name: string;
  statusID: string;
}

const VariableComponent: React.FC = (props) => {
  const [messagetxt, setMessageTXT] = useState('');
  const [ActivitySubmitting, setActivitySubmitting] = useState(false);
  const [CropTypeSubmitting, setCropTypeSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [dataCropType, setDataCropType] = useState<DataTypeTable[]>([]);
  const [dataActivity, setDataActivity] = useState<DataTypeTable[]>([]);
  const [totalActivity, setTotalActivity] = useState(0);
  const [totalCroptype, setTotalCroptype] = useState(0);
  const [currentPageActivity, setCurrentPageActivity] = useState(1);
  const [currentPageCroptype, setCurrentPageCroptype] = useState(1);
  const [submitBadge, setSubmitBadge] = useState('error');
  const [submitTitle, setSubmitTitle] = useState('');
  const [createAccountOption, setCreateAccountOption] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitCardMessage, setSubmitCardMessage] = useState('');

  const [PageLoaded, setPageLoaded] = useState(false);
  const [NGstate, setNGstate] = useState<{ value: string; label: string }[]>(
    []
  );
  const [itemList, setItemList] = useState<selectOptions[]>([]);
  const [loansetting, setLoansetting] = useState([]);

  const [formx] = Form.useForm();
  const [formloan] = Form.useForm();

  const [selectedState, setSelectedState] = useState(1);
  const [selectedAssignATM, setSelectedAssignATM] = useState(0);

  const optionAccount = [
    { value: 'createBankAccount', label: 'create FCMB Account' },
    { value: 'adduser', label: 'Add user only' },
  ];
  const pageSize: number = 10;
  const { token } = theme.useToken();
  //
  const loader = (page: string, state: boolean) => {
    if (page === 'activity') {
      setActivitySubmitting(state);
    } else {
      setCropTypeSubmitting(state);
    }
  };
  const fileList: UploadFile[] = [];
  const handleSubmit = (value: any, page: string) => {
    loader(page, true);
    UserService.PostRecord(value, page).then(
      (response) => {
        form.resetFields();
        LoadData();
        loader(page, false);
        setMessageTXT(page + ' added Successfully');
        message.success(response.data.message)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage)

        setMessageTXT(resMessage);
        setSubmitBadge('error');
        loader(page, false);
      }
    );
  };
  const handleCreateFCMBAccountforAll = () => {
    UserService.PostRecord({}, 'farmers/generate/all/fcmbAccount').then(
      (response) => {},
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessageTXT(resMessage);
      }
    );
  };
  const setAlltoBatch=()=>{
    UserService.PostRecord({}, 'farmers/reset/all/fcmbAccountCard').then(
      (response) => {},
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessageTXT(resMessage);
      }
    );
  }
  const handleToggle = (key: any, page: string) => {
    UserService.PostRecord({}, page + '/' + key).then(
      (response) => {
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessageTXT(resMessage);
        setSubmitBadge('error');
      }
    );
  };
  const handleUpdate = (key: any, value: any, page: string) => {
    UserService.UpdateRecord({ name: value }, page + '/' + key).then(
      (response) => {
        form.resetFields();
        setMessageTXT(value + ' updated');
        setSubmitBadge('success');
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessageTXT(resMessage);
        setSubmitBadge('error');
      }
    );
  };
  const onSaveSettings = (values: any) => {
    UserService.PostRecord(values, 'transaction-setting').then(
      (response) => {
        message.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const onSaveLoanSettings = (values: any) => {
    UserService.PostRecord(values, 'loan/admin/setting').then(
      (response) => {
        message.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const LoadData = () => {
    UserService.GetRecord('utilities').then(
      (result) => {
        if (result.data) {
          setTotalActivity(result.data.activity.length);
          setTotalCroptype(result.data.croptype.length);
          setDataActivity(
            result.data.activity.map((option: any) => ({
              key: option.id,
              name: option.name,
              statusID: option.statusID,
            }))
          );
          setDataCropType(
            result.data.croptype.map((option: any) => ({
              key: option.id,
              name: option.name,
              statusID: option.statusID,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setDataActivity([]);
      }
    );
  };
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const onChange = (key: string | string[]) => {};

  useEffect(() => {
    LoadData();
  },[])
  useEffect(() => {
    setPageLoaded(true);
    UserService.GetRecord('banking/states').then((result) => {
      if (result.data) {
        setNGstate(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
    UserService.GetRecord('transaction-setting').then((result) => {
      if (result.data) {
        formx.setFieldsValue({
          agent_daily_transaction_limit: result.data.settingValue,
        });
      }
    });
    UserService.GetRecord('loan/admin/setting').then((result) => {
      if (result.data) {
        console.log(result.data.data);
        if (result.data.data.length) {
          formloan.setFieldsValue({
            item1: result.data.data[0].itemID,
            item2: result.data.data[1].itemID,
            quantity1: result.data.data[0].quantity,
            quantity2: result.data.data[1].quantity,
          });
        }
      }
    });
    UserService.GetRecord('items').then(
      (result: any) => {
        if (result.data) {
          setItemList(
            result.data.data.map((option: any) => ({
              value: option.id,
              label: option.name + ' - ' + option.measurement,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);
  const handleUpload = async (file: any) => {
    // handle upload logic here
    try {
      const isCSV = file.file.type === 'text/csv';
      if (!isCSV) {
        setSubmitMessage('You can only upload CSV file!');
        setSubmitTitle('CSV Validation');
        setSubmitBadge('error');
        return false;
      }
      const formData = new FormData();
      formData.append('file', file.file, file.file.name); // Append each file individually to the FormData object
      formData.append('stateID', selectedState + ''); // Append each file individually to the FormData object
      formData.append('createBankAccount', createAccountOption + ''); // Append each file individually to the FormData object
      formData.append('assignATM', selectedAssignATM + ''); // Append each file individually to the FormData object

      UserService.PostRecord(formData, 'farmers/batch/upload').then(
        (response: any) => {
          setSubmitMessage(response.data.message);
          setSubmitTitle('Activity');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setSubmitMessage(resMessage);
          setSubmitTitle('Activity');
        }
      );
    } catch (error) {}
  };
  const handleUploadFCMBATMCARD = async (file: any) => {
    // handle upload logic here
    try {
      const isCSV = file.file.type === 'text/csv';
      if (!isCSV) {
        setSubmitCardMessage('You can only upload CSV file!');

        setSubmitBadge('error');
        return false;
      }
      const formData = new FormData();
      formData.append('file', file.file, file.file.name); // Append each file individually to the FormData object

      UserService.PostRecord(formData, 'fcmb/fcmb-cards').then(
        (response: any) => {
          setSubmitMessage(response.data.message);
        message.success(response.data.message)

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            setSubmitCardMessage(resMessage);
        message.error(resMessage)

        }
      );
    } catch (error) {}
  };
  const handleValidateATMCARD = async (file: any) => {
    // handle upload logic here
    try {
      const isCSV = file.file.type === 'text/csv';
      if (!isCSV) {
        setSubmitCardMessage('You can only upload CSV file!');

        setSubmitBadge('error');
        return false;
      }
      const formData = new FormData();
      formData.append('file', file.file, file.file.name); // Append each file individually to the FormData object

      UserService.UpdateRecord(formData, 'fcmb/noma-cards-list').then(
        (response: any) => {
          // setSubmitMessage(response.data.message);
        message.success(response.data.message)

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            // setSubmitCardMessage(resMessage);
        message.error(resMessage)

        }
      );
    } catch (error) {}
  };
  const handleSaveATMCARDAsCollected = async (file: any) => {
    // handle upload logic here
    try {

      UserService.UpdateRecord({}, 'fcmb/mark-card-as-collected').then(
        (response: any) => {
          // setSubmitMessage(response.data.message);
        message.success(response.data.message)

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        message.error(resMessage)

            // setSubmitCardMessage(resMessage);
        }
      );
    } catch (error) {}
  };
  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel header={option.name} key={index} style={panelStyle}>
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={() => handleToggle(option.key, page)}
                    >
                      <a>
                        {option.statusID === 'Active' ? 'Disable' : 'Enable'}
                      </a>
                    </Popconfirm>
                  </Space>
                </>
              }
              dataSource={[
                <Search
                  placeholder="input Data"
                  enterButton="Save"
                  size="large"
                  defaultValue={option.name}
                  onSearch={(value) => handleUpdate(option.key, value, page)}
                />,
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Crop Type`,
      children: [
        <>
          <Card
            title="Crop Type"
            style={{ width: '50%', margin: '0 auto' }}
            id="BankingAgent"
          >
            <Form
              {...formItemLayout}
              form={form}
              scrollToFirstError
              style={{ maxWidth: 600 }}
              className="formEdit"
            >
              <Form.Item
                label="Crop Type Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Crop Type Name!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="statusID"
                label="Status"
                rules={[{ required: true, message: 'Please select Status!' }]}
              >
                <Select placeholder="select your Status">
                  <Option value="Active">Active</Option>
                  <Option value="Disable">Disable</Option>
                </Select>
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  loading={CropTypeSubmitting}
                  key="submit"
                  type="primary"
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        handleSubmit(values, 'croptype');
                      })
                      .catch((info) => {});
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            {/* Render the current page of data */}
            {renderPage(
              dataCropType.slice(
                (currentPageCroptype - 1) * pageSize,
                currentPageCroptype * pageSize
              ),
              'croptype'
            )}
            {/* Render pagination component */}
            <Pagination
              total={totalCroptype}
              pageSize={pageSize}
              current={currentPageCroptype}
              onChange={(page) => setCurrentPageCroptype(page)}
            />
          </Card>
        </>,
      ],
    },
    {
      key: '2',
      label: `Activity`,
      children: [
        <>
          <Form
            {...formItemLayout}
            form={form}
            scrollToFirstError
            style={{ maxWidth: 600 }}
            className="formEdit"
          >
            <Form.Item
              label="Activity Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Activity Name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="statusID"
              label="Status"
              rules={[{ required: true, message: 'Please select Status!' }]}
            >
              <Select placeholder="select your Status">
                <Option value="Active">Active</Option>
                <Option value="Disable">Disable</Option>
              </Select>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                loading={ActivitySubmitting}
                key="submit"
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      handleSubmit(values, 'activity');
                    })
                    .catch((info) => {});
                }}
              >
                Save
              </Button>
            </Form.Item>
            {/* Render the current page of data */}
            {renderPage(
              dataActivity.slice(
                (currentPageActivity - 1) * pageSize,
                currentPageActivity * pageSize
              ),
              'activity'
            )}
            {/* Render pagination component */}
            <Pagination
              total={totalActivity}
              pageSize={pageSize}
              current={currentPageActivity}
              onChange={(page) => setCurrentPageActivity(page)}
            />
          </Form>
        </>,
      ],
    },
    {
      key: '3',
      label: `Upload Farmers`,
      children: [
        <>
          <Button onClick={handleCreateFCMBAccountforAll}>
            Generate FCMB Account for all
          </Button>
          <Button onClick={setAlltoBatch}>
            Reset Previous Card
          </Button>
          <Form.Item name="stateID">
            <Select
              style={{ width: '30%', marginTop: '1%' }}
              placeholder="Select State"
              onChange={setSelectedState}
            >
              {NGstate.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="createBankAccount">
            <Select
              style={{ width: '30%', marginTop: '1%' }}
              placeholder="Select Account method"
              onChange={setCreateAccountOption}
            >
              {optionAccount.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="assignATM">
            <Select
              style={{ width: '30%', marginTop: '1%' }}
              placeholder="Assign ATM"
              onChange={setSelectedAssignATM}
            >
              <Option key="0" value="0">
                No ATM
              </Option>
              <Option key="1" value="1">
                With ATM
              </Option>
            </Select>
          </Form.Item>
          {submitMessage != '' ? (
            <Alert
              message={submitTitle}
              description={submitMessage}
              closable
              onClose={onClose}
            />
          ) : (
            ''
          )}
          <Upload
            onChange={handleUpload}
            beforeUpload={() => {
              return false;
            }}
            listType="picture"
            defaultFileList={[...fileList]}
          >
            <Button icon={<UploadOutlined rev="icon-rev" />}>Upload</Button>
          </Upload>
        </>,
      ],
    },
    {
      key: '4',
      label: `Transaction Settings`,
      children: [
        <>
          <Card
            title="Transaction Settings"
            style={{ width: '50%', margin: '0 auto' }}
            id="BankingAgent"
          >
            <Form
              form={formx}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onSaveSettings}
              autoComplete="off"
            >
              <Form.Item
                label="Agent Daily Transaction Limit"
                name="agent_daily_transaction_limit"
              >
                <InputNumber />
              </Form.Item>
              <Button
                htmlType="submit"
                icon={<UploadOutlined rev="icon-rev" />}
              >
                Save
              </Button>
            </Form>
          </Card>
        </>,
      ],
    },
    {
      key: '5',
      label: `Loan Settings`,
      children: [
        <>
          <Card
            title="Loan Settings"
            style={{ width: '50%', margin: '0 auto' }}
            id="loan"
          >
            <Form
              form={formloan}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onSaveLoanSettings}
              autoComplete="off"
            >
              <Form.Item
                label="Select First Item"
                name="item1"
                rules={[{ required: true, message: 'Please select an item!' }]}
              >
                <Select options={itemList} />
              </Form.Item>
              <Form.Item label="Quantity" name="quantity1">
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Select Second Item"
                name="item2"
                rules={[{ required: true, message: 'Please select an item!' }]}
              >
                <Select options={itemList} />
              </Form.Item>
              <Form.Item label="Quantity" name="quantity2">
                <InputNumber />
              </Form.Item>
              <Button
                htmlType="submit"
                icon={<UploadOutlined rev="icon-rev" />}
              >
                Save
              </Button>
            </Form>
          </Card>
        </>,
      ],
    },
    {
      key: '6',
      label: `Upload ATM-CARD`,
      children: [
        <>
          <h2 >
            Upload FCMB ATM Card List
          </h2>


          {submitCardMessage != '' ? (
            <Alert
              message='Card Response'
              description={submitCardMessage}
              closable
              onClose={onClose}
            />
          ) : (
            ''
          )}
          <div style={{display:'flex'}}>
          <Upload
            onChange={handleUploadFCMBATMCARD}
            beforeUpload={() => {
              return false;
            }}
            listType="text"
          >
            <Button icon={<UploadOutlined rev="icon-rev" />}>Upload ATM Card</Button>
          </Upload>

          <Upload
            onChange={handleValidateATMCARD}
            beforeUpload={() => {
              return false;
            }}
            listType="text"
          >
            <Button icon={<UploadOutlined rev="icon-rev" />}>Validate Card Owner</Button>
          </Upload>

          <Popconfirm
                      title="Are you sure, you want to mark all pending collection as received?"
                      onConfirm={() => handleSaveATMCARDAsCollected}
                    >
          <Button icon={<UploadOutlined rev="icon-rev" />}>Mark as Collected</Button>
            </Popconfirm>



          </div>
          <CardTable />
        </>,
      ],
    }
  ];
  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Space>
                  {message && submitBadge === 'success' && (
                    <Alert
                      message="Success"
                      description={messagetxt}
                      type="success"
                      closable
                      onClose={onClose}
                    />
                  )}
                  {message && submitBadge === 'error' && (
                    <Alert
                      message="Error"
                      description={messagetxt}
                      type="error"
                      closable
                      onClose={onClose}
                    />
                  )}
                </Space>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default VariableComponent;
