import '../style.css';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import UserService from '../../../../services/user.service';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Select, Button, Layout, Input, message } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Content } = Layout;
interface option {
  value: number;
  label: string;
}
const GuideAdmin = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [selectedPermission, setSelectedPermission] = useState('');
  const [selectedQuize, setSelectedQuize] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [permissionData, setPermissionData] = useState<option[]>([]);
  const [topics, setTopics] = useState<option[]>([]);
  const { id } = useParams();
  let navigate = useNavigate();

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const fetchTopics = async () => {
    try {
      const endpoint = UserService.GetRecord('quize/topic');
      endpoint.then(
        (response) => {
          setTopics(response.data.data.map((data: any) => ({
            value: data.id,
            label: data.name,
          })));
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
          setTopics([]);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchPermission();
    fetchTopics();
  }, []);

  const EditGuide = () => {
    UserService.GetRecord('guide/' + id).then(
      (response: any) => {
        setContent(response.data.data.content);
        setTitle(response.data.data.title);
        setSelectedPermission(response.data.data.permissionID);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        navigate('/admin/view-guide');
      }
    );
  };
  if (id) {
    EditGuide();
  }
  const handleChange = (value: any) => {
    setContent(value);
  };
  const fetchPermission = () => {
    setLoadingPermission(true);
    UserService.GetRecord('permissions').then(
      (response: any) => {
        setLoadingPermission(false);
        setPermissionData(
          response.data.data.map((data: any) => ({
            value: data.id,
            label: data.name,
          }))
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoadingPermission(false);
      }
    );
  };
  const submitGuide = () => {
    setIsSubmitting(true);

    if (id != null) {
      UserService.UpdateRecord(
        { title, content, selectedPermission,selectedquize:selectedQuize },
        'guide/' + id
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    } else {
      UserService.PostRecord(
        { title, content, selectedPermission },
        'guide'
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    }
  };
  const handleDeleteGuide = () => {
    UserService.DeleteRecord('guide/' + id).then(
      (response: any) => {
        message.success(response.data.message);
        navigate('/admin/view-guide');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  // Define the custom toolbar options
  const toolbarOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['color'], // Add color option
      [{ align: [] }], // Add align options
      ['clean'],
    ],
  };

  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <div
              style={{
                overflowX: 'scroll',
                overflowY: 'scroll',
                maxHeight: '80%',
              }}
            >
              <h2>Documentation Management</h2>
              <div style={{ marginBottom: '10px' }}></div>
              <span style={{ marginRight: '8px', textAlign: 'left' }}>
                Select quize
              </span>
              <div style={{ marginBottom: '10px' }}></div>

              <Select
                placeholder="Assign Quize"
                optionFilterProp="children"
                title="This is quize for this document"
                options={topics}
                loading={loadingPermission}
                style={{ width: '100%' }}
                defaultValue={selectedQuize}
                value={selectedQuize}
                onChange={(value) => setSelectedQuize(value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <span style={{ marginRight: '8px', textAlign: 'left' }}>
                {' '}
                Select Permission{' '}
              </span>
              <div style={{ marginBottom: '10px' }}></div>

              <Select
                showSearch
                placeholder="Select Permission"
                optionFilterProp="children"
                title="WHo can see this guide"
                options={permissionData}
                loading={loadingPermission}
                style={{ width: '100%' }}
                defaultValue={selectedPermission}
                value={selectedPermission}
                onChange={(value) => setSelectedPermission(value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <span style={{ marginRight: '8px', textAlign: 'left' }}>
                Document Title
              </span>
              <Input
                placeholder="Enter Title"
                style={{ width: '100%' }}
                onChange={handleChangeTitle}
                value={title}
              />
              <div style={{ marginBottom: '10px' }}></div>

              <ReactQuill
                value={content}
                onChange={handleChange}
                modules={toolbarOptions}
              />
            </div>
            <Button
              type="primary"
              style={{ margin: '10px' }}
              htmlType="submit"
              onClick={submitGuide}
              disabled={isSubmitting}
            >
              {id ? 'Update' : 'Save'}
            </Button>
            {id && (
              <Button
                danger
                style={{ margin: '10px' }}
                htmlType="submit"
                onClick={handleDeleteGuide}
                disabled={isSubmitting}
              >
                Delete
              </Button>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default GuideAdmin;
