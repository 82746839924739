import { Button, Form, Input, Space, Alert, Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import ScaleLoader from 'react-spinners/ScaleLoader';
const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ChangePassword: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [PageLoaded, setPageLoaded] = useState(false);

  const onFinish = (values: any) => {};
  const handleSubmit = (values: any) => {
    // handle form submission
    setLoading(true);

    UserService.UpdateRecord(values, 'users/security/password').then(
      (response) => {
        setLoading(false);
        setSubmitBadge('success');
        setSubmitMessage('Password Change Successfully!');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSubmitBadge('error');
        setLoading(false);
        setSubmitMessage('Password Change Failed!');
      }
    );
  };
  useEffect(() => {
    setPageLoaded(true);
  }, []);
  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Space>
                  {submitMessage && submitBadge === 'success' && (
                    <Alert
                      message="Password Update"
                      description={submitMessage}
                      type="success"
                      closable
                    />
                  )}
                  {submitMessage && submitBadge === 'error' && (
                    <Alert
                      message="Password Update"
                      description={submitMessage}
                      type="error"
                      closable
                    />
                  )}
                </Space>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="changepswForm"
                  onFinish={onFinish}
                  className="changepsw"
                  initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                  }}
                  style={{ maxWidth: 600 }}
                  scrollToFirstError
                >
                  <Form.Item
                    name="oldPassword"
                    label="Old Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Old password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                          })
                          .catch((info) => {});
                      }}
                      loading={loading}
                    >
                      Save Changes
                    </Button>
                  </Form.Item>
                </Form>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default ChangePassword;
