import UserService from '../../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import { CaretRightOutlined,CopyOutlined } from '@ant-design/icons';
import React, { useState, useEffect,useRef } from 'react';
import { Input, Layout, message, Space, Row, Col,Button } from 'antd';
import { Collapse, theme } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const { Content } = Layout;
const { Search } = Input;

interface Guide {
  key: number;
  label: string;
  children?: any;
  style: any;
}
const toolbarOptions = {
  toolbar: [['bold', 'italic', 'underline', 'strike']],
};
const DocumentationComponent = () => {
  const [loading, setLoading] = useState(true);
  const [guide, setGuides] = useState<Guide[]>([]);
  const [guideData, setGuidesData] = useState<Guide[]>([]);

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  useEffect(() => {
    fetchGuides();
  }, []);

  const onSearch = (value: string) => {
    // Method 2: Using for...of loop
    const filteredItems = guideData.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase())
    );
    setGuides(filteredItems);
  };
  const handleCopy = async (code:any) => {
    try {
      navigator.clipboard.writeText(code);
      message.success('Code copied to clipboard!');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };
  const fetchGuides = () => {
    setLoading(true);
    UserService.GetRecord('guide').then(
      (response: any) => {
        setGuides(
          response.data.data.map((keyData: any) => ({
            key: keyData.id,
            label: (
              <Row gutter={[16, 16]} align="middle">
              <Col>
                <span
                  style={{
                    textAlign: 'left',
                    fontSize: '18px',
                    fontWeight: '700',
                  }}
                >
                  {keyData.title}
                </span>
              </Col>
              <Col>
                <Button
                  type="default"
                  onClick={() => handleCopy(`https://app.nomaservice.com/#/training/${keyData.title.replace(/ /g, '-')}`)}
                  icon={<CopyOutlined rev="icon-rev" />}
                >
                  Copy Link
                </Button>
              </Col>
            </Row>




            ),
            children: (
              <ReactQuill value={keyData.content} modules={toolbarOptions} readOnly />
            ),
            style: panelStyle,
          }))
        );
        setGuidesData(
          response.data.data.map((keyData: any) => ({
            key: keyData.id,
            label: keyData.title,
            children: (
              <ReactQuill value={keyData.content} modules={toolbarOptions} />
            ),
            style: panelStyle,
          }))
        );
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };


  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Documentation </h1>

            <div className="m-5">
              <Space direction="vertical">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Space>
            </div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body" style={{ overflowX: 'scroll' }}>
              <Collapse
                bordered={true}
                ghost
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    rev="rev-icon"
                    rotate={isActive ? 90 : 0}
                  />
                )}
                style={{ background: token.colorBgContainer }}
                items={guide}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default DocumentationComponent;
