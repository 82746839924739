import React, { useState, useEffect } from 'react';
import type { DatePickerProps } from 'antd';
import {
  Select,
  InputNumber,
  DatePicker,
  Input,
  Button,
  Layout,
  Skeleton,
  Alert,
  message,
  Col,
  Row,
  Modal,
  Popconfirm,
  Space,
} from 'antd';
import { formatDate } from '../../../common/utility';
import '../../../asset/style.css';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
} from '@ant-design/icons';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
const { Content } = Layout;
const { TextArea } = Input;
const { Search } = Input;
dayjs.extend(customParseFormat);

interface Location {
  id: number;
  name: string;
  description?: string;
  startDate?: string;
  farmCalendarID?: number;
  totalNumberOfHectare?: number;
  farmCalendar?: any;
}

interface Calendar {
  value: number;
  label: string;
}
const CalendarLocationComponent = () => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [locationDescription, setLocationDescription] = useState('');
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationsData, setLocationsData] = useState<Location[]>([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [editLocationId, setEditLocationId] = useState<number | null>(null);
  const [startdate, setStartdate] = useState('');
  const [farmCalendarID, setCalendarId] = useState(0);

  const [numberOfHectare, setNumberOfHectare] = useState(0);
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const handleChangeHectare = (e: any) => {
    setNumberOfHectare(e);
  };
  const dateFormat = 'YYYY-MM-DD';
  useEffect(() => {
    fetchLocations();
    fetchCalendars();
  }, []);
  const handleStartDateChange: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    setStartdate(dateString);
  };
  const fetchLocations = () => {
    setLoading(true);
    UserService.GetRecord('calender/location').then(
      (response: any) => {
        setLocations(response.data.data);
        setLocationsData(response.data.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };
  const fetchCalendars = () => {
    setLoading(true);
    UserService.GetRecord('calender/calendar-setting').then(
      (response: any) => {
        setCalendars(
          response.data.data.map((mapData: any) => ({
            label: mapData.title,
            value: mapData.id,
          }))
        );
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };
  const handleSaveLocation = () => {
    setIsSubmitting(true);
    setModal2Open(false);
    if (locationName.trim() === '') {
      return;
    }

    if (editLocationId !== null) {
      // Edit existing location
      UserService.UpdateRecord(
        {
          name: locationName,
          description: locationDescription,
          startDate: startdate,
          farmCalendarID: farmCalendarID,
          totalNumberOfHectare: numberOfHectare,
        },
        'calender/location/' + editLocationId
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
          const updatedLocations = locations.map((location) =>
            location.id === editLocationId
              ? {
                  ...location,
                  name: locationName,
                  description: locationDescription,
                  startDate: startdate,
                  totalNumberOfHectare: numberOfHectare,
                  farmCalendarID: farmCalendarID,
                }
              : location
          );
          setLocations(updatedLocations);
          setEditLocationId(null);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    } else {
      UserService.PostRecord(
        {
          name: locationName,
          description: locationDescription,
          startDate: startdate,
          farmCalendarID: farmCalendarID,
          totalNumberOfHectare: numberOfHectare,
        },
        'calender/location'
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
          const newLocation: Location = {
            id: Date.now(),
            name: locationName,
            description: locationDescription,
            startDate: startdate,
            totalNumberOfHectare: numberOfHectare,
            farmCalendarID: farmCalendarID,
          };
          setLocations([...locations, newLocation]);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    }

    setLocationName('');
    setLocationDescription('');
  };

  const handleEditLocation = (location: Location) => {
    console.error(location);
    setEditLocationId(location.id);
    setLocationName(location.name);
    setLocationDescription(location.description || '');
    setStartdate(location.startDate || '');
    setNumberOfHectare(location.totalNumberOfHectare || 0);
    setCalendarId(location.farmCalendarID || 0);
    setModal2Open(true);
  };

  const handleDeleteLocation = (locationId: number) => {
    UserService.DeleteRecord('calender/activity/' + locationId).then(
      (response) => {
        const updatedLocations = locations.filter(
          (location) => location.id !== locationId
        );
        setLocations(updatedLocations);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };

  const onSearch = (value: string) => {
    // Method 2: Using for...of loop
    const filteredItems = locationsData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setLocations(filteredItems);
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Calender Location Management</h1>
            <div className="m-5">
              <Button
                type="primary"
                onClick={() => {
                  setModal2Open(true);
                  setStartdate(Date());
                  setEditLocationId(null);
                  setLocationName('');
                  setCalendarId(0);
                  setLocationDescription('');
                }}
              >
                Create Location
              </Button>
            </div>
            <div className="m-5">
              <Space direction="vertical">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Space>
            </div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            <div className="card-body">
              <Row gutter={[16, 16]}>
                {locations.length > 0 ? (
                  locations.map((location) => (
                    <Col
                      key={location.id}
                      span={8}
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      <div className="card">
                        <div className="card-content">
                          <h2 className="card-title">{location.name}</h2>
                          <p className="card-description">
                            Start Date: {formatDate(location.startDate)}
                          </p>
                          <p className="card-description">
                            Hectar: {location.totalNumberOfHectare}
                          </p>
                          <p className="card-description">
                            {location.description}
                          </p>
                        </div>
                        <div className="card-buttons">
                          <Button
                            type="text"
                            className="edit-button"
                            onClick={() => {
                              handleEditLocation(location);
                            }}
                          >
                            <EditOutlined rev="rev-icon" />
                          </Button>
                          <Popconfirm
                            title="Delete the activity"
                            description="Are you sure to delete this activity?"
                            icon={
                              <QuestionCircleOutlined
                                rev="rev-icon"
                                style={{ color: 'red' }}
                              />
                            }
                            onConfirm={() => {
                              handleDeleteLocation(location.id);
                            }}
                          >
                            <Button type="text" className="delete-button">
                              <DeleteOutlined rev="rev-icon" />
                            </Button>
                          </Popconfirm>
                        </div>
                        <Skeleton loading={loading} avatar active></Skeleton>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div className="m-5">
                    <Alert message="Data not found!" showIcon type="error" />
                  </div>
                )}
              </Row>
            </div>
            <Modal
              title={editLocationId ? 'Update Location' : 'Create Location'}
              centered
              open={modal2Open}
              footer={[
                <Button
                  loading={isSubmitting}
                  key="save"
                  onClick={handleSaveLocation}
                >
                  {editLocationId ? 'Update' : 'Save'}
                </Button>,
                <Button key="cancel" onClick={() => setModal2Open(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => setModal2Open(false)}
            >
              <Input
                placeholder="Title"
                value={locationName}
                style={{ width: '100%' }}
                onChange={(e) => setLocationName(e.target.value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <Select
                defaultValue={farmCalendarID}
                showSearch
                placeholder="Select Calendar"
                optionFilterProp="children"
                options={calendars}
                style={{ width: '100%' }}
                onChange={(value) => setCalendarId(value)}
              />
              <div style={{ marginBottom: '10px' }}></div>

              <DatePicker
                placeholder="Start Date"
                style={{ width: '100%' }}
                onChange={handleStartDateChange}
                defaultValue={dayjs(startdate, dateFormat)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <InputNumber
                placeholder="Number of Hectars"
                style={{ width: '100%' }}
                value={numberOfHectare}
                onChange={handleChangeHectare}
              />
              <div style={{ marginBottom: '10px' }}></div>

              <TextArea
                placeholder="Description"
                value={locationDescription}
                rows={4}
                onChange={(e) => setLocationDescription(e.target.value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default CalendarLocationComponent;
