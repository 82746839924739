import {
  Layout,
  Row,
  Col,
  Image,
  Typography,
  Button,
  message,
  Modal,
  Form,
  Input,
} from 'antd';
import React, { useState } from 'react';
import '../../../costom-css/farmer.css';
import { CloseCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../services/user.service';

const { Text } = Typography;
const { Content } = Layout;
interface ChildProps {
  propsForProfile: any; // Adjust the type according to your data
}
const AccountCreationAction = {
  verifyAndCreate: 'verifyAndCreate',
  CreateOnly: 'CreateOnly',
};

const FarmerProfilePage: React.FC<ChildProps> = (props: any) => {
  const receivedProp = props.propsForProfile;
  const navigate = useNavigate();
  const [isLoading, SetisLoading] = useState(false);
  const [accountNo, setAccountNo] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [customerName, setCustomerName] = useState('');

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    NameEnquiryByAccountNumber(e.target.value);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
  };

  const GenerateFCMBAcct = async (
    id: number,
    action: string,
    assignATM: number = 0
  ) => {
    SetisLoading(true);
    UserService.PostRecord(
      { action, assignATM },
      `farmers/generate-fcmb-acc-no/${id}`
    ).then(
      (response) => {
        if (response.data.data) {
          setAccountNo(response.data.data.accountNumber);
          setCustomerId(response.data.data.customerId);
          SetisLoading(false);
        }
        message.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        SetisLoading(false);
      }
    );
  };
  const NameEnquiryByAccountNumber = async (accountNumber: string) => {
    setIsAccountVerified(false);

    if (accountNumber.length !== 10) {
      return false;
    }
    setLoading(true);
    UserService.GetRecord(
      `utilities/name-enquiry-by-account-number/${accountNumber}`
    ).then(
      (response) => {
        message.success('Account verified successfully');
        setCustomerName(response.data.accountName);
        setLoading(false);
        setIsAccountVerified(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        SetisLoading(false);
        setLoading(false);
      }
    );
  };
  const onFinish = (value: object) => {
    setLoading(true);
    UserService.PostRecord(
      value,
      `farmers/add-bank-account/${receivedProp.uuid}`
    ).then(
      (response) => {
        message.success('Account verified successfully');
        setLoading(false);
        setOpen(false);
        setAccountNo(response.data.accountNumber);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        SetisLoading(false);
        setLoading(false);
      }
    );
  };
  return (
    <>
      {
        <div className="App">
          <Content style={{ margin: '50px' }}>
            <Button
              onClick={() => navigate(0)}
              danger
              style={{ position: 'absolute', left: '5px' }}
            >
              <CloseCircleFilled rev="icon-rev" />
            </Button>
            <div style={{ margin: '20px 0' }} />

            {
              <>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={7}
                    lg={7}
                    xl={7}
                    style={{ textAlign: 'left', padding: '10px' }}
                  >
                    <Image
                      src={'data:image/jpeg;base64,' + receivedProp.Base64Image}
                      alt="Base64 Image"
                      style={{ width: '100%', maxHeight: '150px' }}
                    />
                    <div
                      style={{
                        marginBottom: '60px',
                        textAlign: 'left',
                        padding: '15px',
                      }}
                    >
                      <p>{receivedProp.email}</p>
                      <p>{'Farmer ID : ' + receivedProp.uuid}</p>
                      <p style={{ paddingBottom: '10px' }}>
                        {!receivedProp.farmerAccountDetail && !accountNo ? (
                          <Button onClick={showModal}>
                            <PlusCircleOutlined rev="icon-rev" /> Locally add
                            FCMB Account
                          </Button>
                        ) : (
                          ''
                        )}{' '}
                      </p>
                      {receivedProp.assignATM !== 1 &&
                      receivedProp.assignATM !== 2 ? (
                        <p>
                          <Button
                            loading={isLoading}
                            style={{ margin: '5px' }}
                            onClick={() =>
                              GenerateFCMBAcct(
                                receivedProp.uuid,
                                AccountCreationAction.CreateOnly,
                                1
                              )
                            }
                          >
                            Enable ATM
                          </Button>
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={17}
                    lg={17}
                    xl={17}
                    style={{ textAlign: 'left', padding: '10px' }}
                  >
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>FirstName: {receivedProp.first_name}</Text>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>MiddleName: {receivedProp.middleName}</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>LastName: {receivedProp.last_name}</Text>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>Agent: {receivedProp.Agent}</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>Phone: {receivedProp.phone}</Text>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>
                          Default AccountNo: {receivedProp.accountNum}
                        </Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>Gendar: {receivedProp.sex}</Text>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>City: {receivedProp.city}</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>DOB: {receivedProp.dob}</Text>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>Occupation: {receivedProp.occupation}</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>
                          State OF Origin: {receivedProp.state_of_origin}
                        </Text>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Text>Address: {receivedProp.address}</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: '15px' }}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Text>Location Area: {receivedProp.locationArea}</Text>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Text>
                          <a
                            href={receivedProp.locationLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Location Link: {receivedProp.locationLink}
                          </a>
                        </Text>
                      </Col>
                    </Row>
                    <h2>Farmer's Account Details</h2>
                    {/*  */}
                    {accountNo && (
                      <Row style={{ padding: '15px' }}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Text>Bank Account NO: {accountNo}</Text>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Text>Customer ID: {customerId}</Text>
                        </Col>
                      </Row>
                    )}
                    {receivedProp.farmerAccountDetail ? (
                      <>
                        <Row style={{ padding: '15px' }}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Text>
                              Bank Account NO:{' '}
                              {receivedProp.farmerAccountDetail.accountNumber}
                            </Text>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Text>
                              Bank: {receivedProp.farmerAccountDetail.Bank.name}
                            </Text>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Text>
                              Customer ID:{' '}
                              {receivedProp.farmerAccountDetail.customerId}
                            </Text>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Text>
                              ATMNO:{' '}
                              {receivedProp.farmerAccountDetail.atmCardNo}
                            </Text>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Button
                          loading={isLoading}
                          style={{ margin: '5px' }}
                          onClick={() =>
                            GenerateFCMBAcct(
                              receivedProp.uuid,
                              AccountCreationAction.verifyAndCreate
                            )
                          }
                        >
                          Generate FCMB Account with BVN Check
                        </Button>
                        <Button
                          loading={isLoading}
                          style={{ margin: '5px' }}
                          onClick={() =>
                            GenerateFCMBAcct(
                              receivedProp.uuid,
                              AccountCreationAction.CreateOnly
                            )
                          }
                        >
                          Generate FCMB Account
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            }
          </Content>
          <Modal
            footer={[
              <Button key="back" onClick={handleCancel}>
                Return
              </Button>,
            ]}
            title={
              <div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false);
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true);
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                // end
              >
                Save existing FCMB account
              </div>
            }
            open={open}
            onCancel={handleCancel}
          >
            <Form
              onFinish={onFinish}
              name="wrap"
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
              style={{ maxWidth: 600 }}
            >
              <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={[{ required: true }]}
              >
                <Input onChange={(val: any) => onChange(val)} />
              </Form.Item>
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} sm={24} md={6}></Col>
                <Col className="gutter-row" xs={24} sm={24} md={12}>
                  <p>
                    {loading ? (
                      <>Comfirming Account...</>
                    ) : (
                      <b>{customerName}</b>
                    )}{' '}
                  </p>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <Form.Item>
                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={!isAccountVerified}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      }
    </>
  );
};

export default FarmerProfilePage;
