import React, { useState, useEffect } from 'react';
import { Card, Radio, Button, message, Result } from 'antd';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import { Layout } from 'antd';
import UserService from '../../../../services/user.service';

const { Content } = Layout;

const QuizeModule = () => {
  const [questions, setQuestions] = useState<any>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [topics, setTopics] = useState([]);

  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(null);
  const fetchTopics = async () => {
    try {
      const endpoint = UserService.GetRecord('quize/topic/user');
      endpoint.then(
        (response) => {
          setTopics(response.data.data);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const fetchQuestion = async (topicName: string) => {
    try {
      const endpoint = UserService.GetRecord(
        `quize/question-user/${topicName.replace(/ /g, '-')}`
      );
      endpoint.then(
        (response) => {
          setQuestions(response.data.data);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
          setQuestions([]);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchTopics();
  }, []);

  const handleAnswerChange = (index: any, value: number) => {
    const updatedOptions = [...selectedAnswers];
    updatedOptions[index] = value;
    console.log(updatedOptions);
    setSelectedAnswers(updatedOptions);
  };

  const handleSubmit = () => {
    try {
      const payload = { options: selectedAnswers };
      const endpoint = UserService.PostRecord(payload, 'quize/answers');
      endpoint.then(
        (response) => {
          setSubmitted(true);
          message.success('Answer Submitted successfully');
          setQuestions([]);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
        }
      );
    } catch (error) {
      console.error(error);
      message.error('Failed to add question');
    }
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Quize</h1>

            <div style={{ padding: '20px' }}>
              {score !== null ? (
                <Card
                  title="Quize Result"
                  style={{ width: '400px', margin: 'auto' }}
                >
                  <p>Your Score: {score}%</p>
                </Card>
              ) : (
                <>
                  {questions.length > 0 && (
                    <>
                      {!submitted ? (
                        <>
                          {questions.map((question: any, index: number) => (
                            <Card
                              key={question.id}
                              title={`Question ${index + 1}`}
                              style={{ width: '400px', margin: '20px auto' }}
                            >
                              <p>{question.question}</p>
                              <Radio.Group
                                onChange={(e) =>
                                  handleAnswerChange(
                                    question.id,
                                    e.target.value
                                  )
                                }
                              >
                                {question.QuestionOptions.map((option: any) => (
                                  <Radio key={option.id} value={option.id}>
                                    {option.option}
                                  </Radio>
                                ))}
                              </Radio.Group>
                            </Card>
                          ))}
                          {/* <Button
                            type="primary"
                            onClick={handleSubmit}
                            style={{ margin: '20px auto', display: 'block' }}
                          >
                            Submit Answers
                          </Button> */}
                        </>
                      ) : (
                        <>
                          <Result
                            status="success"
                            title="Answer Submitted Successfully"
                            subTitle="Thank you for your time."
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {questions.length === 0 && (
                <>
                  {topics.map((topicsData: any) => (
                    <Card
                      onClick={() => fetchQuestion(topicsData.name)}
                      title={`${topicsData.name}`}
                      style={{ width: '600px', margin: '20px auto' }}
                    ></Card>
                  ))}
                </>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default QuizeModule;
