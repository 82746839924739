import UserService from '../../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  Layout,
  Card,
  Skeleton,
  Alert,
  message,
  Col,
  Row,
  Popconfirm,
  Space,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
} from '@ant-design/icons';
const { Meta } = Card;

const { Content } = Layout;
const { Search } = Input;

interface Guide {
  id: number;
  title: string;
  content?: string;
  permission?: any;
  cover_image?: string;
}

const GuideComponent = () => {
  const [loading, setLoading] = useState(true);
  const [guide, setGuides] = useState<Guide[]>([]);
  const [guideData, setGuidesData] = useState<Guide[]>([]);
  let navigate = useNavigate();
  useEffect(() => {
    fetchGuides();
  }, []);

  const fetchGuides = () => {
    setLoading(true);
    UserService.GetRecord('guide').then(
      (response: any) => {
        setGuides(response.data.data);
        setGuidesData(response.data.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };
  const handleDeleteGuide = (id: number) => {
    UserService.DeleteRecord('guide/' + id).then(
      (response: any) => {
        message.success(response.data.message);
        const updatedGuideData = guideData.filter((guid) => guid.id !== id);
        const updatedGuide = guide.filter((guid) => guid.id !== id);
        setGuides(updatedGuide);
        setGuidesData(updatedGuideData);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };

  const onSearch = (value: string) => {
    // Method 2: Using for...of loop
    const filteredItems = guideData.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setGuides(filteredItems);
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Manage System Documentation </h1>

            <div className="m-5">
              <Button type="primary" onClick={() => navigate('/admin-guide')}>
                Add Guide
              </Button>
            </div>
            <div className="m-5">
              <Space direction="vertical">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Space>
            </div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body">
              <Row gutter={[16, 16]}>
                {guide.length > 0 ? (
                  guide.map((guideData) => (
                    <Col
                      key={guideData.id}
                      span={8}
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      <div className="card">
                        <div className="card-content">
                          <h2 className="card-title">{guideData.title}</h2>
                        </div>
                        <div className="card-buttons">
                          <Button
                            type="text"
                            className="edit-button"
                            onClick={() => {
                              navigate(`/admin-guide/${guideData.id}`);
                            }}
                          >
                            <EditOutlined rev="rev-icon" />
                          </Button>
                          <Popconfirm
                            title="Delete the document"
                            description="Are you sure to delete this document?"
                            icon={
                              <QuestionCircleOutlined
                                rev="rev-icon"
                                style={{ color: 'red' }}
                              />
                            }
                            onConfirm={() => {
                              handleDeleteGuide(guideData.id);
                            }}
                          >
                            <Button type="text" className="delete-button">
                              <DeleteOutlined rev="rev-icon" />
                            </Button>
                          </Popconfirm>
                        </div>
                        <Skeleton loading={loading} avatar active></Skeleton>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div className="m-5">
                    <Alert message="Data not found!" showIcon type="error" />
                  </div>
                )}
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default GuideComponent;
