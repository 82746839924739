import {
  List,
  Table,
  Button,
  Popconfirm,
  Collapse,
  Result,
  Modal,
  Badge,
  Layout,
  Alert,
  Form,
  Input,
  Card,
  message,
  Flex,
  Tag,
  Col,
  Row,
  Space,
} from 'antd';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import UserService from '../../../services/user.service';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import RoleHooks from '../../../hooks/verifyRole';
import { FileExcelOutlined } from '@ant-design/icons';
import { ExportExcel } from '../../../library/Excelexport';
import { generatePDF } from '../../../library/PDFexport';
import { CaretRightOutlined } from '@ant-design/icons';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import type { ColumnsType } from 'antd/es/table';
const { TextArea } = Input;
const { permissionsData } = RoleHooks();

const { Panel } = Collapse;
const { Content } = Layout;
interface selectOptions {
  value: number;
  label: string;
}
const panelStyle = {
  marginBottom: 24,

  border: 'none',
};
interface transactionType {
  id: number;
  amount: number;
  measurement: string;
  reference: string;
  quantity: number;
  TransactionStatus: string;
  TransactionType: string;
  Item: string;
  uuid: string;
  Farmer: string;
  User: string;
  agentRemarks: string;
  auditorsComments: string;
  FcmbCustomerReference: string;
  FcmbStan: string;
  FcmbTransactionId: string;
  createdAt: Date;
  accountNumber: string;
}

const ViewTransaction: React.FC = (props) => {
  const [form] = Form.useForm();
  const [transactionStatus, setTransactionStatus] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [transaction, setTransaction] = useState<transactionType[]>([]);
  const [customGapSize, setCustomGapSize] = React.useState<number>(0);
  const [gapSize, setGapSize] = React.useState<SizeType | 'customize'>('large');
  const [loading, setLoading] = useState(false);
  const [transactionSearchType, setTransactionSearchType] = useState('');
  const [transactionSearchStatus, setTransactionSearchStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionReference, setTransactionReference] = useState('');
  const [transactionData, setTransactionData] = useState<transactionType>();
  const [comment, setComment] = useState('');
  const [pin, setPin] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [farmers, setFarmers] = useState<selectOptions[]>([]);
  const [farmer, setFarmer] = useState();
  const [responseData, setResponseData] = useState<any>({});

  const showModal = (reference: string) => {
    setTransactionReference(reference);
    findTransaction(reference);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    UserService.PostRecord(
      { comments: comment, reference: transactionReference, pin },
      `transaction/farmer/approve-transaction`
    ).then(
      (result: any) => {
        if (result.data) {
          message.success(result.data.message);
          setResponseData(result.data.data);
          loadTransaction(transactionSearchType, transactionSearchStatus);
          setMessageError('');
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setMessageError(resMessage);
      }
    );
  };
  const handleReject = () => {
    UserService.PostRecord(
      { comments: comment, reference: transactionReference },
      `transaction/farmer/reject-transaction`
    ).then(
      (result: any) => {
        if (result.data) {
          message.success(result.data.message);
          setMessageError('');
          loadTransaction(transactionSearchType, transactionSearchStatus);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadMeta = async () => {
    UserService.GetRecord('transaction/transaction-meta').then(
      (result: any) => {
        if (result.data) {
          setTransactionStatus(
            result.data.data.transactionStatus.map((option: any) => ({
              value: option.id,
              label: option.name,
            }))
          );
          setTransactionType(
            result.data.data.transactionType.map((option: any) => ({
              value: option.id,
              label: option.name,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };
  const loadTransaction = async (
    transaction_type = '',
    transaction_status = '',
    searchStr = '',
    farmerStr = ''
  ) => {
    setLoading(true);
    UserService.GetRecord(
      `transaction/farmer/transaction?transaction_type=${transaction_type}&transaction_status=${transaction_status}&search=${searchStr}&farmer=${farmerStr}`
    ).then(
      (result: any) => {
        if (result.data) {
          setLoading(false);
          setTransaction(
            result.data.data.map((option: any, index: number) => ({
              id: index + 1,
              Farmer: option.Farmer.first_name + ' ' + option.Farmer.last_name,
              amount: option.amount,
              uuid: option.Farmer.uuid,
              measurement: option.measurement,
              reference: option.reference,
              quantity: option.quantity,
              TransactionStatus: option.TransactionStatus.name,
              TransactionType: option.TransactionType.name,
              Item: option.Item.name,
              User: option.User.first_name + ' ' + option.User.last_name,
              agentRemarks: option.agentRemarks,
              auditorsComments: option.auditorsComments,
              createdAt: option.createdAt,
              FcmbCustomerReference: option.fcmb_customer_Reference,
              FcmbStan: option.fcmb_stan,
              FcmbTransactionId: option.fcmb_tran_id,
              accountNumber: option.farmerAccountDetail.accountNumber,
            }))
          );
        }
      }, //
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          message.error(resMessage)
      }
    );
  };
  const onChangeTransactionStatus = (e: any) => {
    setTransactionSearchStatus(e.value);
  };
  const onChangeTransactionType = (e: any) => {
    setTransactionSearchType(e.value);
  };
  const onChangeFarmer = (e: any) => {
    setFarmer(e.value);
  };
  const findTransaction = (reference: any) => {
    UserService.GetRecord(`transaction/farmer/transaction/${reference}`).then(
      (result: any) => {
        if (result.data) {
          const res = result.data.data;
          setTransactionData({
            id: 1,
            amount: res.amount,
            Farmer: res.Farmer.first_name + ' ' + res.Farmer.last_name,
            uuid: res.Farmer.uuid,
            Item: res.Item.name,
            measurement: res.measurement,
            quantity: res.quantity,
            reference: res.reference,
            TransactionStatus: res.TransactionStatus.name,
            TransactionType: res.TransactionType.name,
            User: res.User.first_name + ' ' + res.User.last_name,
            agentRemarks: res.agentRemarks,
            auditorsComments: res.auditorsComments,
            createdAt: res.createdAt,
            FcmbCustomerReference: res.fcmb_customer_Reference,
            FcmbStan: res.fcmb_stan,
            FcmbTransactionId: res.fcmb_tran_id,
            accountNumber: res.farmerAccountDetail.accountNumber,
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  useEffect(() => {
    farmersList();
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  useEffect(() => {
    loadMeta();
    loadTransaction(
      transactionSearchType,
      transactionSearchStatus,
      searchText,
      farmer
    );
  }, [transactionSearchType, transactionSearchStatus, searchText, farmer]);
  const exportExcel = () => {
    ExportExcel({
      excelDelta: transaction,
      fileName: 'TransactionLog',
    });
  };

  const columns: ColumnsType<transactionType> = [
    {
      title: 'SN',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Farmer',
      dataIndex: 'Farmer',
      key: 'Farmer',
    },
    {
      title: 'Farmer ID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Item',
      dataIndex: 'Item',
      key: 'Item',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Unit',
      dataIndex: 'measurement',
      key: 'measurement',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'TransactionStatus',
      key: 'TransactionStatus',
      render: (res) => (
        <Badge
          status={
            res.TransactionStatus === 'Pending'
              ? 'processing'
              : res.TransactionStatus === 'Complete'
              ? 'success'
              : 'error'
          }
          text={res.TransactionStatus}
        />
      ),
    },
    {
      title: 'TransactionType',
      dataIndex: 'TransactionType',
      key: 'TransactionType',
    },
    // {
    //   title: 'POS-Agent',
    //   dataIndex: 'User',
    //   key: 'User',
    // },
    {
      title: 'Action',
      key: 'action',

      render: (_, record) => (
        <Space size="middle">
          {record &&
            permissionsData.includes('pos-agent') &&
            (record.TransactionStatus === 'Pending' ? (
              <Popconfirm
                title="Cancel Request"
                description="Are you sure to cancel this request?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => cancelRequest(record.reference)}
              >
                <Button danger>Cancel</Button>
              </Popconfirm>
            ) : (
              <Button type="dashed" disabled={true}>
                {record.TransactionStatus}
              </Button>
            ))}
          {
            <Button type="default" onClick={() => showModal(record.reference)}>
              View
            </Button>
          }
        </Space>
      ),
    },
  ];
  const cancelRequest = (id: string) => {
    UserService.GetRecord(`transaction/farmer/cancel-transaction/${id}`).then(
      (result: any) => {
        if (result.data) {
          message.success(result.data.message);
          loadTransaction(transactionSearchType, transactionSearchStatus);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };
  const renderPage = (pageData: any) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        // style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel header={option.Farmer} key={index} style={panelStyle}>
            <List
              size="small"
              footer={
                <>
                  <Space size="middle">
                    {option &&
                      permissionsData.includes('pos-agent') &&
                      (option.TransactionStatus === 'Pending' ? (
                        <Popconfirm
                          title="Cancel Request"
                          description="Are you sure to cancel this request?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => cancelRequest(option.reference)}
                        >
                          <Button danger>Cancel</Button>
                        </Popconfirm>
                      ) : (
                        <Button danger>Cancelled</Button>
                      ))}
                    {
                      <Button
                        type="default"
                        onClick={() => showModal(option.reference)}
                      >
                        View
                      </Button>
                    }
                  </Space>
                </>
              }
              bordered
              //
              dataSource={[
                'Farmer ID: ' + option.uuid,
                'Amount: ' + option.amount,
                'status: ' + option.TransactionStatus,
                'Created At: ' + option.createdAt,
                <Badge
                  status={
                    option.TransactionStatus === 'Pending'
                      ? 'processing'
                      : option.TransactionStatus === 'Complete'
                      ? 'success'
                      : 'error'
                  }
                  text={option.TransactionStatus}
                />,
              ]}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchText(e.target.value);
  };
  const farmersList = () => {
    UserService.GetRecord('farmers').then((result) => {
      if (result.data) {
        setFarmers(
          result.data.map((option: any) => ({
            value: option.uuid,
            label: option.first_name + ' ' + option.last_name,
          }))
        );
      }
    });
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <Card
              title="Transaction Summary"
              style={{ width: '100%', margin: '0 auto' }}
              id="BankingAgent"
            >
              {/* <Flex gap={gapSize !== 'customize' ? gapSize : customGapSize}> */}

              <Button onClick={exportExcel}>
                <FileExcelOutlined rev="" />
                Export
              </Button>
              <div style={{ marginTop: '15px' }}>
                <Row gutter={[16, 16]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                    xl={{ span: 5 }}
                  >
                    <Select
                      placeholder="Sort By Transaction Status"
                      options={transactionStatus}
                      onChange={onChangeTransactionStatus}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                    xl={{ span: 5 }}
                  >
                    <Select
                      placeholder="Sort By Transaction Type"
                      options={transactionType}
                      onChange={onChangeTransactionType}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                    xl={{ span: 5 }}
                  >
                    <Select
                      placeholder="Select Farmer"
                      options={farmers}
                      onChange={onChangeFarmer}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                    xl={{ span: 5 }}
                  >
                    <Input
                      onChange={onChange}
                      placeholder="Search Transaction Ref"
                    />
                  </Col>
                </Row>
              </div>

              {/* </Flex> */}
              <div style={{ marginTop: '20px' }}></div>
              {!isMobile && (
                <Table
                  size="middle"
                  bordered
                  scroll={{ x: 'calc(700px + 50%)' }}
                  loading={loading}
                  style={{ marginTop: '20px' }}
                  dataSource={transaction}
                  columns={columns}
                />
              )}
              {isMobile && (
                <>
                  {/* Render the current page of data */}
                  {renderPage(transaction)}
                  {/* Render pagination component */}
                </>
              )}
            </Card>
            {transactionData && (
              <Modal
                title={transactionData.Farmer}
                open={isModalOpen}
                footer={
                  permissionsData.includes('auditor')
                    ? [
                        <Button
                          onClick={handleOk}
                          disabled={
                            transactionData.TransactionStatus != 'Pending'
                          }
                        >
                          Approve
                        </Button>,
                        <Button
                          danger
                          disabled={
                            transactionData.TransactionStatus != 'Pending'
                          }
                          onClick={handleReject}
                        >
                          Reject
                        </Button>,
                        <Button
                          key="pdf"
                          onClick={() => {
                            generatePDF('screen');
                          }}
                          danger
                        >
                          PDF
                        </Button>,
                        <Button onClick={handleCancel}>Cancel</Button>,
                      ]
                    : [
                        <Button
                          key="pdf"
                          danger
                          onClick={() => {
                            generatePDF('screen');
                          }}
                        >
                          PDF
                        </Button>,
                        <Button onClick={handleCancel}>Cancel</Button>,
                      ]
                }
              >
                <div id="screen" style={{ padding: '10px', margin: '10px' }}>
                  <Result
                    status={
                      (transactionData
                        ? transactionData.TransactionStatus === 'Complete'
                          ? 'success'
                          : transactionData.TransactionStatus === 'Pending'
                          ? 'warning'
                          : 'warning'
                        : 'warning') as any
                    }
                    title={`Transaction ${transactionData.TransactionStatus}`}
                  />

                  <p>Credit FCMB Account: {transactionData.accountNumber}</p>
                  <p>Amount: {transactionData.amount}</p>
                  <p>Reference: {transactionData.reference}</p>
                  <p>Item: {transactionData.Item}</p>
                  <p>Quantity: {transactionData.quantity}</p>
                  <p>Unit: {transactionData.measurement}</p>
                  <p>Status: {transactionData.TransactionStatus}</p>
                  <p>Transaction Type: {transactionData.TransactionType}</p>
                  <p>Naration: {transactionData.agentRemarks}</p>
                  <p>Auditor Naration: {transactionData.auditorsComments}</p>
                  <p>Agent: {transactionData.User}</p>

                  {responseData ? (
                    <>
                      <p>Transaction Stan: {responseData.stan}</p>
                      <p>
                        Customer Reference: {responseData.customer_Reference}
                      </p>
                      <p>Bank Transaction ID: {responseData.tran_id}</p>
                    </>
                  ) : (
                    <>
                      <p>Transaction Stan: {transactionData.FcmbStan}</p>
                      <p>
                        Customer Reference:{' '}
                        {transactionData.FcmbCustomerReference}
                      </p>
                      <p>
                        Bank Transaction ID: {transactionData.FcmbTransactionId}
                      </p>
                    </>
                  )}
                  <p>Date: {transactionData.createdAt.toString()}</p>
                </div>
                {permissionsData.includes('auditor') && (
                  <>
                    <TextArea
                      defaultValue={transactionData.auditorsComments}
                      rows={4}
                      placeholder="Leave a comment "
                      onChange={(e) => setComment(e.target.value)}
                      disabled={transactionData.TransactionStatus != 'Pending'}
                    />
                    <Input
                      style={{ margin: '5px' }}
                      placeholder="Enter Transaction Pin, ****"
                      type="password"
                      onChange={(e) => setPin(e.target.value)}
                      disabled={transactionData.TransactionStatus != 'Pending'}
                    />
                  </>
                )}
                {messageError && <Alert message={messageError} type="error" />}
              </Modal>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default ViewTransaction;
