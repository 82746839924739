import React, { useRef, useState, useEffect } from 'react';
import {
  CaretRightOutlined,
  DeleteFilled,
  FileExcelOutlined,
  FilePdfOutlined,
  EyeFilled,
  EditFilled,
  UploadOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Popconfirm,
  Collapse,
  Form,
  Input,
  Select,
  Space,
  Modal,
  message,
  Badge,
  theme,
  Pagination,
  Layout,
  Table,
  Image,
  Alert,
  Col,
  Row,
  Upload,
} from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { List } from 'antd';
import { Divider } from 'antd';
import { Tooltip } from '@mui/material';
import { ExportExcel } from '../../../library/Excelexport';
import { generatePDF } from '../../../library/PDFexport';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import Highlighter from 'react-highlight-words';
import RoleHooks from '../../../hooks/verifyRole';
import BasicPDFDocument from '../../dfdocument';
const { permissionsData } = RoleHooks();

const API_URL = `${process.env.REACT_APP_API_END_POINT}`;
const { Option } = Select;
const { Content } = Layout;
const { Panel } = Collapse;
interface agentType {
  key: React.Key;
  firstName: string;
  lastName: string;
  otherName: string;
  phone: number;
  email: string;
  status: string;
  AgentID: string;
  accountNumber: string;
  address: string;
  bankID: string;
  bvnNumber: string;
  dob: string;
  gender: string;
  maritalStatus: string;
  ninNumber: string;
  other_name: string;
  lga: any;
  createdAt: string;
  state?: any;
  bank?: any;
  Business?: any;

  Witness?: any;
  AccountInformations?: any;
  bankingStatusID?: number;
  bankingStatus?: string;
  Status: any;
  occupation: string;
  employmentStatus?: string;
  motherMaidenName?: string;
  houseNum?: string;
  city?: string;
  streetName?: string;
  superagent?: string;
}

type DataIndex = keyof agentType;
const Agents: React.FC = (props) => {
  const [form] = Form.useForm();
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [isopenModalProgress, setIsopenModalProgress] = useState(false);
  const [agentData, setAgentData] = useState<agentType[]>([]);
  const [selectedData, setSelectedData] = useState<agentType>();
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [statusData, setStatusData] = useState<[]>();
  const [viewableStatusData, setViewableStatusData] = useState<[]>();
  const [pageSize, setPageSize] = useState(2000);
  const [opendrawer, setOpenDrawer] = useState(false);
  const [agentBankDetals, setAgentBankDetals] = useState([]);
  const [bank, setBank] = useState<{ value: string; label: string }[]>([]);
  const [loadingBank, setLoadingBank] = useState(true);
  const [loadingState, setLoadingState] = useState(true);
  const [loadingLGA, setLoadingLGA] = useState(true);
  const [stringName, setStringName] = useState('');
  const [NGlga, setNGlga] = useState<{ value: string; label: string }[]>([]);
  const [NGstate, setNGstate] = useState<{ value: string; label: string }[]>(
    []
  );
  const [passportfileList, setPassportFileList] = useState<any>([]);
  const [uploadIDfileList, setUuploadIDFileList] = useState<any>([]);
  const [signaturefileList, setSignatureFileList] = useState<any>([]);
  const [witnessSignaturefileList, setWitnessSignatureUuploadIDFileList] =
    useState<any>([]);
  const [EditUserID, setEditUser] = useState(0);
  const [EditBusinessID, setEditBusinessID] = useState(0);
  const [EditWittnessID, setEditWittnessID] = useState(0);
  const [success, setSuccess] = useState<string>('');
  const [errorMsg, setError] = useState<string>('');
  const { token } = theme.useToken();
  const [loadingBankDetails, setLoadingBankDetails] = useState(false);
  const [errorCreatingAccount, setErrorCreatingAccount] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [accountNumber, setAccountNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmingAgent, setConfirmingAgent] = useState(false);
  const [agentID, setAgentID] = useState('');
  const [customerBankName, setCustomerBankName] = useState('');

  const [isAgentAccountVerified, setIsAgentAccountVerified] = useState(false);
  const [showPDF, setshowPDF] = useState(0);

  //
  const LoadDetails = (Data: agentType) => {
    setIsopenModalProgress(true);
    setSelectedData(Data);
    getBankDetails(Data.key);
  };
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [verifyBVN, setVerifyBVN] = useState(false);
  const [hidCreateAccountBTN, setHidCreateAccountBTN] = useState(true);
  const getBankDetails = (id: any) => {
    setLoadingBankDetails(true);
    UserService.GetRecord(`banking/banks/details/${id}`).then((result) => {
      setLoadingBankDetails(false);
      if (result.data.data.length > 0) {
        setAgentBankDetals(
          result.data.data.map((option: any) => ({
            id: option.id,
            bank: option.Bank.name,
            account: option.accountNumber,
            userID: option.userID,
          }))
        );
        const fcmbBankCode = 25; //214
        const bankWithCode26 = result.data.data.find(
          (bank: any) => bank.bankID === fcmbBankCode
        );
        if (bankWithCode26) {
          setHidCreateAccountBTN(true);
        } else {
          setHidCreateAccountBTN(false);
        }
      }
    });
  };
  const LoadStatus = () => {
    UserService.GetRecord('utilities/status/bankingAgent').then(
      (result) => {
        setViewableStatusData(
          result.data.allStatus.map((option: any) => ({
            value: option.id,
            label: option.usertype + ' ' + option.name,
          }))
        );
        setStatusData(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.usertype + ' ' + option.name,
          }))
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const exportExcel = () => {
    let searchurl = `banking/agent?page=0&pageSize=0`;
    UserService.GetRecord(searchurl).then((result) => {
      if (result.data) {
        const pushData = result.data.data.map((option: any) => ({
          firstName: option.first_name,
          lastName: option.last_name,
          phone: option.phone,
          email: option.email,
          AgentID: option.AgentID,
          otherName: option.other_name,
          AccountInformations: option.AccountInformations.map(
            (details: any) => ({
              Bank: details.Bank.name,
              accountNumber: details.accountNumber,
            })
          ),
          address: option.address,
          bvnNumber: option.bvnNumber,
          dob: option.dob,
          gender: option.gender,
          maritalStatus: option.maritalStatus,
          ninNumber: option.ninNumber,
          other_name: option.other_name,
          state: option.State ? option.State.name : '',
          lga: option.LGA ? option.LGA.name : '',
          modeOfIdentification: option.Business
            ? option.Business.modeOfIdentification
            : '',
          IDnumber: option.Business ? option.Business.IDnumber : '',
          uploadID: option.Business
            ? API_URL + 'monitor/upload?searchkey=' + option.Business.uploadID
            : '',
          passport: option.Business
            ? API_URL + 'monitor/upload?searchkey=' + option.Business.passport
            : '',
          bussinessName: option.Business ? option.Business.bussinessName : '',
          registrationNumber: option.Business
            ? option.Business.registrationNumber
            : '',
          position: option.Business ? option.Business.position : '',
          BusinessType: option.Business ? option.Business.BusinessType : '',
          BusinessAddress: option.Business
            ? option.Business.BusinessAddress
            : '',
          BusinessArea: option.Business ? option.Business.BusinessArea : '',
          YearsInBusiness: option.Business
            ? option.Business.YearsInBusiness
            : '',
          dateIncoporation: option.Business
            ? option.Business.dateIncoporation
            : '',
          Signature: option.Business
            ? API_URL + 'monitor/upload?searchkey=' + option.Business.Signature
            : '',
          witnessFirstName: option.Witness ? option.Witness.first_name : '',
          witnessLastName: option.Witness ? option.Witness.last_name : '',
          witnessOtherName: option.Witness ? option.Witness.other_name : '',
          witnessSignature: option.Witness ? option.Witness.signature : '',
          createdAt: option.createdAt,
          bankingStatus: option.bankingStatus ? option.bankingStatus : '',
          occupation: option.occupation,
          employmentStatus: option.employmentStatus,
          motherMaidenName: option.motherMaidenName,
        }));
        ExportExcel({
          excelDelta: pushData,
          fileName: 'BankingAgent',
        });
      }
    });
  };
  const onChangeAgent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAgentID(e.target.value);

    if (e.target.value.length < 3) {
      return false;
    }
    setConfirmingAgent(true);
    UserService.GetRecord(
      `banking/agent/view-agent-details/${e.target.value}`
    ).then(
      (response) => {
        message.success('Agent found');
        setCustomerBankName(response.data.data.name);
        setConfirmingAgent(false);
        setIsAgentAccountVerified(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        setConfirmingAgent(false);
      }
    );
  };
  const submitAssignSuperAgent = (id: any) => {
    setLoading(true);
    UserService.UpdateRecord(
      { agent_id: agentID },
      `banking/agent/assign-superagent/${id}`
    ).then(
      (response) => {
        message.success('Account assigned to agent successfully');
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        setLoading(false);
      }
    );
  };
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    NameEnquiryByAccountNumber(e.target.value);
    setAccountNumber(e.target.value);
  };
  const NameEnquiryByAccountNumber = async (accountNumber: string) => {
    setIsAccountVerified(false);

    if (accountNumber.length !== 10) {
      return false;
    }
    setLoading(true);
    UserService.GetRecord(
      `utilities/name-enquiry-by-account-number/${accountNumber}`
    ).then(
      (response) => {
        message.success('Account verified successfully');
        setCustomerName(response.data.accountName);
        setLoading(false);
        setIsAccountVerified(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        setLoading(false);
      }
    );
  };
  const submitAddAccount = (id: any) => {
    setLoading(true);
    UserService.UpdateRecord(
      { accountNumber },
      `banking/agent/add-account-number/${id}`
    ).then(
      (response) => {
        message.success('Account added successfully');
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(`${resMessage}`);
        setLoading(false);
      }
    );
  };
  const LoadData = (search?: string | null, status = '') => {
    const searchParams = new URLSearchParams();
    const queryString = searchParams.toString();
    let searchurl = search
      ? `banking/agent?page=${currentPage}&pageSize=${pageSize}&searchstr=${search}`
      : `banking/agent?page=${currentPage}&pageSize=${pageSize}&${queryString}`;
    searchurl = status != '' ? searchurl + `&statusUser=${status}` : searchurl;
    UserService.GetRecord(searchurl).then(
      (result) => {
        setLoadingData(false);
        if (result.data) {
          // console.log('error:',result.data.data[0].SuperAgent.AccountInformations[0].accountNumber)
          setTotalData(result.data.totalUsers);
          setAgentData(
            result.data.data.map((option: any) => ({
              key: option.id,
              firstName: option.first_name,
              lastName: option.last_name,
              otherName: option.other_name,
              phone: option.phone,
              email: option.email,
              status: option.status,
              roleID: option.Role.name,
              createdAt: option.createdAt,
              AgentID: option.AgentID,
              accountNumber: option.accountNumber,
              address: option.address,
              bankID: option.bankID,
              bvnNumber: option.bvnNumber,
              dob: option.dob,
              gender: option.gender,
              maritalStatus: option.maritalStatus,
              ninNumber: option.ninNumber,
              other_name: option.other_name,
              lga: option.LGA,
              superagent:
                (option.SuperAgent != null &&
                option?.data?.data[0]?.SuperAgent?.AccountInformations?.[0]
                  ?.accountNumber !== undefined
                  ? option.SuperAgent.AccountInformations[0].accountNumber
                  : '') +
                ' ' +
                (option.SuperAgent != null &&
                option.SuperAgent.AgentID !== undefined
                  ? option.SuperAgent.AgentID
                  : ''),
              state: option.State,
              bank: option.Bank,
              Business: option.Business,
              Witness: option.Witness,
              bankingStatusID: option.bankingStatusID,
              Status: option.Status,
              AccountInformations: option.AccountInformations,
              bankingStatus: option.Status
                ? option.Status.usertype + ' ' + option.Status.name
                : '',
              occupation: option.occupation,
              employmentStatus: option.employmentStatus,
              dateIncoporation: option.dateIncoporation,
              motherMaidenName: option.motherMaidenName,
              houseNum: option.houseNum,
              city: option.city,
              streetName: option.streetName,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setAgentData([]);
      }
    );
  };
  const handleCancelProgress = () => {
    setIsopenModalProgress(false);
  };
  const handleDelete = (selected: any) => {
    UserService.DeleteRecord('banking/agent/delete/' + selected.key).then(
      (result) => {
        message.success('Deleted Successfully!');
        LoadData();
        handleCancelProgress();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const handleRemoveAccount = (accountDetails: any) => {
    UserService.DeleteRecord('banking/banks/details/' + accountDetails.id).then(
      (result) => {
        message.success('Deleted Successfully!');
        getBankDetails(accountDetails.userID);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const createAccount = (userid: React.Key, withBVN = false) => {
    setIsAddingAccount(true);

    const url = 'fcmb/create-account/' + userid;
    let payload = {};
    if (withBVN) {
      payload = { checkBVN: 'verifyBVN' };
    }
    UserService.PostRecord(payload, url).then(
      (result) => {
        setErrorCreatingAccount(null);
        setIsAddingAccount(false);
        getBankDetails(userid);
      },
      (error) => {
        let resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (typeof error.response.data.data != 'undefined') {
          resMessage =
            resMessage + ' : ' + JSON.stringify(error.response.data.data);
        }
        setErrorCreatingAccount(resMessage);
        message.error(resMessage);
        setIsAddingAccount(false);
      }
    );
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const showDrawer = async (record: any) => {
    setErrorCreatingAccount(null);
    setStringName(record.lga.name);
    setEditUser(record.key);
    setSelectedData(record);
    setOpenDrawer(true);
    const first_name = record.firstName;
    const other_name = record.otherName;
    const last_name = record.lastName;
    const phone = record.phone;
    const email = record.email;
    const houseNum = record.houseNum;
    const streetName = record.streetName;
    const city = record.city;
    const address = record.address;
    const BusinessAddress = record.Business
      ? record.Business.BusinessAddress
      : '';
    const BusinessArea = record.Business ? record.Business.BusinessArea : '';
    const BusinessType = record.Business ? record.Business.BusinessType : '';
    const IDnumber = record.Business ? record.Business.IDnumber : '';
    const Signature = record.Business ? record.Business.Signature : '';
    const YearsInBusiness = record.Business
      ? record.Business.YearsInBusiness
      : '';
    const bussinessName = record.Business ? record.Business.bussinessName : '';
    const dateIncoporation = record.Business
      ? record.Business.dateIncoporation
      : '';
    const modeOfIdentification = record.Business
      ? record.Business.modeOfIdentification
      : '';
    const passport = record.Business ? record.Business.passport : '';
    const position = record.Business ? record.Business.position : '';
    const registrationNumber = record.Business
      ? record.Business.registrationNumber
      : '';
    const uploadID = record.Business ? record.Business.uploadID : '';
    const witnessFirst_name = record.Witness ? record.Witness.first_name : '';
    const witnessLast_name = record.Witness ? record.Witness.last_name : '';
    const witnessOther_name = record.Witness ? record.Witness.other_name : '';
    const witnessSignature = record.Witness ? record.Witness.signature : '';
    const bvnNumber = record.bvnNumber;
    const ninNumber = record.ninNumber;
    const dob = record.dob;
    const gender = record.gender;
    const lgaID = record.lgaID;
    const stateID = record.state.id;
    const maritalStatus = record.maritalStatus;
    const occupation = record.occupation;
    const employmentStatus = record.employmentStatus;
    const motherMaidenName = record.motherMaidenName;
    if (record.Witness) {
      setEditWittnessID(record.Witness.id);
    }
    if (record.Business) {
      setEditBusinessID(record.Business.id);
    }
    getBankDetails(record.key);
    if (stateID) {
      lga(stateID);
    }
    form.setFieldsValue({
      first_name,
      last_name,
      other_name,
      phone,
      email,
      houseNum,
      streetName,
      city,
      address,
      BusinessAddress,
      BusinessArea,
      BusinessType,
      IDnumber,
      YearsInBusiness,
      bussinessName,
      modeOfIdentification,
      dateIncoporation,
      witnessOther_name,
      position,
      registrationNumber,
      witnessFirst_name,
      witnessLast_name,
      bvnNumber,
      ninNumber,
      dob,
      gender,
      lgaID: record.lga.name,
      stateID,
      maritalStatus,
      occupation,
      employmentStatus,
      motherMaidenName,
    });
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append('businessID', EditBusinessID + '');
    formData.append('wittnessID', EditWittnessID + '');
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (typeof values[key] !== 'object' && values[key] !== null) {
          formData.append(key, values[key]);
        }
      }
    }
    if (passportfileList.length > 0) {
      passportfileList.forEach((file: any) => {
        formData.append('passport', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (witnessSignaturefileList.length > 0) {
      witnessSignaturefileList.forEach((file: any) => {
        formData.append('witnessSignature', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (uploadIDfileList.length > 0) {
      uploadIDfileList.forEach((file: any) => {
        formData.append('uploadID', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (signaturefileList.length > 0) {
      signaturefileList.forEach((file: any) => {
        formData.append('Signature', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    UserService.UpdateRecord(
      formData,
      `banking/agent/register/${EditUserID}`
    ).then(
      (result) => {
        setSuccess(result.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
      }
    );
  };

  useEffect(() => {
    if (success != '') {
      message.success(success);
    }
  }, [success]);
  useEffect(() => {
    if (errorMsg != '') {
      message.error(errorMsg);
    }
  }, [errorMsg]);
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const lga = (id: number) => {
    setLoadingLGA(true);
    UserService.GetRecord('banking/lgas/' + id).then((result) => {
      if (result.data) {
        setLoadingLGA(false);
        setNGlga(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  };
  const searchItem: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const search = e.target.value;
    LoadData(search);
  };
  useEffect(() => {
    LoadStatus();
    setPageLoaded(true);
  }, []);
  useEffect(() => {
    LoadData();
  }, [currentPage, pageSize]);

  const onsearchChange = (checkedValues: any) => {
    LoadData(null, checkedValues);
  };
  const onStatusChange = (checkedValues: any, id: any) => {
    UserService.UpdateRecord(
      { status: checkedValues },
      'banking/agent/edit/' + id
    ).then(
      (result) => {
        message.success('User Status Set');
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
    UserService.GetRecord('banking/banks').then((result) => {
      setLoadingBank(false);
      if (result.data) {
        setBank(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
    UserService.GetRecord('banking/states').then((result) => {
      setLoadingState(false);
      if (result.data) {
        setNGstate(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  }, []);
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<agentType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev="icon-rev" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev="icon-rev"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns: ColumnsType<agentType> = [
    {
      title: 'FirstName',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Other Name',
      dataIndex: 'other_name',
      key: 'other_name',
      ...getColumnSearchProps('other_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Agent-ID',
      dataIndex: 'AgentID',
      key: 'AgentID',
      ...getColumnSearchProps('AgentID'),
    },
    {
      title: 'Status',
      dataIndex: 'bankingStatus',
      key: 'bankingStatus',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'BVN Number',
      dataIndex: 'bvnNumber',
      key: 'bvnNumber',

      ...getColumnSearchProps('bvnNumber'),
    },
    {
      title: 'NIN Number',
      dataIndex: 'ninNumber',
      key: 'ninNumber',

      ...getColumnSearchProps('ninNumber'),
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',

      ...getColumnSearchProps('dob'),
    },
    {
      title: 'Super Agent',
      dataIndex: 'superagent',
      key: 'superagent',

      ...getColumnSearchProps('superagent'),
    },
    //
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',

      ...getColumnSearchProps('gender'),
    },
    {
      title: 'Marital Status',
      dataIndex: 'maritalStatus',
      key: 'maritalStatus',

      ...getColumnSearchProps('maritalStatus'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '10%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',

      render: (_: any, record: agentType) => (
        <Space>
          <Button
            onClick={(e) => {
              LoadDetails(record);
            }}
          >
            <EyeFilled rev="icon-rev" />
          </Button>
          {permissionsData.includes('update-agent-details') && (
            <Button
              onClick={(e) => {
                showDrawer(record);
              }}
            >
              <EditFilled rev="icon-rev" />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={option.firstName + ' ' + option.lastName}
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <Button
                      onClick={() => {
                        LoadDetails(option);
                      }}
                    >
                      View Details
                    </Button>
                    {permissionsData.includes('update-agent-details') && (
                      <Button
                        onClick={(e) => {
                          showDrawer(option);
                        }}
                      >
                        <EditFilled rev="icon-rev" />
                      </Button>
                    )}
                  </Space>
                </>
              }
              dataSource={[
                'First Name: ' + option.firstName,
                'Last Name: ' + option.lastName,
                'Super Agent: ' + option.superagent,
                'Email: ' + option.email,
                'Phone: ' + option.phone,
                'RoleID: ' + option.roleID,
                'AgentID: ' + option.AgentID,
                'CreatedAt: ' + option.createdAt,
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  if(showPDF) return <BasicPDFDocument
  selectedData={selectedData}
  loadingBankDetails={loadingBankDetails}
  agentBankDetals={agentBankDetals}
  API_URL={API_URL} />



  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <div className="centered-container">
                  <Form.Item
                    label="Status"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 10 }}
                    style={{ margin: '5px' }}
                  >
                    <Select
                      style={{ width: 220 }}
                      onChange={onsearchChange}
                      options={viewableStatusData}
                    />
                  </Form.Item>
                </div>

                <div style={{ overflowX: 'auto' }}></div>
                <Divider />
                {isMobile && (
                  <>
                    <div />
                    <Input
                      style={{ margin: '5px' }}
                      placeholder="Search Order"
                      onChange={searchItem}
                    />
                  </>
                )}

                <Tooltip style={{ margin: '5px' }} title="Export Excel">
                  <Button
                    onClick={() => {
                      exportExcel();
                    }}
                  >
                    Export Excel
                    <FileExcelOutlined rev="icon-rev" />
                  </Button>
                </Tooltip>
                <Tooltip style={{ margin: '5px' }} title="Export Excel">
                  <Button
                    onClick={() => {
                      generatePDF();
                    }}
                  >
                    Export PDF
                    <FilePdfOutlined rev="icon-rev" />
                  </Button>
                </Tooltip>
                <Divider />

                <div id="report">
                  {!isMobile ? (
                    <Table
                      loading={loadingData}
                      columns={columns}
                      dataSource={agentData}
                      scroll={{ x: 'max-content', y: 400 }}
                      pagination={false}
                    />
                  ) : (
                    <>{renderPage(agentData, 'order')}</>
                  )}
                </div>
                <Pagination
                  total={totalData}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handlePaginationChange}
                />
                <Modal
                  title="Update Agent Record"
                  width={720}
                  onCancel={onClose}
                  open={opendrawer}
                  bodyStyle={{ paddingBottom: 80 }}
                  footer={
                    <Space>
                      <Button onClick={onClose}>Cancel</Button>
                      <Button
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              onFinish(values);
                            })
                            .catch((info) => {});
                        }}
                        htmlType="submit"
                        type="primary"
                      >
                        Update
                      </Button>
                    </Space>
                  }
                >
                  <Form
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                    layout="vertical"
                  >
                    <h3>Personal Information</h3>

                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="first_name"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter first name',
                            },
                          ]}
                        >
                          <Input placeholder="Please enter first name" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="last_name"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter last name',
                            },
                          ]}
                        >
                          <Input placeholder="Please enter last name" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="other_name"
                          label="Other Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter other name',
                            },
                          ]}
                        >
                          <Input placeholder="Please enter other name" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="phone"
                          label="Phone"
                          rules={[
                            { required: true, message: 'Please enter phone' },
                          ]}
                        >
                          <Input placeholder="Please enter phone" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            { required: true, message: 'Please enter email' },
                          ]}
                        >
                          <Input placeholder="Please enter email" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="motherMaidenName"
                          label="Mother's Maiden Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your mother maiden name!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="dob"
                          label="Date of Birth"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your date of birth!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="maritalStatus"
                          label="Marital Status"
                          rules={[
                            {
                              required: true,
                              message: 'Please select marital status!',
                            },
                          ]}
                        >
                          <Select placeholder="select tell us your marital status">
                            <Option value="Single">Single</Option>
                            <Option value="Married">Married</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="gender"
                          label="Gender"
                          rules={[
                            {
                              required: true,
                              message: 'Please select gender!',
                            },
                          ]}
                        >
                          <Select placeholder="select your gender">
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="occupation"
                          label="Occupation "
                          rules={[
                            {
                              required: false,
                              message: 'Please input your occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="employmentStatus"
                          label="Employment Status"
                          rules={[
                            {
                              required: true,
                              message: 'Please input employment status!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select placeholder="Select Employment Status">
                            <Option value="employed">Employed</Option>
                            <Option value="unemployed">Unemployed</Option>
                            <Option value="others">Others</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="houseNum"
                          label="House Number"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your house number!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="streetName"
                          label="Street Name"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your street name!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="city"
                          label="City Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your city name!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="address"
                          label="Address"
                          rules={[
                            { required: true, message: 'Please enter address' },
                          ]}
                        >
                          <Input placeholder="Please enter address" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <h3>Account Information</h3>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="bvnNumber"
                          label="BVN"
                          rules={[
                            {
                              max: 11,
                              min: 11,
                              required: true,
                              message: 'Please input your BVN number!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input maxLength={11} type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="ninNumber"
                          label="NIN"
                          rules={[
                            {
                              max: 11,
                              min: 11,
                              required: true,
                              message: 'Please input your NIN number!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </Row>

                    {agentBankDetals && (
                      <>
                        {agentBankDetals.map((bankDetails: any) => (
                          <React.Fragment key={bankDetails.id}>
                            <p>
                              <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <b>Bank</b> : {bankDetails.bank}
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <b>Account Number</b> : {bankDetails.account}
                                  <MinusCircleOutlined
                                    rev="true"
                                    onClick={() =>
                                      handleRemoveAccount(bankDetails)
                                    }
                                  />
                                </Col>
                              </Row>
                            </p>
                          </React.Fragment>
                        ))}
                      </>
                    )}

                    {permissionsData.includes('add-agent-account-number') && (
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 20 }}
                            label="FCMB Account Number"
                            rules={[
                              {
                                message:
                                  'Please input your FCMB Account Number!',
                                whitespace: false,
                              },
                            ]}
                          >
                            <Input onChange={(val: any) => onChange(val)} />
                            <div style={{ margin: '10px' }}></div>
                            <Row gutter={16}>
                              <Col
                                className="gutter-row"
                                xs={24}
                                sm={24}
                                md={6}
                              ></Col>
                              <Col
                                className="gutter-row"
                                xs={24}
                                sm={24}
                                md={12}
                              >
                                <p>
                                  {loading ? (
                                    <>Comfirming Account...</>
                                  ) : (
                                    <b>{customerName}</b>
                                  )}{' '}
                                </p>
                              </Col>
                            </Row>
                            <Button
                              type="primary"
                              loading={loading}
                              disabled={!isAccountVerified}
                              onClick={() => selectedData
                                  ? submitAddAccount(selectedData.key)
                                  : null
                              }
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}

                    {permissionsData.includes('assign-super-agent') && (
                      <>
                        <h3>Super Agent</h3>

                        <Row gutter={16}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 20 }}
                              label="Agent ID"
                              rules={[
                                {
                                  message: 'Please input your Agent ID!',
                                  whitespace: false,
                                },
                              ]}
                            >
                              <Input
                                onChange={(val: any) => onChangeAgent(val)}
                              />
                              <div style={{ margin: '10px' }}></div>
                              <Row gutter={16}>
                                <Col
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={6}
                                ></Col>
                                <Col
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={12}
                                >
                                  <p>
                                    {confirmingAgent ? (
                                      <>Comfirming Agent...</>
                                    ) : (
                                      <b>{customerBankName}</b>
                                    )}{' '}
                                  </p>
                                </Col>
                              </Row>
                              <Button
                                type="primary"
                                loading={confirmingAgent}
                                disabled={!isAgentAccountVerified}
                                onClick={() =>
                                  selectedData
                                    ? submitAssignSuperAgent(selectedData.key)
                                    : null
                                }
                              >
                                Assign Agent
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    <h3>Business Information</h3>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="stateID" label="State">
                          <Select
                            placeholder="Select State"
                            loading={loadingState}
                            onChange={lga}
                          >
                            {NGstate.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="lgaID"
                          label={'LGA (' + stringName + ')'}
                        >
                          <Select
                            defaultValue={stringName}
                            placeholder="Select LGA"
                            loading={loadingLGA}
                            disabled={loadingLGA}
                          >
                            {NGlga.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="modeOfIdentification"
                          label="Mode Of Identification"
                        >
                          <Select placeholder="Select Mode Of Identification">
                            <Option key="Passport" value="Passport">
                              Passport
                            </Option>
                            <Option
                              key="NationalI_D_Card"
                              value="National ID Card"
                            >
                              National ID Card
                            </Option>
                            <Option
                              key="Driver_License"
                              value="Driver's License"
                            >
                              Driver's License
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="IDnumber" label="ID Number">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="uploadID" label="Upload ID">
                          <Upload
                            beforeUpload={() => {
                              return false;
                            }}
                            onChange={(info: any) =>
                              setUuploadIDFileList(info.fileList)
                            }
                          >
                            <Button icon={<UploadOutlined rev="icon-rev" />}>
                              Select File
                            </Button>
                          </Upload>

                          {selectedData && selectedData.Business && (
                            <a
                              href={`${API_URL}monitor/upload?searchkey=${selectedData.Business.uploadID}`}
                              target="_blank"
                            >
                              View
                            </a>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="passport" label="Passport Photograph">
                          <Upload
                            beforeUpload={() => {
                              return false;
                            }}
                            onChange={(info: any) =>
                              setPassportFileList(info.fileList)
                            }
                          >
                            <Button icon={<UploadOutlined rev="icon-rev" />}>
                              Select File
                            </Button>
                          </Upload>
                          {selectedData && selectedData.Business && (
                            <a
                              href={`${API_URL}monitor/upload?searchkey=${selectedData.Business.passport}`}
                              target="_blank"
                            >
                              View
                            </a>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="bussinessName" label="Bussiness Name">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="registrationNumber"
                          label="Registration Number"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="position" label="Position">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="BusinessType" label="Business Type">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="BusinessAddress"
                          label="Business Address"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="BusinessArea" label="Business Area">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="YearsInBusiness"
                          label="Years In Business"
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="dateIncoporation"
                          label="Date Incorporation"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 20 }}
                          name="Signature"
                          label="Upload Signature"
                        >
                          <Upload
                            beforeUpload={() => {
                              return false;
                            }}
                            onChange={(info: any) =>
                              setSignatureFileList(info.fileList)
                            }
                          >
                            <Button icon={<UploadOutlined rev="icon-rev" />}>
                              Select File
                            </Button>
                          </Upload>
                          {selectedData && selectedData.Business && (
                            <a
                              href={`${API_URL}monitor/upload?searchkey=${selectedData.Business.Signature}`}
                              target="_blank"
                            >
                              View
                            </a>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <h3>Wittness Information</h3>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 20 }}
                          name="witnessFirst_name"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Firstname!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 20 }}
                          name="witnessLast_name"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Lastname!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 20 }}
                          name="witnessOther_name"
                          label="Other Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Othername!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 20 }}
                          name="witnessSignature"
                          label="Witness Signature"
                        >
                          <Upload
                            name="files"
                            beforeUpload={() => {
                              return false;
                            }}
                            onChange={(info: any) =>
                              setWitnessSignatureUuploadIDFileList(
                                info.fileList
                              )
                            }
                          >
                            <Button>Click to Upload</Button>
                          </Upload>
                          {selectedData && selectedData.Witness && (
                            <a
                              href={`${API_URL}monitor/upload?searchkey=${selectedData.Witness.Signature}`}
                              target="_blank"
                            >
                              View
                            </a>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
                <Modal
                  title="View Details"
                  open={isopenModalProgress}
                  onCancel={handleCancelProgress}
                  footer={[
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => handleDelete(selectedData)}
                    >
                      <Button key="delete">
                        Delete <DeleteFilled rev="icon-rev" />
                      </Button>
                    </Popconfirm>,

                    <Button
                      key="pdf"
                      onClick={() => {
                        // ReactPDF.render(<BasicPDFDocument />, `/banking.pdf`);
                        // generatePDF('BankingAgent');
                        setshowPDF(1)
                      }}
                    >
                      Export PDF
                      <FilePdfOutlined rev="icon-rev" />
                    </Button>,
                    <Button key="back" onClick={handleCancelProgress}>
                      Return
                    </Button>,
                  ]}
                >
                  <Form id="BankingAgent">
                    <h2>Details Information</h2>

                    {selectedData &&
                      selectedData.Business &&
                      selectedData.Business.passport != null && (
                        <Image
                          width={200}
                          src={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Business.passport
                          }
                        />
                      )}

                    <p>
                      <b>Name</b> : {selectedData && selectedData.firstName}
                    </p>
                    <p>
                      <b>OtherName</b> :{' '}
                      {selectedData && selectedData.other_name}
                    </p>
                    <p>
                      <b>LastName</b> : {selectedData && selectedData.lastName}
                    </p>
                    <p>
                      <b>Agent ID</b> : {selectedData && selectedData.AgentID}
                    </p>

                    <p>
                      <b>Email</b> : {selectedData && selectedData.email}
                    </p>
                    <p>
                      <b>DOB</b> : {selectedData && selectedData.dob}
                    </p>
                    <p>
                      <b>Phone</b> : {selectedData && selectedData.phone}
                    </p>

                    <p>
                      <b>Gender</b> : {selectedData && selectedData.gender}
                    </p>
                    <p>
                      <b>Marital Status</b> :{' '}
                      {selectedData && selectedData.maritalStatus}
                    </p>
                    <p>
                      <b>State</b> :{' '}
                      {selectedData &&
                        selectedData.state &&
                        selectedData.state.name}
                    </p>
                    <p>
                      <b>LGA</b> :{' '}
                      {selectedData &&
                        selectedData.lga &&
                        selectedData.lga.name}
                    </p>
                    <p>
                      <b>Address</b> : {selectedData && selectedData.address}
                    </p>
                    <h3>Business Information</h3>

                    <p>
                      <b>Identification Number</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.IDnumber}
                    </p>
                    <p>
                      <b>Business Reg. Number</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.registrationNumber}
                    </p>
                    <p>
                      <b>Position</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.position}
                    </p>
                    <p>
                      <b>Business Type</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.BusinessType}
                    </p>
                    <p>
                      <b>Business Address</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.BusinessAddress}
                    </p>
                    <p>
                      <b>Business Area</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.BusinessArea}
                    </p>
                    <p>
                      <b>Years in Business</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.YearsInBusiness}
                    </p>
                    <p>
                      <b>Date Incoporation</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.dateIncoporation}
                    </p>
                    <p>
                      <b>Mode Identification</b> :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.modeOfIdentification}
                    </p>
                    <p>
                      <b>UploadID</b> :{' '}
                      {selectedData && selectedData.Business && (
                        <a
                          target="_blank"
                          href={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Business.uploadID
                          }
                        >
                          {' '}
                          View Upload ID
                        </a>
                      )}
                    </p>
                    <p>
                      <b>Signature</b> :{' '}
                      {selectedData && selectedData.Business && (
                        <a
                          target="_blank"
                          href={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Business.Signature
                          }
                        >
                          {' '}
                          View Signature
                        </a>
                      )}
                    </p>

                    <h3>Account Information</h3>
                    {loadingBankDetails && (
                      <Button
                        type="primary"
                        loading={loadingBankDetails}
                        disabled={true}
                      >
                        Loading bank details
                      </Button>
                    )}
                    <span>
                      {errorCreatingAccount && (
                        <Alert message={errorCreatingAccount} type="error" />
                      )}
                    </span>
                    {agentBankDetals && (
                      <>
                        {agentBankDetals.map((bankDetails: any) => (
                          <React.Fragment key={bankDetails.id}>
                            <p>
                              <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <b>Bank</b> : {bankDetails.bank}
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <b>Account Number</b> : {bankDetails.account}
                                </Col>
                              </Row>
                            </p>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                    {!hidCreateAccountBTN &&
                      permissionsData.includes('create-bank-account') && (
                        <>
                          <Button
                            style={{ margin: '5px' }}
                            key="fcmbaccount"
                            type="primary"
                            onClick={() =>
                              selectedData && createAccount(selectedData.key)
                            }
                            loading={isAddingAccount}
                            disabled={isAddingAccount}
                          >
                            Create FCMB Account Only
                          </Button>
                          <Button
                            style={{ margin: '5px' }}
                            key="fcmbaccount"
                            type="primary"
                            onClick={() =>
                              selectedData &&
                              createAccount(selectedData.key, true)
                            }
                            loading={isAddingAccount}
                            disabled={isAddingAccount}
                          >
                            Verify BVN and Create FCMB Account
                          </Button>
                        </>
                      )}
 <Button
                            style={{ margin: '5px' }}
                            key="fcmbaccount"
                            type="primary"
                            onClick={() =>
                              selectedData &&
                              createAccount(selectedData.key, true)
                            }
                            loading={isAddingAccount}
                            disabled={isAddingAccount}
                          >
                            Verify BVN information
                          </Button>
                    <p>
                      <b>BVN</b> : {selectedData && selectedData.bvnNumber}
                    </p>
                    <p>
                      <b>NIN</b> : {selectedData && selectedData.ninNumber}
                    </p>
                    <h3>Withness</h3>
                    <p>
                      <b>FirstName</b> :{' '}
                      {selectedData &&
                        selectedData.Witness &&
                        selectedData.Witness.first_name}
                    </p>
                    <p>
                      <b>LastName</b> :{' '}
                      {selectedData &&
                        selectedData.Witness &&
                        selectedData.Witness.last_name}
                    </p>
                    <p>
                      <b>OtherName</b> :{' '}
                      {selectedData &&
                        selectedData.Witness &&
                        selectedData.Witness.other_name}
                    </p>
                    <p>
                      <b>Signature</b> :{' '}
                      {selectedData && selectedData.Witness && (
                        <a
                          target="_blank"
                          href={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Witness.signature
                          }
                        >
                          {' '}
                          View Signature
                        </a>
                      )}
                    </p>
                    <p>
                      <b>Status</b> :{' '}
                      {selectedData &&
                        selectedData.Status &&
                        selectedData.Status.usertype +
                          ' ' +
                          selectedData.Status.name}
                    </p>
                    <p>
                      Status:{' '}
                      <Select
                        defaultValue={
                          selectedData &&
                          selectedData.Status &&
                          selectedData.Status.name
                        }
                        style={{ width: 220 }}
                        onChange={(val) => {
                          // Handle the change event here
                          const selectid = selectedData ? selectedData.key : 0;
                          onStatusChange(val, selectid);
                        }}
                        options={statusData}
                      />
                    </p>
                  </Form>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default Agents;
