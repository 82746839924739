import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import { Layout, Form, Input, Alert, Space, Button, Card } from 'antd';
import RoleHooks from '../../../hooks/verifyRole';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import ScaleLoader from 'react-spinners/ScaleLoader';
const { Content } = Layout;
const { uid } = RoleHooks();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Pin = () => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [PageLoaded, setPageLoaded] = useState(true);

  const handleSubmit = (values: any) => {
    // handle form submission
    setLoading(true);
    UserService.UpdateRecord(values, 'users/security/pin').then(
      (response) => {
        setLoading(false);
        setSubmitBadge('success');
        setSubmitMessage('Pin Updated!');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSubmitBadge('error');
        setSubmitMessage('Profile Update Failed!');
      }
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Card
                  title="Change Pin"
                  className="adjust-responsive"
                  style={{ margin: '0 auto' }}
                  id="Profile"
                >
                  <Space>
                    {submitMessage && submitBadge === 'success' && (
                      <Alert
                        message="Change Pin"
                        description={submitMessage}
                        type="success"
                        closable
                      />
                    )}
                    {submitMessage && submitBadge === 'error' && (
                      <Alert
                        message="Change Pin"
                        description={submitMessage}
                        type="error"
                        closable
                      />
                    )}
                  </Space>
                  <Form {...formItemLayout} form={form} scrollToFirstError>
                    <Form.Item
                      label="Old Pin"
                      name="old_pin"
                      rules={[
                        {
                          max: 4,
                          required: true,
                          message: 'Please input your Old Pin!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                    <Form.Item
                      label="New Pin"
                      name="new_pin"
                      rules={[
                        {
                          max: 4,
                          required: true,
                          message: 'Please input your New Pin!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                    <Form.Item
                      label="confirm_pin"
                      name="confirm_pin"
                      rules={[
                        {
                          max: 4,
                          required: true,
                          message: 'Please confirm Pin!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        loading={loading}
                        key="submit"
                        type="primary"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              handleSubmit(values);
                            })
                            .catch((info) => {});
                        }}
                      >
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Pin;
