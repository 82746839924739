import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Layout,
  Card,
  Skeleton,
  Alert,
  message,
  Col,
  Row,
  Modal,
  Popconfirm,
  Space,
  Select,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
const { Meta } = Card;
const { Content } = Layout;
const { Search } = Input;
interface AssingModel {
  id: number;
  name: string;
  description?: string;
  User: any;
  FarmCalendarLocation: any;
}
interface DataType {
  value: number;
  label: string;
}
const AssignCalendarLocationComponent = () => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignUser, setAssingModels] = useState<AssingModel[]>([]);
  const [assignUserData, setAssingModelsData] = useState<AssingModel[]>([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [editAssingModelId, setEditAssingModelId] = useState<number | null>(
    null
  );
  const [user, setUser] = useState<DataType[]>([]);
  const [selectedUser, setSelectedUser] = useState<DataType[]>([]);
  const [locationId, setLocationId] = useState(0);
  const [location, setLocation] = useState<DataType[]>([]);

  useEffect(() => {
    fetchAssingModels();
    getUsersList();
    fetchLocations();
  }, []);
  const fetchAssingModels = () => {
    setLoading(true);
    UserService.GetRecord('calender/location/assign/user').then(
      (response: any) => {
        setAssingModels(response.data.data);
        setAssingModelsData(response.data.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };
  const fetchLocations = () => {
    UserService.GetRecord('calender/location').then(
      (response: any) => {
        setLocation(
          response.data.data.map((locations: any) => ({
            value: locations.id,
            label: locations.name,
          }))
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const getUsersList = () => {
    UserService.GetRecord('users/userby-permission/calendar-farm-manager').then(
      (response: any) => {
        setUser(
          response.data.data.map((option: any) => ({
            value: option.id,
            label: option.first_name + ' ' + option.last_name,
          }))
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const handleSaveAssingModel = () => {
    setIsSubmitting(true);
    setModal2Open(false);
    UserService.PostRecord(
      { farmCalendarLocationID: locationId, userId: selectedUser },
      'calender/location/assign/user'
    ).then(
      (result) => {
        setIsSubmitting(false);
        message.success(result.data.message);
        fetchAssingModels();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsSubmitting(false);
        message.error(resMessage);
      }
    );
  };
  const handleEditAssingModel = (dataSource: AssingModel) => {
    setEditAssingModelId(dataSource.id);
    setModal2Open(true);
  };

  const handleDeleteAssingModel = (id: number) => {
    UserService.DeleteRecord('calender/location/assign/user/' + id).then(
      (response) => {
        const updatedAssingModels = assignUser.filter(
          (assign) => assign.id !== id
        );
        setAssingModels(updatedAssingModels);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };

  const onSearch = (value: string) => {
    // Method 2: Using for...of loop
    const filteredItems = assignUserData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setAssingModels(filteredItems);
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Assign Agent Calendar Location</h1>

            <div className="m-5">
              <Button
                type="primary"
                onClick={() => {
                  setModal2Open(true);
                  setEditAssingModelId(null);
                }}
              >
                Assign
              </Button>
            </div>
            <div className="m-5">
              <Space direction="vertical">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Space>
            </div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body">
              <Row gutter={[16, 16]}>
                {assignUser.length > 0 ? (
                  assignUser.map((AssignData) => (
                    <Col
                      key={AssignData.id}
                      span={8}
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      <div className="card">
                        <div className="card-content">
                          <h2 className="card-title">
                            {AssignData.User.first_name +
                              ' ' +
                              AssignData.User.last_name}
                          </h2>
                          <p className="description">
                            {AssignData.FarmCalendarLocation.name}
                          </p>
                        </div>
                        <div className="card-buttons">
                          <Button
                            type="text"
                            className="edit-button"
                            onClick={() => {
                              handleEditAssingModel(AssignData);
                            }}
                          >
                            <EditOutlined rev="rev-icon" />
                          </Button>
                          <Popconfirm
                            title="Delete the Assigned Data"
                            description="Are you sure to delete this Assigned Data?"
                            icon={
                              <QuestionCircleOutlined
                                rev="rev-icon"
                                style={{ color: 'red' }}
                              />
                            }
                            onConfirm={() => {
                              handleDeleteAssingModel(AssignData.id);
                            }}
                          >
                            <Button type="text" className="delete-button">
                              <DeleteOutlined rev="rev-icon" />
                            </Button>
                          </Popconfirm>
                        </div>
                        <Skeleton loading={loading} avatar active></Skeleton>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div className="m-5">
                    <Alert message="Data not found!" showIcon type="error" />
                  </div>
                )}
              </Row>
            </div>
            <Modal
              title="Add AssingModel"
              centered
              open={modal2Open}
              footer={[
                <Button
                  loading={isSubmitting}
                  key="save"
                  onClick={handleSaveAssingModel}
                >
                  {editAssingModelId ? 'Update' : 'Save'}
                </Button>,
                <Button key="cancel" onClick={() => setModal2Open(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => setModal2Open(false)}
            >
              <Select
                defaultValue={selectedUser}
                showSearch
                placeholder="Select User"
                optionFilterProp="children"
                options={user}
                style={{ width: '100%' }}
                onChange={(value) => setSelectedUser(value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <Select
                defaultValue={locationId}
                showSearch
                placeholder="Select Location"
                optionFilterProp="children"
                options={location}
                style={{ width: '100%' }}
                onChange={(value) => setLocationId(value)}
              />
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AssignCalendarLocationComponent;
