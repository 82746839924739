import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";

  // Create styles
  const styles = StyleSheet.create({
    page: {
      color: "black",
    },
    section: {
      margin: 5,
      padding: 5,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });
//()
  // Create Document Component
  interface ChildProps {
    selectedData:any;
    loadingBankDetails:any;
    agentBankDetals:any;
    API_URL:any;
  }
  const BasicPDFDocument: React.FC<ChildProps> = (props: any) => {
    const selectedData = props.selectedData;
    const loadingBankDetails = props.loadingBankDetails;
    const agentBankDetals = props.agentBankDetals;
    const API_URL = props.API_URL;
    return (
      <PDFViewer style={styles.viewer}>
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
          <h2>Details Information</h2>

{/* {selectedData &&
                      selectedData.Business &&
                      selectedData.Business.passport != null && (
                        <Image
                          width={200}
                          src={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Business.passport
                          }
                        />
                      )} */}

                    <View style={styles.section}><Text>
                      First Name : {selectedData && selectedData.firstName}
                    </Text></View>
                    <View style={styles.section}><Text>
                      OtherName :{' '}
                      {selectedData && selectedData.other_name}
                    </Text></View>
                    <View style={styles.section}><Text>
                      LastName : {selectedData && selectedData.lastName}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Agent ID : {selectedData && selectedData.AgentID}
                    </Text></View>

                    <View style={styles.section}><Text>
                      Email : {selectedData && selectedData.email}
                    </Text></View>
                    <View style={styles.section}><Text>
                      DOB : {selectedData && selectedData.dob}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Phone : {selectedData && selectedData.phone}
                    </Text></View>

                    <View style={styles.section}><Text>
                      Gender : {selectedData && selectedData.gender}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Marital Status :{' '}
                      {selectedData && selectedData.maritalStatus}
                    </Text></View>
                    <View style={styles.section}><Text>
                      State :{' '}
                      {selectedData &&
                        selectedData.state &&
                        selectedData.state.name}
                    </Text></View>
                    <View style={styles.section}><Text>
                      LGA :{' '}
                      {selectedData &&
                        selectedData.lga &&
                        selectedData.lga.name}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Address : {selectedData && selectedData.address}
                    </Text></View>
                    <h3>Business Information</h3>

                    <View style={styles.section}><Text>
                      Identification Number :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.IDnumber}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Business Reg. Number :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.registrationNumber}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Position :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.position}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Business Type :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.BusinessType}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Business Address :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.BusinessAddress}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Business Area :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.BusinessArea}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Years in Business :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.YearsInBusiness}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Date Incoporation :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.dateIncoporation}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Mode Identification :{' '}
                      {selectedData &&
                        selectedData.Business &&
                        selectedData.Business.modeOfIdentification}
                    </Text></View>
                    <View style={styles.section}><Text>
                      UploadID :{' '}
                      {selectedData && selectedData.Business && (
                        <a
                          target="_blank"
                          href={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Business.uploadID
                          }
                        >
                          {' '}
                          View Upload ID
                        </a>
                      )}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Signature :{' '}
                      {selectedData && selectedData.Business && (
                        <a
                          target="_blank"
                          href={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Business.Signature
                          }
                        >
                          {' '}
                          View Signature
                        </a>
                      )}
                    </Text></View>

                    <h3>Account Information</h3>


                    {agentBankDetals && (
                      <>
                        {agentBankDetals.map((bankDetails: any) => (
                            <View style={styles.section}><Text>

                                  Bank : {bankDetails.bank}

                                  Account Number : {bankDetails.account}

                            </Text></View>
                        ))}
                      </>
                        )}


                    <View style={styles.section}><Text>
                      BVN : {selectedData && selectedData.bvnNumber}
                    </Text></View>
                    <View style={styles.section}><Text>
                      NIN : {selectedData && selectedData.ninNumber}
                    </Text></View>
                    <h3>Withness</h3>
                    <View style={styles.section}><Text>
                      FirstName :{' '}
                      {selectedData &&
                        selectedData.Witness &&
                        selectedData.Witness.first_name}
                    </Text></View>
                    <View style={styles.section}><Text>
                      LastName :{' '}
                      {selectedData &&
                        selectedData.Witness &&
                        selectedData.Witness.last_name}
                    </Text></View>
                    <View style={styles.section}><Text>
                      OtherName :{' '}
                      {selectedData &&
                        selectedData.Witness &&
                        selectedData.Witness.other_name}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Signature :{' '}
                      {selectedData && selectedData.Witness && (
                        <a
                          target="_blank"
                          href={
                            API_URL +
                            'monitor/upload?searchkey=' +
                            selectedData.Witness.signature
                          }
                        >
                          {' '}
                          View Signature
                        </a>
                      )}
                    </Text></View>
                    <View style={styles.section}><Text>
                      Status :{' '}
                      {selectedData &&
                        selectedData.Status &&
                        selectedData.Status.usertype +
                          ' ' +
                          selectedData.Status.name}
                    </Text></View>
            {/* <Form id="BankingAgent">



                  </Form> */}
          </Page>
        </Document>
      </PDFViewer>
    );
  }
  export default BasicPDFDocument;
