import UserService from '../../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import React, { useState, useEffect } from 'react';
import {  Layout, message,Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
const { Content } = Layout;

const toolbarOptions = {
  toolbar: [[]],
};
const TrainingComponent = () => {
  const [loading, setLoading] = useState(true);
  const [guide, setGuides] = useState<any>();
  const { title } = useParams();

  useEffect(() => {
    fetchGuides();
  }, []);
  const fetchGuides = () => {
    setLoading(true);
    UserService.GetRecord(`guide/training/${title}`).then(
      (response: any) => {
        setGuides(response.data.data)
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };

  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        {/* <CustomSideBar /> */}
        <Layout className="site-layout">
          {/* <TopBar /> */}
          <Content style={{ margin: '20px' }}>
            <h1>System Training</h1>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body" style={{ overflow:'scroll' }}>

                {guide  && <ReactQuill value={guide.content} modules={toolbarOptions} readOnly />}
            </div>
            {guide && <Button type='primary' style={{margin:'5px'}}><Link to={`/quize/${guide.QuizeTopic.name.replace(/ /g, '-')}`}>
            Take Quize</Link> </Button>}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default TrainingComponent;
