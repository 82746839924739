import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import RoleHooks from '../../../hooks/verifyRole';
import {
  PieChartOutlined,
  SecurityScanFilled,
  ProfileFilled,
  UserOutlined,
  PoweroffOutlined,
  LockOutlined,
  PushpinFilled,
  CalendarOutlined,
  BookOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import AuthService from '../../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const { Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
const { permissionsData } = RoleHooks();
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items: MenuItem[] = [];
items.push(getItem('Dashboard', '/', <UserOutlined rev="icon-rev" />));
if (permissionsData.includes('view-user')) {
  items.push(
    getItem(
      'User Management',
      '/account/users',
      <UserOutlined rev="icon-rev" />
    )
  );
}
if (permissionsData.includes('view-farmers')) {
  items.push(
    getItem('Farmer Management', '/farmers', <UserOutlined rev="icon-rev" />)
  );
}
if (permissionsData.includes('create-monitor-records')) {
  items.push(
    getItem(
      'Farm Monitor',
      '/farmers/activity',
      <PieChartOutlined rev="icon-rev" />
    )
  );
}
if (permissionsData.includes('view-monitor-records')) {
  items.push(
    getItem(
      'Farm Record',
      '/activity/charts',
      <PieChartOutlined rev="icon-rev" />
    )
  );
}
if (permissionsData.includes('system-variable')) {
  items.push(
    getItem('Settings', '/settings', <PieChartOutlined rev="icon-rev" />)
  );
  items.push(
    getItem('Set State', '/state-variable', <PushpinFilled rev="icon-rev" />)
  );
}
if (permissionsData.includes('view-roles')) {
  items.push(getItem('Permissions', '/roles', <LockOutlined rev="icon-rev" />));
}
items.push(
  getItem('Profile', '/user/profile', <ProfileFilled rev="icon-rev" />)
);


items.push(
  getItem(
    'Change Password',
    '/user/change-password',
    <SecurityScanFilled rev="icon-rev" />
  )
);
if (permissionsData.includes('create-order') || permissionsData.includes('auditor') || permissionsData.includes('root')) {
  items.push(
    getItem('Update Commodity', '/order/items-list', <PushpinFilled rev="icon-rev" />)
  );
}
if (permissionsData.includes('supplier')) {
  items.push(
    getItem(
      'Set Items',
      '/supplier/items-list',
      <PushpinFilled rev="icon-rev" />
    )
  );
}
if (permissionsData.includes('create-order')) {
  items.push(
    getItem('Manage Orders', '/order/item', <PushpinFilled rev="icon-rev" />)
  );
}
if (permissionsData.includes('supplier')) {
  items.push(
    getItem('Orders', '/supplier/order', <PushpinFilled rev="icon-rev" />)
  ); // temp c
}
if (permissionsData.includes('dispatch-rider')) {
  items.push(
    getItem(
      'Dispatch Rider',
      '/dispatch-rider',
      <PushpinFilled rev="icon-rev" />
    )
  );
}
if (permissionsData.includes('view-banking-agent')) {
  items.push(
    getItem(
      'Banking Agents',
      '/banking-Agents',
      <PushpinFilled rev="icon-rev" />
    )
  );
}
if (
  permissionsData.includes('card-agent') ||
  permissionsData.includes('root')
) {
  items.push(
    getItem(
      'Farmers Bank Account',
      '/farmers-bank-account',
      <PushpinFilled rev="icon-rev" />
    )
  );
}
if (
  permissionsData.includes('auditor') ||
  permissionsData.includes('pos-agent')
) {
  items.push(getItem('Manage Pin', '/user/pin', <ProfileFilled rev="icon-rev" />))
}
if (permissionsData.includes('view-banking-agent')) {
  items.push(
    getItem('Manage Doc', '/admin/view-guide', <BookOutlined rev="icon-rev" />)
  );
}
if (permissionsData.includes('admin-farm-manager')) {
  items.push(
    getItem('Calendar', 'calendar', <CalendarOutlined rev="icon-rev" />, [
      getItem('1. Calendar variable', '/admin/calendar/activity'),
      getItem('2. Manage Calendar', '/admin/calendar/create'),
      getItem('3. Farm Location', '/admin/calendar/location'),
      getItem('4. Assign Location', '/admin/calendar/assign-location'),
      getItem('5. View Calendar', '/admin/calendar/admin-view-calendar'),
    ])
  );
}
if (permissionsData.includes('documentation-editor')) {
  items.push(
    getItem('Quize', 'quize', <CalendarOutlined rev="icon-rev" />, [
      getItem('Manage Topic', '/quize-topic'),
      getItem('View Quize', '/quize'),
      getItem('Perticipants', '/quize-perticipant'),

      getItem('Documentation', '/documentation', <BookOutlined rev="icon-rev" />)

    ])
  );
}

if (
  permissionsData.includes('root') ||
  permissionsData.includes('pos-agent')
) {
  let transactionAccess = [getItem('View Transaction', '/transaction/view')];
  if (
    permissionsData.includes('loan-agent') ||
    permissionsData.includes('root')
  ) {
    //
    transactionAccess.push(getItem('Input Support', '/admin/loan', <PushpinFilled rev="icon-rev" />));
  }

  if (permissionsData.includes('pos-agent')) {
    transactionAccess.push(
      getItem('Commodity Purchase', '/farmer/transfer')
    );
  }
  items.push(
    getItem(
      'Commodity module',
      'transaction',
      <TransactionOutlined rev="icon-rev" />,
      transactionAccess
    )
  );
}
if (
  permissionsData.includes('root') ||
  permissionsData.includes('manage-inventory')|| permissionsData.includes('view-all-store-record')
) {
  let inventoryaccess = [getItem('View Log', '/admin/inventory-warehouse/log')];
  if (
    permissionsData.includes('root') ||
    permissionsData.includes('manage-inventory')
  ) {
    inventoryaccess.push(
      getItem('Manage Inventory', '/admin/inventory'))
  }
  if (
    permissionsData.includes('root') ||permissionsData.includes('view-all-store-record')){
  inventoryaccess.push(getItem('Manage Warehouse', '/admin/inventory-warehouse'))
  inventoryaccess.push(getItem('View Store Inventory', '/admin/warehouse-store'))
  }

  items.push(
    getItem(
      'Inventory',
      'Inventory',
      <TransactionOutlined rev="icon-rev" />,
      inventoryaccess
    )
  );

}
if (permissionsData.includes('calendar-farm-manager')) {
  items.push(
    getItem(
      'Farm Manager',
      '/admin/calendar/view-calendar',
      <CalendarOutlined rev="icon-rev" />
    )
  );
}


items.push(getItem('Signout', 'signout', <PoweroffOutlined rev="icon-rev" />));
export default function CustomSideBar() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setIsLoading(false);

    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={0}
        style={{ backgroundColor: '#fff' }}
      >
        <Menu
          onClick={({ key }) => {
            if (key === 'signout' || key === 'login') {
              AuthService.logout();
              navigate('/login');
            } else {
              navigate(key);
            }
          }}
          theme="dark"
          defaultSelectedKeys={[window.location.pathname]}
          mode="inline"
          items={items}
        />
      </Sider>
      {isLoading && (
        <div className="login-form-wrapper">
          <BarLoader
            color={'#06a35700'}
            loading={!isLoading}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
