// InventoryPage.tsx

import React, { useState, useEffect } from 'react';
import { Table, Button, Modal,Card, Form, Input ,Collapse,Select ,Layout,message} from 'antd';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import UserService from '../../../services/user.service';
import { CaretRightOutlined } from '@ant-design/icons';

import {
  Popconfirm,
  Space,
  Tag,
  theme,
  List,
  Pagination,message as alertmessage
} from 'antd';
const { Content } = Layout;
const { Panel } = Collapse;

interface Inventory {
  id: number;
  item_id: number;
  unityPrice: number;
  notation: string;
  statusID: number;
  createdBy?: number;
  quantity?: number;
  action?: string;
}
interface Warehouse {
  id: number;
  name: string;
  location: string;
}
interface selectOptions {
  value: number;
  label: string;
  price: number;
  measurement: number;
}

const InventoryPage: React.FC = () => {
  const [data, setData] = useState<Inventory[]>([]);
  const [warehoseData, setWarehoseData] = useState<Warehouse[]>([]);
  const [itemList, setItemList] = useState<selectOptions[]>([]);
  const action = [{value: 'add',label: 'Add'},{value: 'remove',label: 'Remove'}];
  const [isMobile, setIsMobile] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [viewInventory, setViewInventory] = useState(true);
  const [form] = Form.useForm();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  // const panelStyle = {
  //   marginBottom: 24,
  //   background: token.colorFillAlter,
  //   borderRadius: token.borderRadiusLG,
  //   border: 'none',
  // };
  const getLocation = async () => {
    try {
      // const response = await axios.get<{ data: Warehouse[] }>('/api/warehouse');
      UserService.GetRecord('inventory').then(
        (result: any) => {
          if (result.data) {
            setWarehoseData(result.data.data);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            message.error(resMessage)
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const loadItem = async () => {
    UserService.GetRecord('items').then(
      (result: any) => {
        if (result.data) {
          setItemList(
            result.data.data.map((option: any) => ({
              value: option.id,
              label: option.name+'/'+option.measurement,
              price: option.price,
              measurement:option.measurement
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          message.error(resMessage)
      }
    );
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  useEffect(() => {
    fetchData();
    getLocation();
    loadItem();
  }, []);

  const columns = [

    {
      title: 'Item ID',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Unity Price',
      dataIndex: 'unityPrice',
      key: 'unityPrice',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Notation',
      dataIndex: 'notation',
      key: 'notation',
    },
    {
      title: 'Ware House',
      dataIndex: 'WareHouse',
      key: 'WareHouse',
    },

  ];



    const fetchData = async () => {
    UserService.GetRecord('warehouse-inventory').then(
      (result: any) => {
        if (result.data) {
          setData(
            result.data.data.map((option: any) => ({
              id: option.id,
              quantity: option.quantity,
              notation: option.notation,
              unityPrice: option.unityPrice,
              item:option.Item.name,
              WareHouse:option.WareHouse.name
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          message.error(resMessage)
      }
    );
  };


  const handleModalOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as Inventory;
      UserService.PostRecord(values,'warehouse-inventory').then(
        (result: any) => {
          if (result.data) {
            message.success(result.data.message)
            setModalVisible(false);
            fetchData();
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            message.error(resMessage)
        }
      );

    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const renderPage = (pageData: any) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        // style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={
              option.item
            }
            key={index}
            // style={panelStyle}
          >
            <List
              size="small"

              bordered
              //
              dataSource={[
                'Item: ' + option.item,
                'Unit Price: ' + option.unityPrice,
                'Quantity: ' + option.quantity,
                'Notation: ' + option.notation,
                'WareHouse: ' + option.WareHouse,
              ]}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  return (
    <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <CustomSideBar />
          <Layout className="site-layout">
            <TopBar />
            <Content style={{ margin: '20px' }}>
              <div className="centered-container">
                  {!viewInventory?
                  <>
                  <h3>Select Warehouse Location</h3>

                  <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '16px' }}>
                    {warehoseData.map((warehouse) => (
                      <div
                      onClick={()=>setViewInventory(true)}
                      ><Card
                        key={warehouse.id}
                        title={warehouse.name}
                        style={{ width: 300, margin: '16px' }}
                      >
                        <p>Location: {warehouse.location}</p>
                      </Card></div>
                    ))}
                  </div>
                  </>:
                  <></>
                  }
                  {viewInventory?
                    <>
                    <div style={{display: 'flex'}} >
                    <Button onClick={() => setModalVisible(true)}>Add Inventory</Button>
                    </div>
                    {isMobile && (
                        <>
                          {/* Render the current page of data */}
                          {renderPage(data)}
                          {/* Render pagination component */}
                        </>
                      )}
                      {!isMobile && (
                        <>
                          {<Table dataSource={data} columns={columns} />}
                        </>
                      )}

                        <Modal
                          title="Edit Inventory"
                          visible={modalVisible}
                          onOk={handleModalOk}
                          onCancel={() => setModalVisible(false)}
                        >
                          <Form form={form} layout="vertical">
                            {/* Add form fields based on your Inventory schema */}
                            <Form.Item label="Item" name="item_id">
                                <Select options={itemList} />
                            </Form.Item>
                            <Form.Item label="Quantity" name="quantity">
                              <Input type='number' />
                            </Form.Item>
                            <Form.Item label="Notation" name="notation">
                              <Input />
                            </Form.Item>
                            <Form.Item label="Action" name="action">
                                <Select options={action} />
                            </Form.Item>

                          </Form>
                        </Modal>
                        </>:<></>
                      }
              </div>
            </Content>
            </Layout>
          </Layout>
    </div>
  );
};

export default InventoryPage;

