import {
    List,
    Table,
    Button,
    Collapse,
    Layout,
    Form,Select,
    Card,
    message,Flex
  } from 'antd';
  import CustomSideBar from '../inc/CustomSideBar';
  import TopBar from '../inc/TopBar';
  import UserService from '../../../services/user.service';
  import React, { useState, useEffect } from 'react';
  import { FileExcelOutlined } from '@ant-design/icons';
  import { ExportExcel } from '../../../library/Excelexport';
  import RoleHooks from '../../../hooks/verifyRole';
  import { CaretRightOutlined } from '@ant-design/icons';
  import type { SizeType } from 'antd/es/config-provider/SizeContext';
  import type { ColumnsType } from 'antd/es/table';
  const { permissionsData } = RoleHooks();


  const { Panel } = Collapse;
  const { Content } = Layout;
  interface selectOptions {
    value: number;
    label: string;
  }
  const panelStyle = {
    marginBottom: 24,

    border: 'none',
  };
  interface transactionType {
    id: number;
    item_id: number;
    unityPrice: number;
    notation: string;
    statusID: number;
    createdBy?: number;
    quantity?: number;
    warehouse?: string;
    action?: string;
  }

  const ViewTransaction: React.FC = (props) => {
    const [form] = Form.useForm();
    const [transaction, setTransaction] = useState<transactionType[]>([]);
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [warehoseData, setWarehoseData] = useState([]);
    const [selectedWareHouse, setselectedWareHouse] = useState();


    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      setIsMobile(mediaQuery.matches);
    }, []);


    const loadTransaction = async (warehouse:any=null) => {
      setLoading(true);
      let url = (warehouse===null)?`warehouse-inventory/log/history`:`warehouse-inventory/log/history?warehouse_id=${warehouse}`

      UserService.GetRecord(
        `${url}`
      ).then(
        (result: any) => {
          if (result.data) {
            setLoading(false);
            setTransaction(
              result.data.data.map((option: any, index: number) => ({
                id: index + 1,
              quantity: option.quantity,
              notation: option.notation,
              unityPrice: option.unityPrice,
              item:option.Item.name,
              warehouse:option.MainInventory.WareHouse.name,
              action:option.action.toUpperCase(),
              }))
            );
          }
        }, //
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            message.error(resMessage)
        }
      );
    };



    useEffect(() => {
      loadTransaction(selectedWareHouse);
    }, [selectedWareHouse]);
    useEffect(() => {
      getLocation();
    }, []);
    const exportExcel = () => {
      ExportExcel({
        excelDelta: transaction,
        fileName: 'InventoryLog',
      });
    };
    const getLocation = async () => {
      try {
        // const response = await axios.get<{ data: Warehouse[] }>('/api/warehouse');
        UserService.GetRecord('inventory').then(
          (result: any) => {
            if (result.data) {
              setWarehoseData(result.data.data);
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              message.error(resMessage)
          }
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const columns: ColumnsType<transactionType> = [
        {
            title: 'SN',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'WareHouse',
            dataIndex: 'warehouse',
            key: 'warehouse',
          },
        {
            title: 'Item ID',
            dataIndex: 'item',
            key: 'item',
          },
          {
            title: 'Unity Price',
            dataIndex: 'unityPrice',
            key: 'unityPrice',
          },
          {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
          },
          {
            title: 'Notation',
            dataIndex: 'notation',
            key: 'notation',
          },


          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
          }


    ];

    const renderPage = (pageData: any) => {
      return (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
          )}
          // style={{ background: token.colorBgContainer }}
        >
          {pageData.map((option: any, index: number) => (
            <Panel header={option.item} key={index} style={panelStyle}>
              <List
                size="small"
                bordered
                dataSource={[
                  'Warehouse: ' + option.warehouse,
                  'Item: ' + option.item,
                  'unityPrice: ' + option.unityPrice,
                  'quantity: ' + option.quantity,
                  'Naration: ' + option.notation,
                  'Created At: ' + option.createdAt,

                ]}
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            </Panel>
          ))}
        </Collapse>
      );
    };


    return (
      <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <CustomSideBar />
          <Layout className="site-layout">
            <TopBar />
            <Content style={{ margin: '20px' }}>
              <Card
                title="Inventory Log"
                style={{ width: '100%', margin: '0 auto' }}
                id="BankingAgent"
              >
                <Flex >

                <Button onClick={exportExcel}>
                  <FileExcelOutlined rev="" />
                  Export
                </Button>
                { (
              permissionsData.includes('view-all-store-record') ||
                permissionsData.includes('root')) &&
                <Select  style={{minWidth:'50%'}} onChange={setselectedWareHouse}>

                  {warehoseData && warehoseData.map((data:any) => (
                    <Select.Option key={data.id} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>}


                </Flex>
                <div style={{ marginTop: '20px' }}></div>
                {!isMobile && (
                  <Table
                    size="middle"
                    bordered
                    scroll={{ x: 'calc(700px + 50%)' }}
                    loading={loading}
                    style={{ marginTop: '20px' }}
                    dataSource={transaction}
                    columns={columns}
                  />
                )}
                {isMobile && (
                  <>
                    {/* Render the current page of data */}
                    {renderPage(transaction)}
                    {/* Render pagination component */}
                  </>
                )}
              </Card>

            </Content>
          </Layout>
        </Layout>
      </div>
    );
  };

  export default ViewTransaction;
