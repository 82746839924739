import React from 'react';
import { theme, Layout } from 'antd';
import RoleHooks from '../../../hooks/verifyRole';

const { Header, Content } = Layout;
const { name, status, permissionsData } = RoleHooks();

export default function TopBar() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      <Header style={{ padding: 0, background: colorBgContainer }}>
        <div className="logo">{name + ' (' + status + ')'}</div>
      </Header>
    </>
  );
}
