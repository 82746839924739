import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import {
  Form,
  Input,
  Button,
  Layout,
  Card,
  Skeleton,
  Alert,
  message,
  Col,
  Row,
  Modal,
  Popconfirm,
  Space,
  List,
  Select,
  Typography,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
const { Content } = Layout;
const { Search } = Input;
const { Meta } = Card;

interface Calendar {
  id: number;
  title: string;
  maxDuration: number;
  farmCalendarMetaData: any;
}
interface Product {
  value: number;
  label: string;
}
interface Meta {
  key?: number | null;
  id?: number | null;
  activityId?: number | null;
  farmCalendarID: number;
  FarmCalendarActivity?: any;
}
const CalendarComponent = () => {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [calendarTitle, setCalendarTitle] = useState('');
  const [maxDuration, setmaxDuration] = useState('');

  const [calendarsMetaData, setCalendarsMetaData] = useState<Meta[]>([]);

  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [calendarsData, setCalendarsData] = useState<Calendar[]>([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [editCalendarId, setEditCalendarId] = useState<number | null>(null);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    fetchCalendars();
    fetchProducts();
  }, []);

  const fetchCalendars = () => {
    setLoading(true);
    UserService.GetRecord('calender/calendar-setting').then(
      (response: any) => {
        setCalendars(response.data.data);
        setCalendarsData(response.data.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };
  const fetchProducts = () => {
    setLoading(true);
    UserService.GetRecord('calender/activity').then(
      (response: any) => {
        setProducts(
          response.data.data.map((option: any) => ({
            label: option.name,
            value: option.id,
          }))
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };
  const fetchCalendarMeta = (id: number) => {
    UserService.GetRecord('calender/calendar-meta/' + id).then(
      (response: any) => {
        setCalendarsMetaData(response.data.data);
      }
    );
  };
  const removeMeta = (id: number, calendarId: number) => {
    UserService.DeleteRecord('calender/calendar-meta/' + id).then(
      (response: any) => {
        fetchCalendarMeta(calendarId);
      }
    );
  };
  const handleAddMeta = (farmCalendarID: any) => {
    const newMeta: Meta = {
      id: null,
      key: Math.random(),
      activityId: 1,
      farmCalendarID: farmCalendarID,
    };
    setCalendarsMetaData([...calendarsMetaData, newMeta]);
  };
  const handleSaveMeta = (
    calendarId: number,
    activities: number,
    hectares: number,
    Metaid = null
  ) => {
    if (Metaid === null) {
      UserService.PostRecord(
        { activities, hectares },
        'calender/calendar-meta/' + calendarId
      ).then((response: any) => {
        fetchCalendarMeta(calendarId);
        message.success(response.data.message);
        fetchCalendars();
      });
    } else {
      UserService.UpdateRecord(
        { activities, hectares },
        'calender/calendar-meta/' + Metaid
      ).then((response: any) => {
        message.success(response.data.message);
        fetchCalendars();
      });
    }
  };
  const handleSaveCalendar = () => {
    if (editCalendarId !== null) {
      UserService.UpdateRecord(
        { maxDuration, title: calendarTitle },
        'calender/calendar-setting/' + editCalendarId
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
          const updatedCalendar = calendars.map((calendarsData) =>
            calendarsData.id === editCalendarId
              ? {
                  ...calendars,
                  id: result.data.data.id,
                  title: result.data.data.title,
                  maxDuration: result.data.data.maxDuration,
                  farmCalendarMetaData: result.data.data.farmCalendarMetaData,
                }
              : calendarsData
          );
          setCalendars(updatedCalendar);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    }
  };
  const handleRemoveCardMeta = (CID: number) => {
    const updatedProducts = calendarsMetaData.filter(
      (product) => product.key !== CID
    );
    setCalendarsMetaData(updatedProducts);
  };

  const onFinish = (values: any) => {
    if (typeof values.meta != 'undefined') {
      UserService.PostRecord(values, 'calender/calendar-setting').then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
          const newCalendar: Calendar = {
            id: result.data.data.id,
            title: result.data.data.title,
            maxDuration: result.data.data.maxDuration,
            farmCalendarMetaData: result.data.data.farmCalendarMetaData,
          };
          setCalendars([...calendars, newCalendar]);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    }
  };
  const handleEditCalendar = (calendar: any) => {
    setCalendarsMetaData(calendar.farmCalendarMetaData);
    setEditCalendarId(calendar.id);
    setCalendarTitle(calendar.title);
    setmaxDuration(calendar.maxDuration);
    setModalEditOpen(true);
  };
  const handleDeleteCalendar = (calendarId: number) => {
    UserService.DeleteRecord('calender/calendar-setting/' + calendarId).then(
      (response) => {
        const updatedCalendars = calendars.filter(
          (calendar) => calendar.id !== calendarId
        );
        setCalendars(updatedCalendars);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const onSearch = (value: string) => {
    // Method 2: Using for...of loop
    const filteredItems = calendarsData.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setCalendars(filteredItems);
  };

  const handleViewCalendarRecord = (calendarID: any) => {
    UserService.GetRecord('calender/calendar-data/' + calendarID).then(
      (response: any) => {
        message.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };

  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Farm Calender</h1>

            <div className="m-5">
              <Button
                type="primary"
                onClick={() => {
                  setModal2Open(true);
                  setEditCalendarId(null);
                  setCalendarTitle('');
                }}
              >
                Add Calendar
              </Button>
            </div>
            <div className="m-5">
              <Space direction="vertical">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Space>
            </div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body">
              <Row gutter={[16, 16]}>
                {calendars.length > 0 ? (
                  calendars.map((calendar) => (
                    <Col
                      key={calendar.id}
                      span={8}
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      <div className="card">
                        <div className="card-content">
                          <List
                            header={<div>{calendar.title}</div>}
                            // footer={<div>{calendar.startDate}</div>}
                            bordered
                            dataSource={calendar.farmCalendarMetaData}
                            renderItem={(item: any) => (
                              <List.Item>
                                <small>
                                  <b>
                                    <Typography.Text mark>
                                      {item.FarmCalendarActivity.name}
                                    </Typography.Text>{' '}
                                  </b>
                                  : {item.totalNumberOfHectare}{' '}
                                </small>
                              </List.Item>
                            )}
                          />
                        </div>
                        <div className="card-buttons">
                          <Button
                            type="text"
                            className="edit-button"
                            onClick={() => {
                              handleEditCalendar(calendar);
                            }}
                          >
                            <EditOutlined rev="rev-icon" />
                          </Button>
                          <Popconfirm
                            title="Delete the activity"
                            description="Are you sure to delete this activity?"
                            icon={
                              <QuestionCircleOutlined
                                rev="rev-icon"
                                style={{ color: 'red' }}
                              />
                            }
                            onConfirm={() => {
                              handleDeleteCalendar(calendar.id);
                            }}
                          >
                            <Button type="text" className="delete-button">
                              <DeleteOutlined rev="rev-icon" />
                            </Button>
                          </Popconfirm>
                        </div>
                        <Skeleton loading={loading} avatar active></Skeleton>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message="Data not found!" showIcon type="error" />
                  </div>
                )}
              </Row>
            </div>
            <Modal
              title="Create Calendar"
              centered
              open={modal2Open}
              footer={[
                <Button key="cancel" onClick={() => setModal2Open(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => setModal2Open(false)}
            >
              <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                style={{ maxWidth: '100%' }}
                autoComplete="off"
              >
                <Form.Item
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input title.',
                    },
                  ]}
                  label={'Title Calendar'}
                  name={'title'}
                  noStyle
                >
                  <Input
                    placeholder="Title Calendar"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <div style={{ marginBottom: '10px' }}></div>
                <Form.Item
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input max duration.',
                    },
                  ]}
                  noStyle
                  label={'Max Duration'}
                  name={'maxDuration'}
                >
                  <Input
                    placeholder="Max month Duration"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <div style={{ marginBottom: '10px' }}></div>
                <Form.List name="meta">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'activities']}
                            rules={[
                              { required: true, message: 'Select Activity' },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select activity"
                              optionFilterProp="children"
                              onSearch={onSearch}
                              options={products}
                            />
                          </Form.Item>

                          <MinusCircleOutlined
                            rev="rev-icon"
                            onClick={() => remove(name)}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined rev="rev-icon" />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              title="Edit Calendar"
              centered
              open={modalEditOpen}
              footer={[
                <Button
                  loading={isSubmitting}
                  key="save"
                  onClick={handleSaveCalendar}
                >
                  Update
                </Button>,
                <Button key="cancel" onClick={() => setModalEditOpen(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => setModalEditOpen(false)}
            >
              <span>Title</span>
              <Input
                placeholder="Title"
                value={calendarTitle}
                onChange={(e) => setCalendarTitle(e.target.value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <span>Max Duration</span>

              <Input
                placeholder="Max Duration"
                value={maxDuration}
                onChange={(e) => setmaxDuration(e.target.value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <span>Activities</span>

              {calendarsMetaData &&
                calendarsMetaData.map((Meta: any) => (
                  <Space
                    key={Math.random()}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Select
                      showSearch
                      placeholder="Select activity"
                      optionFilterProp="children"
                      options={products}
                      defaultValue={Meta.activityId}
                      onChange={(value) => (Meta.activityId = value)}
                    />

                    <MinusCircleOutlined
                      rev="true"
                      onClick={() =>
                        Meta.id != null
                          ? removeMeta(Meta.id, Meta.farmCalendarID)
                          : handleRemoveCardMeta(Meta.key)
                      }
                    />

                    <CheckCircleOutlined
                      rev="true"
                      onClick={() =>
                        handleSaveMeta(
                          Meta.farmCalendarID,
                          Meta.activityId,
                          Meta.totalNumberOfHectare,
                          Meta.id
                        )
                      }
                    />
                  </Space>
                ))}

              <Button
                type="dashed"
                onClick={() => {
                  handleAddMeta(editCalendarId);
                }}
                block
                icon={<PlusOutlined rev="rev-icon" />}
              >
                Add Activity
              </Button>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default CalendarComponent;
