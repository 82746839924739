import React, { useRef, useState, useEffect } from 'react';
import RoleHooks from '../../../hooks/verifyRole';
import { Link } from 'react-router-dom';

import {
  CaretRightOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  DeleteFilled,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Input,
  Space,
  message,
  Popconfirm,
  theme,
  Pagination,
  Layout,
  Tag,
  Table,
  Modal,
  Form,
  Select,
  Alert,
} from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { List } from 'antd';
import { Divider } from 'antd';
import { Tooltip } from '@mui/material';
import { ExportExcel } from '../../../library/Excelexport';
import { generatePDF } from '../../../library/PDFexport';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import Highlighter from 'react-highlight-words';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { getLatitude,getLongitude } from 'geolocation-utils'; // Importing geolocation-utils library (if installed)
const { Content } = Layout;
const { Panel } = Collapse;
const { TextArea } = Input;
const { permissionsData } = RoleHooks();

interface agentType {
  key: React.Key;
  id?: number;
  uuid?: string;
  name?: string;
  phone?: string;
  product1?: string;
  product2?: string;
  quantity1?: number;
  quantity2?: number;
  status?: string;
  return?: number;
  expected_payback?: string;
  created_at?: string;
  locationArea?: string;
  locationLink?: string;
}
interface selectOptions {
  value: number;
  label: string;
}
type DataIndex = keyof agentType;
const LoanComponent: React.FC = (props) => {
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [farmerData, setFarmerData] = useState<agentType[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [pageSize, setPageSize] = useState(10);
  const [success, setSuccess] = useState<string>('');
  const [errorMsg, setError] = useState<string>('');
  const { token } = theme.useToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [itemList, setItemList] = useState<selectOptions[]>([]);
  const [resMessage, setResMessage] = useState<{
    message: string;
    tag: 'success' | 'error' | 'info' | 'warning';
  }>();
  const [farmerID, setFarmerID] = useState('');
  const [formloan] = Form.useForm();
  const [showDate, SetshowDate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [location, setLocation] = useState<any>({latitude:null, longitude:null});

  const [searchStatus, setSearchStatus] = useState('');

  const onsearchChange = (checkedValues: any) => {
    setSearchStatus(checkedValues);
  };
  const searchBTN = () => {
    const formattedStartDate = showDate
      ? format(dateRange[0].startDate, 'yyyy-MM-dd')
      : '';
    const formattedEndDate = showDate
      ? format(dateRange[0].endDate, 'yyyy-MM-dd')
      : '';
    LoadData(
      `loan?startdate=${formattedStartDate}&enddate=${formattedEndDate}&status=${searchStatus}`
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
    getLocation()
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDateRangeChange = (ranges: any) => {
    setDateRange([ranges.selection]);
  };
  const useDateHandler = () => {
    SetshowDate(!showDate);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const exportExcel = () => {
    ExportExcel({
      excelDelta: farmerData,
      fileName: 'FarmersAccount',
    });
  };
  const mapLink = location.latitude
  ? `https://maps.google.com/?q=${location.latitude},${location.longitude}`
  : null;

  useEffect(() => {
    loadItem();
  }, []);
  const loadItem = async () => {
    UserService.GetRecord('items').then(
      (result: any) => {
        if (result.data) {
          setItemList(
            result.data.data.map((option: any) => ({
              value: option.id,
              label: option.name,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          message.error(resMessage)
      }
    );
  };
  const LoadData = (url: string) => {
    UserService.GetRecord(url).then(
      (result) => {
        if (result.data) {
          setSuccess('data found!');
          setTotalData(result.data.data.length);
          setFarmerData(
            result.data.data.map((option: any, index: number) => ({
              key: index + 1,
              id: option.id,
              uuid: option.Farmer.uuid,
              name: option.Farmer.first_name + ' ' + option.Farmer.last_name,
              phone: '( *'+option.Farmer.phone+' )',
              product1: option.Item1Model.name,
              product2: option.Item2Model ? option.Item2Model.name : '',
              quantity1: option.quantity1,
              quantity2: option.quantity2,
              amount: option.amount,
              return: option.return,
              status: option.TransactionStatus.name,
              created_at: option.createdAt,
              locationArea: option.locationArea,
              locationLink: option.locationLink,
              expected_payback: option.LoanRepayments[0].expectedPaymentDate,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
        setFarmerData([]);
      }
    );
  };
  const onFinish = (values: any) => {
    setSubmitting(true);
    values.farmerID = farmerID;
    values.locationLink = mapLink
    UserService.PostRecord(values, 'loan').then(
      (response) => {
        setSubmitting(false);

        form.resetFields();
        message.success(response.data.message);
        LoadData('loan');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const onDelete = (id: any) => {
    UserService.DeleteRecord('loan/' + id).then(
      (response) => {
        form.resetFields();
        message.success(response.data.message);
        LoadData('loan');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.length >= 6) {
      setFarmerID(e.target.value);
      UserService.GetRecord('farmers/' + e.target.value).then(
        (result: any) => {
          if (result.data) {
            setResMessage({
              message:
                result.data.data.first_name + '-' + result.data.data.last_name,
              tag: 'success',
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setResMessage({ message: resMessage, tag: 'error' });
        }
      );
    }
  };
  const completeLoan = (LoanID: number) => {
    setSubmitting(true);
    UserService.UpdateRecord({}, 'loan/' + LoanID).then(
      (result: any) => {
        setSubmitting(false);
        LoadData('loan');
        message.success('Loan Completed!');
      },
      (error) => {
        setSubmitting(false);

        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  useEffect(() => {
    if (success !== '') {
      message.success(success);
    }
  }, [success]);
  useEffect(() => {
    if (errorMsg !== '') {
      message.error(errorMsg);
    }
  }, [errorMsg]);
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  useEffect(() => {
    setPageLoaded(true);
  }, []);
  useEffect(() => {
    LoadData('loan');
  }, [currentPage, pageSize]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<agentType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev="icon-rev" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev="icon-rev"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns: ColumnsType<agentType> = [
    {
      title: 'SN',
      dataIndex: 'key',
      key: 'key',
      ...getColumnSearchProps('key'),
    },
    {
      title: 'Farmer ID',
      dataIndex: 'uuid',
      key: 'uuid',
      ...getColumnSearchProps('uuid'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },

    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },

    {
      title: 'Item 1',
      dataIndex: 'product1',
      key: 'product1',
      ...getColumnSearchProps('product1'),
    },
    {
      title: 'QTY 1',
      dataIndex: 'quantity1',
      key: 'quantity1',
      ...getColumnSearchProps('quantity1'),
    },
    {
      title: 'Item 2',
      dataIndex: 'product2',
      key: 'product2',
      ...getColumnSearchProps('product2'),
    },
    {
      title: 'QTY 2',
      dataIndex: 'quantity2',
      key: 'quantity',
      ...getColumnSearchProps('quantity2'),
    },

    {
      title: 'Repayment Quantity',
      dataIndex: 'return',
      key: 'return',
      // ...getColumnSearchProps('return'),

      render: (_: any, record: any) => {
        return  ( <Popconfirm
          title="Each bag should weigh 75KG, Weight - 75kg Grain size - Long grain
          Moisture content -14%?"
        ><Button>
        {record.return+' Bags of Rice'}
      </Button></Popconfirm>)
      }
    },
    {
      title: 'Payment Date',
      dataIndex: 'expected_payback',
      key: 'expected_payback',
      render: (_: any, record: any) => {
        const originalDate = new Date(`${record.expected_payback}`);

        const formattedDate = originalDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short',
        });
        return formattedDate.toString();
      }
      // ...getColumnSearchProps('expected_payback'),
    },
    {
      title: 'Geo-Location',
      dataIndex: 'locationLink',
      key: 'locationLink',

      render: (_: any, record: any) => {
        return  (<a href={record.locationLink} target="_blank" rel="noopener noreferrer">
        Open in Maps
      </a>)
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // ...getColumnSearchProps('status'),
      render: (_: any, record: any) => {
        const today = new Date();
        const targetDate = new Date(record.expected_payback);
        if (today > targetDate && record.status === 'Pending') {
          return <Tag color="red">Missed</Tag>;
        } else if (record.status === 'Pending') {
          return <Tag color="orange">{record.status}</Tag>;
        } else {
          return <Tag color="green">{record.status}</Tag>;
        }
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      ...getColumnSearchProps('created_at'),
    },

    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',

      render: (_: any, record: any) => (
        <>
          {record.status != 'Complete' && (
            <Space>
              <Popconfirm
                title="Please confirm that the weight received and the quantity recieved are in good condition as specified?"
                onConfirm={() => completeLoan(record.id)}
              >
                <Button>
                  Complete
                  <CheckCircleOutlined rev="icon-rev" />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {(permissionsData.includes('delete-loan') ||
            permissionsData.includes('root')) && (
            <Popconfirm
              title="Sure you want to delete this loan?"
              onConfirm={() => onDelete(record.id)}
            >
              <Button>
                Delete
                <DeleteFilled rev="icon-rev" />
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];
  const handleSuccess = (position:any) => {
    if(position.coords){
      const { latitude, longitude } = position.coords;
      console.log('cooredinate',position.coords)
      setLocation({ latitude, longitude });
    }

  };

  // Function to handle geolocation errors
  const handleError = (error:any) => {
    message.error(error.message);
  };

  const getLocation=()=>{
    if (navigator.geolocation) {
      // Use the Geolocation API to get the current position
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      message.error('Geolocation is not supported by your browser.');
    }
  }
  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => {
          const today = new Date();
          const targetDate = new Date(option.expected_payback);
          let tags = <Tag color="red">Missed</Tag>;
          if (today > targetDate && option.status === 'Pending') {
            tags = <Tag color="red">Missed</Tag>;
          } else if (option.status === 'Pending') {
            tags = <Tag color="orange">{option.status}</Tag>;
          } else {
            tags = <Tag color="green">{option.status}</Tag>;
          }
          return (
            <Panel
              header={option.uuid + ' - ' + option.name}
              key={index}
              style={panelStyle}
            >
              <List
                size="small"
                footer={
                  option.status != 'Complete' && (
                    <>
                      <Space>
                        <Popconfirm

                          title="Please confirm that the weight received and the quantity recieved are in good condition as specified?"
                          onConfirm={() => completeLoan(option.id)}
                        >
                          <Button>
                            Complete
                            <CheckCircleOutlined rev="icon-rev" />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </>
                  )
                }
                dataSource={[
                  'Name: ' + option.name,
                  'UUID: ' + option.uuid,
                  'Phone: ' + option.phone,
                  'Product 1: ' + option.product1,
                  'Quantity: ' + option.quantity1,
                  'Product 2: ' + option.product2,
                  'Quantity: ' + option.quantity2,
                  'Expected Payback: ' + option.expected_payback,
                  <a href={option.locationLink} target="_blank" rel="noopener noreferrer">
                  Open in Maps
                </a>,


                  tags,
                ]}
                bordered
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <div style={{ overflowX: 'auto' }}></div>
                <div style={{ margin: '5px' }}>
          <Button type="default" size="large" >
              <Link to="/admin/loan">Input Support</Link>
            </Button>
            <Button type="primary" size="large" >
              <Link to="/farmer/transfer">Commodity Purchase</Link>
            </Button>
            </div>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    padding: '5px',
                  }}
                >
                  <Select
                    style={{
                      width: 220,
                      marginRight: '10px',
                      marginBottom: '10px',
                    }}
                    onChange={onsearchChange}
                    options={[
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Complete', label: 'Completed' },
                    ]}
                  />
                  <div
                    id="range-picker-container"
                    className="range-picker-container"
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                  >
                    {showDate && (
                      <DateRange

                        ranges={dateRange}
                        onChange={handleDateRangeChange}
                      />
                    )}
                    <Button type="primary" onClick={useDateHandler}>
                      {showDate ? 'Hide Schedule' : 'Show Schedule'}
                    </Button>

                    <Button
                      type="primary"
                      onClick={searchBTN}
                      style={{ margin: '5px' }}
                    >
                      Search
                    </Button>
                  </div>

                  <Tooltip
                    style={{ margin: '5px', marginBottom: '10px' }}
                    title="Add "
                  >
                    <Button type="primary" onClick={showModal}>
                      Initiate Request
                    </Button>
                  </Tooltip>
                </div>
                <h2>Transaction Summary</h2>
                <Tooltip style={{ margin: '5px' }} title="Export Excel">
                  <Button
                    onClick={() => {
                      exportExcel();
                    }}
                  >
                    Export Excel
                    <FileExcelOutlined rev="icon-rev" />
                  </Button>
                </Tooltip>
                <Tooltip style={{ margin: '5px' }} title="Export Excel">
                  <Button
                    onClick={() => {
                      generatePDF();
                    }}
                  >
                    Export PDF
                    <FilePdfOutlined rev="icon-rev" />
                  </Button>
                </Tooltip>

                <Divider />

                <div id="report">
                  {!isMobile ? (
                    <Table
                      columns={columns}
                      dataSource={farmerData}
                      scroll={{ x: 'max-content' }}
                      pagination={false}
                    />
                  ) : (
                    <>{renderPage(farmerData, 'order')}</>
                  )}
                </div>
                <Pagination
                  total={totalData}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={handlePaginationChange}
                />

                <Modal
                  title="Initiate Request"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Form
                    name="basic"
                    form={formloan}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Enter Farmer's ID"
                      name="farmerID"
                      rules={[{ message: "Please Enter Farmer's ID!" }]}
                    >
                      <Input onChange={onChange} />
                      <span>
                        {resMessage && (
                          <Alert
                            message={resMessage.message}
                            type={resMessage.tag}
                          />
                        )}
                      </span>
                    </Form.Item>

                    <Form.Item
                      label="Select Item 1"
                      name="item1"
                      rules={[
                        { required: true, message: 'Please select an item!' },
                      ]}
                    >
                      <Select options={itemList} />
                    </Form.Item>

                    <Form.Item
                      label="Quantity"
                      name="quantity1"
                      rules={[
                        { required: true, message: 'Please input quantity!' },
                      ]}
                    >
                      <Input type="number" min={0} />
                    </Form.Item>
                    <Form.Item label="Select Item 2" name="item2">
                      <Select options={itemList} />
                    </Form.Item>

                    <Form.Item label="Quantity" name="quantity2">
                      <Input type="number" min={0} />
                    </Form.Item>
                    <Form.Item
                      name="locationLink"
                      label="Geo-Location"
                      rules={[
                        {
                          message: 'location!',
                          whitespace: false,
                        },
                      ]}
                    >
                      {mapLink && (
                              <a href={mapLink} target="_blank" rel="noopener noreferrer">
                                Open in Maps
                              </a>
                            )}
                      {/* <Input /> */}
                    </Form.Item>

                    <Form.Item
                      name="locationArea"
                      label="Remark"
                      rules={[
                        {
                          message: 'Remark!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                    <center>
                      <Button
                        loading={submitting}
                        disabled={resMessage ? false : true}
                        htmlType="submit"
                        type="primary"
                      >
                        Submit
                      </Button>
                    </center>
                  </Form>
                </Modal>


                <Modal
                  title="Initiate batch operation"
                  open={isModalOpen}
                  onCancel={handleCancel}
                >
                 
                    <center>
                      <Button
                        loading={submitting}
                        disabled={resMessage ? false : true}
                        htmlType="submit"
                        type="primary"
                      >
                        Submit
                      </Button>
                    </center>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default LoanComponent;
//transaction summary