import React, { useState, useEffect } from 'react';
import RoleHooks from '../../hooks/verifyRole';
import { Link } from 'react-router-dom';
import {
  CopyOutlined,
  ShareAltOutlined,
  UserOutlined,
  OneToOneOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import { Layout, Row, Col, Card, Statistic } from 'antd';
import { Button, Input, Alert, Space, message, List, Modal } from 'antd';
import UserService from '../../services/user.service';
import CustomSideBar from './inc/CustomSideBar';
import TopBar from './inc/TopBar';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
const { Content } = Layout;
const { permissionsData } = RoleHooks();
interface transaction {
  transactionstatusID: number;
  totalAmount: number;
  transactionCount: number;
  statusLabel: string;
}
const Dashboard: React.FC = () => {
  const [PageLoaded, setPageLoaded] = useState(false);
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [tabshare, setTabshare] = useState(0);
  const [totalfarmer, setTotalfarmer] = useState(0);
  const [totalMonitor, setTotalmonitor] = useState(0);
  const [totalFarmerWithout, setTotalFarmerWithout] = useState(0);
  const [transactionStat, setTransactionStat] = useState<transaction[]>([]);

  const handleCopy = async () => {
    try {
      navigator.clipboard.writeText(code);
      message.success('Code copied to clipboard!');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      permissionsData.includes('view-dashboard') ||
      permissionsData.includes('pos-agent') ||
      permissionsData.includes('auditor')
    ) {
      UserService.GetRecord('dashboard').then((result) => {
        if (result.data) {
          setTotalfarmer(result.data.totalFarmer);
          setTotalmonitor(result.data.totalMonitor);
          setTotalFarmerWithout(result.data.farmersWithoutMonitorTotal);
          setTransactionStat(result.data.Transaction);
        } else {
          console.log('no record');
        }
      });
    }

    setPageLoaded(true);
  }, []);

  const generateCode = () => {
    if (permissionsData.includes('generate-referral-link')) {
      UserService.GetRecord(
        'banking/generate/registration/link?action=new-code'
      ).then((result) => {
        if (result.data) {
          const baseUrl =
            window.location.protocol + '//' + window.location.host;
          setCode(baseUrl + '/#' + result.data.code);
          message.success('Banking agent code generated!');
        }
      });
    }
  };

  const requirements = [
    'First Name',
    'Other Name',
    'Last Name',
    'Date of Birth',
    'Business Address',
    'Business Area',
    'Business Type',
    'Years in Business',
    'Address',
    'Bussiness Name',
    'BVN Number',
    'NIN Number',
    'Date Incoporation',
    'Email',
    'Gender',
    'LGA',
    'State',
    'Marital Status',
    'Mode of Identification',
    'Phone',
    'Position',
    'Registration Number',
    'Witness First_name',
    'Witness Last name',
    'Witness Other Name',
    'Link Code',
    'ID number',
    'Banks',
    'AccountNo',
  ];

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '5%' }}>
                <p>Check Menu </p>
                {(permissionsData.includes('view-dashboard-as-admin') ||
                  permissionsData.includes('view-dashboard-as-agent')) && (
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Card bordered={false}>
                        <Statistic
                          title="Total Hectares"
                          value={totalfarmer}
                          valueStyle={{ color: '#3f8600' }}
                          prefix={<UserOutlined rev="icon-rev" />}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Card bordered={false}>
                        <Statistic
                          title="Total Monitor"
                          value={totalMonitor}
                          valueStyle={{ color: '#3f8600' }}
                          prefix={<OneToOneOutlined rev="icon-rev" />}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Card bordered={false}>
                        <Statistic
                          title="Non-Active Hectares"
                          value={totalFarmerWithout}
                          valueStyle={{ color: '#3f8600' }}
                          prefix={<OneToOneOutlined rev="icon-rev" />}
                        />
                      </Card>
                    </Col>
                  </Row>
                )}
                <div style={{ marginTop: '10px' }}></div>
                {(permissionsData.includes('pos-agent') ||
                  permissionsData.includes('auditor')) && (
                  <><Row gutter={[16, 16]}>
                    {transactionStat &&
                      transactionStat.map((rec, index) => (
                        <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8}>
                          <Card bordered={false}>
                            <Statistic
                              title={
                                rec.statusLabel + `(${rec.transactionCount})`
                              }
                              value={rec.totalAmount}
                              valueStyle={{ color: '#3f8600' }}
                              prefix={
                               '₦'
                              }
                            />
                          </Card>
                        </Col>
                      ))}
                  </Row>
                  <Button type="primary" size="large" style={{margin:'10px'}}>
                  <Link to="/transaction/view">View Pending Transactions</Link>

                </Button>
                </>
                )}
                <br></br>
                {permissionsData.includes('generate-referral-link') && (
                  <>
                    <Space.Compact className="generate-link">
                      <Button
                        type="default"
                        onClick={() => setOpen(true)}
                        icon={<ShareAltOutlined rev="icon-rev" />}
                      ></Button>
                      <Input defaultValue={code} disabled={true} value={code} />
                      <Button type="primary" onClick={generateCode}>
                        Generate
                      </Button>
                    </Space.Compact>
                    <Alert
                      style={{ margin: '5%', textAlign: 'left' }}
                      message="Note: Please note before generating link, review that the following list have been validated"
                      type="warning"
                    />
                    <List
                      header={<h3>Registration Requirements</h3>}
                      bordered
                      dataSource={requirements}
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                    <Modal
                      open={open}
                      title="Share Link"
                      onCancel={handleCancel}
                      footer={[
                        <Button key="back" onClick={handleCancel}>
                          Cancel
                        </Button>,
                      ]}
                    >
                      {tabshare === 1 && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Row style={{ gap: '16px' }}>
                              <Col>
                                <Button
                                  type="default"
                                  onClick={handleCopy}
                                  icon={<CopyOutlined rev="icon-rev" />}
                                >
                                  Copy Link
                                </Button>
                              </Col>
                              <Col>
                                <WhatsappShareButton url={code}>
                                  {' '}
                                  <WhatsappIcon size={32} round />{' '}
                                </WhatsappShareButton>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                      {tabshare === 0 && (
                        <>
                          <h5>AGENTS ASSESSMENT FORM FOR SUPER-AGENTS.</h5>
                          <p>Note: Before sharing registration:</p>
                          <p>
                            Have you confirmed if the agent have access to a
                            good network?{' '}
                          </p>
                          <p>
                            All the requirement in the form, do the agent in
                            view have all?{' '}
                          </p>
                          <p>
                            Have you informed the agent that the system doesn’t
                            permit multiple entry?{' '}
                          </p>
                          <p>
                            Have you informed the prospective agent the link
                            expires after 24 hours?{' '}
                          </p>
                          <p>
                            Have you known this person you want to profile as an
                            agent for long?{' '}
                          </p>
                          <p>
                            Do you have a good relationship with the person?{' '}
                          </p>
                          <p>Is the person of a good character? </p>
                          <p>Do you know the person’s residence? </p>
                          <p>
                            Have you visited the proposed place the agent will
                            use for business?{' '}
                          </p>
                          <p>
                            Do you know the name of the street the agent lives?{' '}
                          </p>
                          <p>Is this person ready for duty as it calls? </p>
                          <p>
                            Can you say this person you want to register as an
                            agent have all it takes to contribute to the company
                            to achieve its set goals?{' '}
                          </p>
                          <center>
                            <Button onClick={() => setTabshare(1)}>
                              Proceed
                            </Button>
                          </center>
                        </>
                      )}
                    </Modal>
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Dashboard;
