import {
  Button,
  Popconfirm,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Select,
  theme,
  Alert,
  Modal,
  List,
  Collapse,
  Pagination,
  Col,
  Layout,
  Row,
} from 'antd';
import { formatDate } from '../../../common/utility';
import { CaretRightOutlined, FileExcelOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import '../../../costom-css/farmer.css';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import FarmerProfile from './FarmerProfile';
import { ExportExcel } from '../../../library/Excelexport';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';

const { Content } = Layout;
const { TextArea } = Input;

const { Option } = Select;
const { Column } = Table;
const { Panel } = Collapse;
interface DataType {
  key: React.Key;
  Base64Image: string;
  last_name: string;
  first_name: string;
  phone: string;
  email: string;
  accountNum: string;
  sex: string;
  uuid: string;
  status: string;
  createdAt: string;
  FarmerMonitors?: number;
  Agent: string;
  state?: string;
  farmerAccountDetail?: any;
  address?: string;
  bvnverified?: number;
  city?: string;
  dob?: string;
  middleName?: string;
  motherMaidenName?: string;
  occupation?: string;
  salutation?: string;
  streetName?: string;
  state_of_origin?: string;
  assignATM?: number;
  fcmbaccount?: string;
  locationLink?: string;
  locationArea?: string;
  bvn?: string;
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
interface selectOptions {
  value: number;
  label: string;
}
const FarmerPage: React.FC = () => {
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [submitMessage, setSubmitMessage] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const { token } = theme.useToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFarmer, setTotalFarmer] = useState(0);
  const [iseditModalVisible, setIseditModalVisible] = useState(false);
  const [submitTitle, setSubmitTitle] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [farmerID, setFarmerID] = useState(0);
  const [deleteMSG, setDeleteMSG] = useState('');
  const [isLoadingContent, SetisLoadingContent] = useState(true);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [state, setState] = useState<{ value: string; label: string }[]>([]);
  const [transferProps, setTransferProps] = useState<any>(null);
  const [cityData, setCity] = useState<any>([]);
  const [maritalData, setMarital] = useState<any>([]);
  const [employmentData, setEmployment] = useState<any>([]);
  const [pageSize, setPageSize] = useState(10);
  const [showDate, SetshowDate] = useState(false);
  const [agent, setAgent] = useState<selectOptions[]>([]);
  const [selectedAgent, setSelectedAgent] = useState();


  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const showModal = () => {
    setOpen(true);
  };
  const handleDateRangeChange = (ranges: any) => {
    setDateRange([ranges.selection]);
  };
  const searchRecord = () => {
    SetisLoadingContent(true);
    const formattedStartDate = showDate
      ? format(dateRange[0].startDate, 'yyyy-MM-dd')
      : '';
    const formattedEndDate = showDate
      ? format(dateRange[0].endDate, 'yyyy-MM-dd')
      : '';
      LoadData(`?from=${formattedStartDate}&to=${formattedEndDate}&agent=${selectedAgent}`);

  };
  const exportExcel = async() => {

    SetisLoadingContent(true);
    const formattedStartDate = showDate
      ? format(dateRange[0].startDate, 'yyyy-MM-dd')
      : '';
    const formattedEndDate = showDate
      ? format(dateRange[0].endDate, 'yyyy-MM-dd')
      : '';
      LoadData(`?from=${formattedStartDate}&to=${formattedEndDate}&exportbtn=true&agent=${selectedAgent}`,true);
  };
  const loadAgent = async () => {
    UserService.GetRecord('users/user/get-all-agents').then(
      (result: any) => {
        if (result.data) {
          setAgent(
            result.data.data.map((option: any) => ({
              value: option.id,
              label: option.first_name+' '+option.last_name,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };
  useEffect(() => {
    LoadData();
  }, [currentPage, pageSize]);

  useEffect(() => {
    loadAgent()
  }, []);
  const FCMBUtility = async () => {
    await UserService.GetRecord('utilities/fcmb').then((result) => {
      setCity(
        result.data.cities.map((data: any) => ({
          value: data.ref_code,
          label: data.ref_desc,
        }))
      );
      setMarital(
        result.data.maritalstatus.map((data: any) => ({
          value: data.ref_code,
          label: data.ref_desc,
        }))
      );
      setEmployment(
        result.data.employment.map((data: any) => ({
          value: data.value,
          label: data.localetext,
        }))
      );
    });
  };
  const submitForm = (values: any) => {
    setLoading(true);
    UserService.PostRecord(values, 'farmers').then(
      (response) => {
        LoadData();
        setLoading(false);
        setOpen(false);
        form.resetFields();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setSubmitMessage(resMessage);
      }
    );
  };
  const LoadData = async (urldata: any = null, exportData = false) => {
    SetisLoadingContent(true);
    const directLink = urldata ? `farmers${urldata}&page=${currentPage}&pageSize=${pageSize}` : `farmers?page=${currentPage}&pageSize=${pageSize}`;
    try {
        const result = await UserService.GetRecord(directLink);
        if (result.data) {
            SetisLoadingContent(false);
            setTotalFarmer(result.data.total);

            const formattedData = result.data.farmer.map((option: any) => ({
                key: option.id,
                last_name: option.last_name,
                first_name: option.first_name,
                bvn: option.bvn,
                phone: option.phone,
                email: option.email,
                accountNum: option.accountNum,
                sex: option.sex,
                locationLink: option.locationLink,
                locationArea: option.locationArea,
                uuid: option.uuid,
                status: option.status,
                createdAt: formatDate(option.createdAt),
                FarmerMonitors: option.FarmerMonitors.length,
                Agent: option.User.first_name + ' ' + option.User.last_name,
                state: option.State ? option.State.name : '',
                farmerAccountDetail: option.farmerAccountDetail,
                address: option.address,
                bvnverified: option.bvnverified,
                city: option.city,
                fcmbaccount: option.farmerAccountDetail ? option.farmerAccountDetail.accountNumber : 'none',
                dob: option.dob,
                middleName: option.middleName,
                motherMaidenName: option.motherMaidenName,
                occupation: option.occupation,
                salutation: option.salutation,
                streetName: option.streetName,
                state_of_origin: option.state_of_origin,
                Base64Image: option.Base64Image,
                assignATM: option.assignATM,
            }));

            setData(formattedData);

            if (exportData) {
                ExportExcel({
                    excelDelta: formattedData,
                    fileName: 'NOMAFarmers',
                });
            }
        } else {
            setData([]);
        }
    } catch (error) {
        console.error("Error loading data:", error);
        SetisLoadingContent(false);
    }
};

  useEffect(() => {
    LoadData();
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
    setPageLoaded(true);
    FCMBUtility();
    UserService.GetRecord('state').then((result) => {
      if (result.data) {
        setState(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  }, []);
  const handleCancel = () => {
    setOpen(false);
  };
  const handleDelete = (key: any) => {
    UserService.DeleteRecord('farmers/' + key).then(
      (response) => {
        setDeleteMSG(response.data.message);
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setDeleteMSG(resMessage);
      }
    );
  };
  const handleEdit = (key: any) => {
    editform.resetFields();
    setFarmerID(key);
    UserService.GetRecord('farmers/' + key).then((result) => {
      if (result.data) {
        editform.setFieldsValue({
          first_name: result.data.data.first_name,
          last_name: result.data.data.last_name,
          email: result.data.data.email,
          phone: result.data.data.phone,
          accountNum: result.data.data.accountNum,
          sex: result.data.data.sex,
          bvn: result.data.data.bvn,
          status: result.data.data.status,
          stateID: result.data.data.stateID,
          middleName: result.data.data.middleName,
          motherMaidenName: result.data.data.motherMaidenName,
          dob: result.data.data.dob,
          locationLink: result.data.data.locationLink,
          locationArea: result.data.data.locationArea,
          houseNum: result.data.data.houseNum,
          streetName: result.data.data.streetName,
          city: result.data.data.city,
          maritalStatus: result.data.data.maritalStatus,
          occupation: result.data.data.occupation,
          salutation: result.data.data.salutation,
          address: result.data.data.address,
          state_of_origin: result.data.data.state_of_origin,
          Base64Image: result.data.data.Base64Image,
        });
        setIseditModalVisible(true);
      }
    });
  };
  const useDateHandler = () => {
    SetshowDate(!showDate);
  };
  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    UserService.GetRecord(
      `farmers?search=` +
        e.target.value +
        `&page=${currentPage}&pageSize=${pageSize}`
    ).then(
      (result) => {
        setTotalFarmer(result.data.total);
        if (result.data.farmer) {
          setData(
            result.data.farmer.map((option: any) => ({
              key: option.id,
              last_name: option.last_name,
              first_name: option.first_name,
              bvn: option.bvn,
              phone: option.phone,
              email: option.email,
              accountNum: option.accountNum,
              sex: option.sex,
              locationLink: option.locationLink,
              locationArea: option.locationArea,
              uuid: option.uuid,
              status: option.status,
              createdAt: option.createdAt,
              FarmerMonitors: option.FarmerMonitors.length,
              Agent: option.User.first_name + ' ' + option.User.last_name,
              state: option.State ? option.State.name : '',
              farmerAccountDetail: option.farmerAccountDetail,
              fcmbaccount: option.farmerAccountDetail
                ? option.farmerAccountDetail.accountNumber
                : 'none',
              address: option.address,
              bvnverified: option.bvnverified,
              city: option.city,
              dob: option.dob,
              assignATM: option.assignATM,
              middleName: option.middleName,
              motherMaidenName: option.motherMaidenName,
              occupation: option.occupation,
              salutation: option.salutation,
              streetName: option.streetName,
              state_of_origin: option.state_of_origin,
              Base64Image: option.Base64Image,
            }))
          );
        } else {
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setData([]);
      }
    );
  };
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  // Define a function to render a page of data
  const renderPage = (pageData: any) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={
              option.first_name +
              ' ' +
              option.last_name +
              ' ' +
              formatDate(option.createdAt)
            }
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <a
                      onClick={() => {
                        setTransferProps(option);
                      }}
                    >
                      View
                    </a>

                    <a onClick={() => handleEdit(option.uuid)}>Edit</a>
                    <Popconfirm
                      title="Sure to delete this user?"
                      onConfirm={() => handleDelete(option.uuid)}
                    >
                      <a>Delete</a>
                    </Popconfirm>
                  </Space>
                </>
              }
              bordered
              dataSource={[
                'Account Number: ' + option.accountNum,
                'UUID: ' + option.uuid,
                'Email: ' + option.email,
                'BVN: ' + option.bvn,
                'Phone: ' + option.phone,
                'Sex: ' + option.sex,
                'Total Activities: ' + option.FarmerMonitors,
                'Agent: ' + option.Agent,
                'State: ' + option.state,
                'Origin State: ' + option.state_of_origin,
                'FCMB Account: ' + option.fcmbaccount,
                'Card Request: ' + option.assignATM,
              ]}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  const UpdateFarmer = () => {
    editform
      .validateFields()
      .then((values) => {
        // Do something with the form values
        setLoading(true);
        values.email = values.email === null ? '' : values.email;
        UserService.UpdateRecord(values, 'farmers/' + farmerID).then(
          (response) => {
            setSubmitMessage('Update was successful');
            setSubmitTitle('Record Success');
            setSubmitBadge('success');
            setIseditModalVisible(false);
            setLoading(false);
            LoadData();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setSubmitMessage(resMessage);
            setSubmitTitle('Record failed');
            setSubmitBadge('error');
            setLoading(false);
          }
        );
      })
      .catch((error) => {});
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '10px' }}>
                {transferProps && (
                  <FarmerProfile propsForProfile={transferProps} />
                )}
                {!transferProps && (
                  <>
                    <h2 style={{ marginTop: '10px' }}>Farmer Management</h2>

                    <Space size="small" style={{ margin: '20px' }}>
                      <Button type="primary" onClick={showModal}>
                        Add Farmer
                      </Button>
                    </Space>

                      <Row style={{ display: 'flex' }}>
                        <Col span={8}>
                          <div id="range-picker-container" className="range-picker-container">
                            {showDate && (
                              <DateRange ranges={dateRange} onChange={handleDateRangeChange} />
                            )}
                            <Button type="primary" onClick={useDateHandler}>
                              {showDate ? 'Hide Date' : 'Show Date'}
                            </Button>
                          </div>
                          <Button
                            className="searchbtn"
                            loading={isLoadingContent}
                            type="primary"
                            onClick={searchRecord}
                          >
                            Search
                          </Button>
                        </Col>

                        <Col span={8}>
                          <Select className='' onChange={(value) => setSelectedAgent(value)}  options={agent} style={{minWidth:'200px'}} />
                        </Col>

                        <Col span={8}>
                          <Button onClick={exportExcel}>
                            Export Excel <FileExcelOutlined rev="icon-rev" />
                          </Button>
                        </Col>
                      </Row>;
                    <Modal
                      open={open}
                      title="Add Farmer"
                      onCancel={handleCancel}
                      footer={[
                        <Button key="back" onClick={handleCancel}>
                          Return
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => {
                            form
                              .validateFields()
                              .then((values) => {
                                submitForm(values);
                              })
                              .catch((info) => {});
                          }}
                          loading={loading}
                        >
                          Save
                        </Button>,
                      ]}
                    >
                      <Form
                        {...formItemLayout}
                        form={form}
                        name="AddUser"
                        style={{ maxWidth: 600 }}
                        scrollToFirstError
                      >
                        {submitMessage && (
                          <Alert
                            message="Error Response"
                            description={submitMessage}
                            type="error"
                            closable
                            onClose={onClose}
                          />
                        )}
                        <Form.Item
                          name="salutation"
                          label="Salutation"
                          rules={[
                            {
                              message: 'salutation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="first_name"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input Farmer Names!',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="last_name"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input Farmer SurName!',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="middleName"
                          label="other Name"
                          rules={[
                            {
                              message: 'input Middle Name !',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="dob"
                          label="Date Of Birth"
                          rules={[
                            {
                              message: 'Input Date Of Birth!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="motherMaidenName"
                          label="Mother Maiden Name"
                          rules={[
                            {
                              message: 'input Mother Maiden Name!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name="email" label="E-mail">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="phone"
                          label="Phone Number"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your phone number!',
                            },
                          ]}
                        >
                          <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name="bvn"
                          label="BVN"
                          rules={[
                            {
                              required: true,
                              message: 'Please input BVN!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="accountNum"
                          label="account NO"
                          rules={[
                            {
                              message: 'Please input accountNum!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="employmentStatus"
                          label="Employment Status"
                          rules={[
                            {
                              required: true,
                              message: 'Please input employment status!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Employment Status"
                            options={employmentData}
                          />
                        </Form.Item>
                        <Form.Item
                          name="houseNum"
                          label="House Number"
                          rules={[
                            {
                              message: 'Input House Number!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="stateID"
                          label="state"
                          rules={[
                            { required: true, message: 'Please select State!' },
                          ]}
                        >
                          <Select placeholder="select your state">
                            {state.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="maritalStatus"
                          label="Marital Status"
                          rules={[
                            {
                              message: 'Marital Status!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select options={maritalData} />
                        </Form.Item>
                        <Form.Item
                          name="occupation"
                          label="Occupation"
                          rules={[
                            {
                              message: 'Occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="streetName"
                          label="Street Name"
                          rules={[
                            {
                              message: 'Input Street Name!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="city"
                          label="City"
                          rules={[
                            {
                              message: 'Input city!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select options={cityData} />
                        </Form.Item>
                        <Form.Item
                          name="address"
                          label="Address"
                          rules={[
                            {
                              message: 'Input Address!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="status"
                          label="Status"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Status!',
                            },
                          ]}
                        >
                          <Select placeholder="select your Status">
                            <Option value="Active">Active</Option>
                            <Option value="Disable">Disable</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="sex"
                          label="SEX"
                          rules={[
                            { required: true, message: 'Please select Sex!' },
                          ]}
                        >
                          <Select placeholder="select your Gender">
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="locationLink"
                          label="Input location link"
                          rules={[
                            {
                              message: 'Occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="locationArea"
                          label="Input location Information"
                          rules={[
                            {
                              message: 'Occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Form>
                    </Modal>
                    <Modal
                      title="Edit Farmer"
                      open={iseditModalVisible}
                      onOk={UpdateFarmer}
                      onCancel={() => {
                        editform.resetFields();
                        setIseditModalVisible(false);
                      }}
                    >
                      <Form
                        form={editform}
                        name="EditFarmer"
                        style={{ maxWidth: 600 }}
                        scrollToFirstError
                      >
                        <Space>
                          {submitMessage && submitBadge === 'success' && (
                            <Alert
                              message={submitTitle}
                              description={submitMessage}
                              type="success"
                              closable
                              onClose={onClose}
                            />
                          )}
                          {submitMessage && submitBadge === 'error' && (
                            <Alert
                              message={submitTitle}
                              description={submitMessage}
                              type="error"
                              closable
                              onClose={onClose}
                            />
                          )}
                        </Space>
                        <Form.Item
                          name="salutation"
                          label="Salutation"
                          rules={[
                            {
                              message: 'salutation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="first_name"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your SureName!',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="middleName"
                          label="other Name"
                          rules={[
                            {
                              message: 'input Middle Name !',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="last_name"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input Othername!',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="accountNum"
                          label="Account Number"
                          rules={[
                            {
                              message: 'Please input your Account Number!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="bvn"
                          label="BVN"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your BVN!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item name="email" label="E-mail">
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="phone"
                          label="Phone Number"
                          rules={[
                            { message: 'Please input your phone number!' },
                          ]}
                        >
                          <Input style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                          name="sex"
                          label="Gander"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Gender!',
                            },
                          ]}
                        >
                          <Select placeholder="select your Gender">
                            <Option value="M">Male</Option>
                            <Option value="F">Female</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="dob"
                          label="Date Of Birth"
                          rules={[
                            {
                              message: 'Input Date Of Birth!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="stateID"
                          label="state"
                          rules={[
                            { required: true, message: 'Please select State!' },
                          ]}
                        >
                          <Select placeholder="select your state">
                            {state.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="status"
                          label="Status"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Status!',
                            },
                          ]}
                        >
                          <Select placeholder="select your status">
                            <Option value="Active">Active</Option>
                            <Option value="Disable">Disable</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="maritalStatus"
                          label="Marital Status"
                          rules={[
                            {
                              message: 'Marital Status!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select options={maritalData} />
                        </Form.Item>
                        <Form.Item
                          name="houseNum"
                          label="House Number"
                          rules={[
                            {
                              message: 'Input House Number!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="employmentStatus"
                          label="Employment Status"
                          rules={[
                            {
                              required: true,
                              message: 'Please input employment status!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Employment Status"
                            options={employmentData}
                          />
                        </Form.Item>
                        <Form.Item
                          name="occupation"
                          label="Occupation"
                          rules={[
                            {
                              message: 'Occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="streetName"
                          label="Street Name"
                          rules={[
                            {
                              message: 'Input Street Name!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="city"
                          label="City"
                          rules={[
                            {
                              message: 'Input city!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Select options={cityData} />
                        </Form.Item>
                        <Form.Item
                          name="address"
                          label="Address"
                          rules={[
                            {
                              message: 'Input Address!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="locationLink"
                          label="Input location link"
                          rules={[
                            {
                              message: 'Occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="locationArea"
                          label="Input location Information"
                          rules={[
                            {
                              message: 'Occupation!',
                              whitespace: false,
                            },
                          ]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Form>
                    </Modal>
                    <div style={{ margin: '20px 0' }} />
                    {deleteMSG && (
                      <Alert
                        message="Delete Farmer"
                        description={deleteMSG}
                        closable
                        onClose={onClose}
                      />
                    )}
                    <div style={{ margin: '10px 0' }} />

                    <Input
                      placeholder="Search Query..."
                      style={{ width: 250 }}
                      onChange={handleSearch}
                    />

                    <div style={{ margin: '24px 0' }} />
                    {isLoadingContent ? (
                      <ScaleLoader
                        color={'#000'}
                        loading={isLoadingContent}
                        // size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <>

                        {!isMobile && (
                          <Table
                            pagination={false}
                            dataSource={data}
                            scroll={{ x: true }}
                            className="farmerTable"
                          >
                            <Column
                              title="First Name"
                              dataIndex="first_name"
                              key="first_name"
                            />
                            <Column
                              title="Last Name"
                              dataIndex="last_name"
                              key="last_name"
                            />
                            <Column title="UUID" dataIndex="uuid" key="uuid" />
                            <Column
                              title="Phone"
                              dataIndex="phone"
                              key="phone"
                            />
                            <Column
                              title="Email"
                              dataIndex="email"
                              key="email"
                            />
                            <Column
                              title="Account Number"
                              dataIndex="accountNum"
                              key="accountNum"
                            />
                            <Column title="Sex" dataIndex="sex" key="sex" />
                            <Column title="BVN" dataIndex="bvn" key="bvn" />
                            <Column
                              title="Activities"
                              dataIndex="FarmerMonitors"
                              key="FarmerMonitors"
                            />
                            <Column
                              title="State"
                              dataIndex="state"
                              key="state"
                            />
                            <Column title="city" dataIndex="city" key="city" />
                            <Column
                              title="Agent"
                              dataIndex="Agent"
                              key="Agent"
                            />
                            <Column
                              title="Card request"
                              dataIndex="assignATM"
                              key="assignATM"
                            />
                            <Column
                              title="FCMB Account"
                              dataIndex="fcmbaccount"
                              key="fcmbaccount"
                            />
                            <Column
                              title="Created At"
                              dataIndex="createdAt"
                              key="createdAt"
                              render={(_: any, record: DataType) => (
                                <>
                                  <Tag color="blue" key="createdAt">
                                    {formatDate(record.createdAt)}
                                  </Tag>
                                </>
                              )}
                            />
                            {/* formatDate(option.createdAt) */}
                            <Column
                              title="Status"
                              dataIndex="status"
                              key="status"
                              render={(_: any, record: DataType) => (
                                <>
                                  <Tag color="blue" key="status">
                                    {record.status}
                                  </Tag>
                                </>
                              )}
                            />
                            <Column
                              title="Action"
                              key="action"
                              render={(_: any, record: DataType) => (
                                <Space size="middle">
                                  <a
                                    onClick={() => {
                                      setTransferProps(record);
                                    }}
                                  >
                                    View
                                  </a>

                                  <a onClick={() => handleEdit(record.uuid)}>
                                    Edit
                                  </a>
                                  <Popconfirm
                                    title="Sure to delete this user?"
                                    onConfirm={() => handleDelete(record.uuid)}
                                  >
                                    <a>Delete</a>
                                  </Popconfirm>
                                </Space>
                              )}
                            />
                          </Table>
                        )}

                        {isMobile && (
                          <>
                            {/* Render the current page of data */}
                            {renderPage(
                              data.slice(
                                (currentPage - 1) * pageSize,
                                currentPage * pageSize
                              )
                            )}
                          </>
                        )}
                        {/* Render pagination component */}
                        <Pagination
                          total={totalFarmer}
                          pageSize={pageSize}
                          current={currentPage}
                          onChange={handlePaginationChange}
                        />
                      </>
                    )}
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default FarmerPage;
