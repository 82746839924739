import UserService from '../../../services/user.service';
import { useParams } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Result,
  Steps,
  Row,
  Select,
  theme,
  Upload,
  message,
  Space,
} from 'antd';
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const BankingAgent: React.FC = (props) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [form_custome] = Form.useForm();
  const [personalInfoForm, setPersonalInfoForm] = useState<any>();
  const [accountInfoForm, setAccountInfoForm] = useState<any>();
  const [userBusinessInfoForm, setUserBusinessInfoForm] = useState<any>();
  const [current, setCurrent] = useState(0);
  const [bank, setBank] = useState<{ value: string; label: string }[]>([]);
  const [NGstate, setNGstate] = useState<{ value: string; label: string }[]>(
    []
  );
  const [NGlga, setNGlga] = useState<{ value: string; label: string }[]>([]);
  const [isregistered, setIsregistered] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [loadingLGA, setLoadingLGA] = useState(true);
  const [loadingBank, setLoadingBank] = useState(true);
  const [success, setSuccess] = useState<string>('');
  const [errorMsg, setError] = useState<string>('');

  const [passportfileList, setPassportFileList] = useState<any>([]);
  const [uploadIDfileList, setUuploadIDFileList] = useState<any>([]);
  const [signaturefileList, setSignatureFileList] = useState<any>([]);
  const [witnessSignaturefileList, setWitnessSignatureUuploadIDFileList] =
    useState<any>([]);
  const [linkError, setLinkError] = useState<string>('');
  const [isverifyLink, setIsVerifyLink] = useState<boolean>(true);

  const [cityData, setCity] = useState<any>([]);
  const [maritalData, setMarital] = useState<any>([]);
  const [employmentData, setEmployment] = useState<any>([]);
  const { id } = useParams();
  const FCMBUtility = async () => {
    await UserService.GetRecord('utilities/fcmb').then((result) => {
      setCity(
        result.data.cities.map((data: any) => ({
          value: data.ref_code,
          label: data.ref_desc,
        }))
      );
      setMarital(
        result.data.maritalstatus.map((data: any) => ({
          value: data.ref_code,
          label: data.ref_desc,
        }))
      );
      setEmployment(
        result.data.employment.map((data: any) => ({
          value: data.value,
          label: data.localetext,
        }))
      );
    });
  };
  const verifyLink = async () => {
    setIsVerifyLink(true);
    await UserService.GetRecord('banking/agent/verify-link/' + id).then(
      (result) => {
        form.setFieldsValue({
          houseNum: result.data.data.User.houseNum,
          streetName: result.data.data.User.streetName,
          city: result.data.data.User.city,
          address: result.data.data.User.address,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLinkError(resMessage);
      }
    );
    setIsVerifyLink(false);
  };
  const lga = (id: number) => {
    setLoadingLGA(true);
    UserService.GetRecord('banking/lgas/' + id).then((result) => {
      if (result.data) {
        setLoadingLGA(false);
        setNGlga(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  };

  const onFinish = (values: any) => {
    setIsSubmitting(true);
    const formData = new FormData();
    const witnessFormData = form.getFieldsValue();
    const personalInfoFormData = personalInfoForm;
    const accountInfoFormData = accountInfoForm;
    const businessInfoFormData = userBusinessInfoForm;
    const linkcode = { linkCode: id };
    const combinedFormData = {
      ...witnessFormData,
      ...personalInfoFormData,
      ...accountInfoFormData,
      ...businessInfoFormData,
      ...linkcode,
    };

    for (const key in combinedFormData) {
      if (combinedFormData.hasOwnProperty(key)) {
        const value = combinedFormData[key];
        if (typeof value !== 'object' && value !== null) {
          formData.append(key, value);
        }
      }
    }
    if (passportfileList.length > 0) {
      passportfileList.forEach((file: any) => {
        formData.append('passport', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (witnessSignaturefileList.length > 0) {
      witnessSignaturefileList.forEach((file: any) => {
        formData.append('witnessSignature', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (uploadIDfileList.length > 0) {
      uploadIDfileList.forEach((file: any) => {
        formData.append('uploadID', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (signaturefileList.length > 0) {
      signaturefileList.forEach((file: any) => {
        formData.append('Signature', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    if (typeof combinedFormData.bank != 'undefined') {
      combinedFormData.bank.map((banks: any) => {
        formData.append('banks', banks.bank);
        formData.append('accountNo', banks.accountNo);
      });
    }

    UserService.PostRecord(formData, 'banking/agent/register').then(
      (result) => {
        setIsregistered(true);
        setSuccess(result.data.message);
        setError('');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
        setIsSubmitting(false);
      }
    );
  };
  useEffect(() => {
    if (success) {
      message.success(success);
    }
  }, [success]);
  useEffect(() => {
    if (errorMsg) {
      message.error(errorMsg);
    }
  }, [errorMsg]);
  const next = () => {
    form.validateFields().then((values) => {
      const forms = form.getFieldsValue();
      console.log(forms);

      if (current === 0) {
        setPersonalInfoForm(forms);
      } else if (current === 1) {
        setAccountInfoForm(forms);
      } else if (current === 2) {
        setUserBusinessInfoForm(forms);
      }
      setCurrent(current + 1);
    });
  };
  useEffect(() => {
    verifyLink();
    FCMBUtility();
  }, []);
  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'start',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  useEffect(() => {
    UserService.GetRecord('banking/banks').then((result) => {
      setLoadingBank(false);
      if (result.data) {
        setBank(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
    UserService.GetRecord('banking/states').then((result) => {
      setLoadingState(false);
      if (result.data) {
        setNGstate(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  }, []);

  const steps = [
    {
      key: 'personal',
      title: 'Personal Information',
      content: [
        <div className="RegCenter">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="salutation"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Title!',
                    whitespace: false,
                  },
                ]}
              >
                <Select placeholder="Select Title">
                  <Option value="Mr">Mr</Option>
                  <Option value="Mrs">Mrs</Option>
                  <Option value="Miss">Miss</Option>
                  <Option value="MS">MS</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your first name!',
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your last name!',
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="other_name"
                label="Other Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your other name!',
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 15 }}
                wrapperCol={{ span: 30 }}
                name="motherMaidenName"
                label="Mother's Maiden Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your mother maiden name!',
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="phone"
                label="Phone"
                rules={[
                  {
                    max: 13,
                    min: 11,
                    required: true,
                    message: 'Please input your phone number!',
                    whitespace: false,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="dob"
                label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: 'Please input your date of birth!',
                    whitespace: false,
                  },
                ]}
              >
                <Input type="date" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="maritalStatus"
                label="Marital Status"
                rules={[
                  { required: true, message: 'Please select marital status!' },
                ]}
              >
                <Select
                  placeholder="select tell us your marital status"
                  options={maritalData}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Please select gender!' }]}
              >
                <Select placeholder="select your gender">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="occupation"
                label="Occupation "
                rules={[
                  {
                    required: true,
                    message: 'Please input your occupation!',
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="employmentStatus"
                label="Employment Status"
                rules={[
                  {
                    required: true,
                    message: 'Please input employment status!',
                    whitespace: false,
                  },
                ]}
              >
                <Select
                  placeholder="Select Employment Status"
                  options={employmentData}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="houseNum"
                label="House Number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your house number!',
                    whitespace: false,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="streetName"
                label="Street Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your street name!',
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="city"
                label="City Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your city name!',
                    whitespace: false,
                  },
                ]}
              >
                <Select options={cityData} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: 'Please input your address!',
                    whitespace: false,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </div>,
      ],
    },
    {
      key: 'information',
      title: 'Account Information',
      content: [
        <div className="RegCenter">
          <div>
            {/* <Space key='kyc' align="center"> */}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 20 }}
                  name="bvnNumber"
                  label="BVN"
                  rules={[
                    {
                      max: 11,
                      min: 11,
                      required: true,
                      message: 'Please input your BVN number!',
                      whitespace: false,
                    },
                  ]}
                >
                  <Input maxLength={11} type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 20 }}
                  name="ninNumber"
                  label="NIN"
                  rules={[
                    {
                      max: 11,
                      min: 11,
                      required: true,
                      message: 'Please input your NIN number!',
                      whitespace: false,
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
            {/* </Space> */}

            <Form.List name="bank">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    // <Space key={field.key}  align="center">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.area !== curValues.area ||
                            prevValues.sights !== curValues.sights
                          }
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              label="Bank"
                              name={[field.name, 'bank']}
                              rules={[
                                { required: true, message: 'Missing Bank' },
                              ]}
                            >
                              <Select
                                placeholder="Select Bank"
                                loading={loadingBank}
                              >
                                {bank.map((option) => (
                                  <Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={19} sm={10} md={10} lg={10} xl={10}>
                        <Form.Item
                          {...field}
                          label="Account Number"
                          name={[field.name, 'accountNo']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing Account Number',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={5} sm={2} md={2} lg={2} xl={2}>
                        <MinusCircleOutlined
                          rev="icon-rev"
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    </Row>
                    // </Space>
                  ))}

                  <Space
                    key={'account'}
                    align="center"
                    style={{ alignContent: 'center' }}
                  >
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined rev="icon-rev" />}
                    >
                      Add Bank Account Information
                    </Button>
                  </Space>
                </>
              )}
            </Form.List>
          </div>
        </div>,
      ],
    },
    {
      key: 'business',
      title: 'Busness Information',
      content: [
        <div className="RegCenter">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="stateID"
                label="Business State"
              >
                <Select
                  placeholder="Select Business State"
                  loading={loadingState}
                  onChange={lga}
                >
                  {NGstate.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="lga"
                label="LGA"
              >
                <Select
                  placeholder="Select LGA"
                  loading={loadingLGA}
                  disabled={loadingLGA}
                >
                  {NGlga.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="modeOfIdentification"
                label="Mode Of Identification"
              >
                <Select placeholder="Select Mode Of Identification">
                  <Option key="Passport" value="Passport">
                    Passport
                  </Option>
                  <Option key="NationalI_D_Card" value="National ID Card">
                    National ID Card
                  </Option>
                  <Option key="Driver_License" value="Driver's License">
                    Driver's License
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="IDnumber"
                label="ID Number"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="uploadID"
                label="Upload ID"
              >
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(info: any) => setUuploadIDFileList(info.fileList)}
                >
                  <Button icon={<UploadOutlined rev="icon-rev" />}>
                    Select File
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="passport"
                label="Passport Photograph"
              >
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(info: any) => setPassportFileList(info.fileList)}
                >
                  <Button icon={<UploadOutlined rev="icon-rev" />}>
                    Select File
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="bussinessName"
                label="Bussiness Name"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="registrationNumber"
                label="Registration Number"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="position"
                label="Position"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="BusinessType"
                label="Business Type"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="BusinessAddress"
                label="Business Address"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="BusinessArea"
                label="Business Area"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="YearsInBusiness"
                label="Years In Business"
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="dateIncoporation"
                label="Date Incorporation"
              >
                <Input type="date" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 20 }}
                name="Signature"
                label="Upload Signature"
              >
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(info: any) => setSignatureFileList(info.fileList)}
                >
                  <Button icon={<UploadOutlined rev="icon-rev" />}>
                    Select File
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </div>,
      ],
    },
    {
      key: 'wittness',
      title: 'Wittness Information',
      content: [
        <div className="RegCenter">
          {!isregistered ? (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 20 }}
                    name="witnessFirst_name"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Firstname!',
                        whitespace: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 20 }}
                    name="witnessLast_name"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Lastname!',
                        whitespace: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 20 }}
                    name="witnessOther_name"
                    label="Other Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Othername!',
                        whitespace: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 20 }}
                    name="witnessSignature"
                    label="Witness Signature"
                  >
                    <Upload
                      name="files"
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(info: any) =>
                        setWitnessSignatureUuploadIDFileList(info.fileList)
                      }
                    >
                      <Button>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              {errorMsg != '' && <Result status="error" title={errorMsg} />}
            </>
          ) : (
            <>
              <Result
                status="success"
                title="Registration Successful"
                subTitle="Thank you for registering."
              />
            </>
          )}
        </div>,
      ],
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  if (isverifyLink) {
    return (
      <ScaleLoader
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          margin: '0 auto',
        }}
        color={'#000'}
        loading={isverifyLink}
        // size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    );
  }

  if (linkError) {
    return (
      <div style={{ backgroundColor: '#fff', height: '100%' }}>
        {' '}
        <Result
          status="error"
          title={linkError}
          subTitle="Contact your agent for valide link"
        />{' '}
      </div>
    );
  }
  return (
    <div className="bg-light">
      <div className="login-form-wrapperx">
        <>
          <Form
            {...formItemLayout}
            className="centerdiv"
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
          >
            <h2>Register to be an agent</h2>
            <>
              <Steps
                current={current}
                items={items}
                style={{ justifyContent: 'flex-start' }}
              />
              <div style={contentStyle}>{steps[current].content}</div>
              <div style={{ marginTop: 24 }}>
                {current > 0 && (
                  <Button
                    style={{ margin: '0 8px' }}
                    onClick={() => prev()}
                    disabled={isregistered || isSubmiting}
                  >
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => next()}
                    disabled={isregistered}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isregistered || isSubmiting}
                    loading={isSubmiting}
                  >
                    Done
                  </Button>
                )}
              </div>
            </>
          </Form>
        </>
      </div>
    </div>
  );
};

export default BankingAgent;
