import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Layout,
  Card,
  Skeleton,
  Alert,
  message,
  Col,
  Row,
  Modal,
  Popconfirm,
  Space,
  ColorPicker,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import type { Color, ColorPickerProps } from 'antd/es/color-picker';

import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
const { Content } = Layout;
const { TextArea } = Input;
const { Search } = Input;

interface Activity {
  id: number;
  name: string;
  color?: string;
  description?: string;
}

const ActivityVariableComponent = () => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activityName, setActivityName] = useState('');
  const [colorName, setColorName] = useState('');
  const [activityDescription, setActivityDescription] = useState('');
  const [activitys, setActivitys] = useState<Activity[]>([]);
  const [activitysData, setActivitysData] = useState<Activity[]>([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [editActivityId, setEditActivityId] = useState<number | null>(null);
  const [formatRgb, setFormatRgb] = useState<ColorPickerProps['format']>('rgb');

  useEffect(() => {
    fetchActivitys();
  }, []);
  const pickColor = (ColorData: any) => {
    let { r, g, b } = ColorData.metaColor;
    const a = 1;
    setColorName(`rgba(${r.toFixed()},${g.toFixed()},${b.toFixed()},${a})`);
  };
  const fetchActivitys = () => {
    setLoading(true);
    UserService.GetRecord('calender/activity').then(
      (response: any) => {
        setActivitys(response.data.data);
        setActivitysData(response.data.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setLoading(false);
      }
    );
  };

  const handleSaveActivity = () => {
    setIsSubmitting(true);
    setModal2Open(false);
    if (activityName.trim() === '') {
      return;
    }

    if (editActivityId !== null) {
      // Edit existing activity
      UserService.UpdateRecord(
        {
          name: activityName,
          description: activityDescription,
          color: colorName,
        },
        'calender/activity/' + editActivityId
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
          const updatedActivitys = activitys.map((activity) =>
            activity.id === editActivityId
              ? {
                  ...activity,
                  name: activityName,
                  description: activityDescription,
                  color: colorName,
                }
              : activity
          );
          setActivitys(updatedActivitys);
          setEditActivityId(null);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    } else {
      UserService.PostRecord(
        {
          name: activityName,
          description: activityDescription,
          color: colorName,
        },
        'calender/activity'
      ).then(
        (result) => {
          setIsSubmitting(false);
          message.success(result.data.message);
          const newActivity: Activity = {
            id: Date.now(),
            name: activityName,
            color: colorName,
            description: activityDescription,
          };
          setActivitys([...activitys, newActivity]);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsSubmitting(false);
          message.error(resMessage);
        }
      );
    }

    setActivityName('');
    setActivityDescription('');
  };

  const handleEditActivity = (activity: any) => {
    setEditActivityId(activity.id);
    setActivityName(activity.name);
    setActivityDescription(activity.description);
    setColorName(activity.color);
    setModal2Open(true);
  };

  const handleDeleteActivity = (activityId: number) => {
    UserService.DeleteRecord('calender/activity/' + activityId).then(
      (response) => {
        const updatedActivitys = activitys.filter(
          (activity) => activity.id !== activityId
        );
        setActivitys(updatedActivitys);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };

  const onSearch = (value: string) => {
    // Method 2: Using for...of loop
    const filteredItems = activitysData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setActivitys(filteredItems);
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Calender Activity Management</h1>

            <div className="m-5">
              <Button
                type="primary"
                onClick={() => {
                  setModal2Open(true);
                  setEditActivityId(null);
                  setActivityName('');
                  setActivityDescription('');
                }}
              >
                Add Activity
              </Button>
            </div>
            <div className="m-5">
              <Space direction="vertical">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Space>
            </div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body">
              <Row gutter={[16, 16]}>
                {activitys.length > 0 ? (
                  activitys.map((activity) => (
                    <Col
                      key={activity.id}
                      span={8}
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      <div className="card">
                        <div className="card-content">
                          <h2 className="card-title">{activity.name}</h2>
                          <p className="card-description">
                            {activity.description}
                          </p>
                        </div>
                        <div className="card-buttons">
                          <Button
                            type="text"
                            className="edit-button"
                            onClick={() => {
                              handleEditActivity(activity);
                            }}
                          >
                            <EditOutlined rev="rev-icon" />
                          </Button>
                          <Popconfirm
                            title="Delete the activity"
                            description="Are you sure to delete this activity?"
                            icon={
                              <QuestionCircleOutlined
                                rev="rev-icon"
                                style={{ color: 'red' }}
                              />
                            }
                            onConfirm={() => {
                              handleDeleteActivity(activity.id);
                            }}
                          >
                            <Button type="text" className="delete-button">
                              <DeleteOutlined rev="rev-icon" />
                            </Button>
                          </Popconfirm>
                        </div>
                        <Skeleton loading={loading} avatar active></Skeleton>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div className="m-5">
                    <Alert message="Data not found!" showIcon type="error" />
                  </div>
                )}
              </Row>
            </div>
            <Modal
              title="Add Activity"
              centered
              open={modal2Open}
              footer={[
                <Button
                  loading={isSubmitting}
                  key="save"
                  onClick={handleSaveActivity}
                >
                  {editActivityId ? 'Update' : 'Save'}
                </Button>,
                <Button key="cancel" onClick={() => setModal2Open(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => setModal2Open(false)}
            >
              <Input
                placeholder="Activity Name"
                value={activityName}
                onChange={(e) => setActivityName(e.target.value)}
              />
              <div style={{ marginBottom: '10px' }}></div>
              <Space direction="vertical">
                <ColorPicker
                  format={formatRgb}
                  onFormatChange={setFormatRgb}
                  size="large"
                  showText
                  value={colorName}
                  defaultValue={colorName}
                  onChange={(colorObj: any) => pickColor(colorObj)}
                />
              </Space>
              <div style={{ marginBottom: '10px' }}></div>

              <TextArea
                placeholder="Activity Description"
                value={activityDescription}
                rows={4}
                onChange={(e) => setActivityDescription(e.target.value)}
              />
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default ActivityVariableComponent;
