export default function authHeader() {
  interface User {
    accessToken: string;
  }
  let user: User = { accessToken: '' };
  const jsonAuth = localStorage.getItem('user');
  if (jsonAuth) {
    user = JSON.parse(jsonAuth);
    return { 'x-access-token': user.accessToken };
  } else {
    return {};
  }
}
