import React, { useState, useEffect, Suspense } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import AuthService from '../../services/auth.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
const LoginForm: React.FC = (props) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [PageLoaded, setPageLoaded] = useState(false);
  const onFinish = (values: any) => {
    setLoading(true);
    AuthService.login(values).then(
      (response) => {
        window.location.href = '/';
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  // Use cumputedProgress as a dependency
  useEffect(() => setPageLoaded(true), []);
  return (
    <Suspense
      fallback={
        <ScaleLoader
          color={'#fff'}
          loading={!PageLoaded}
          // size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      }
    >
      <div className="login-form-wrapper ">
        {PageLoaded ? (
          <Form
            name="normal_login"
            className="login-form m-5"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {message && (
              <>
                <Alert
                  message={message}
                  description="Authentication Error"
                  type="error"
                  closable
                />
              </>
            )}
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input
                prefix={
                  <UserOutlined
                    rev="icon-rev"
                    className="site-form-item-icon"
                  />
                }
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input
                prefix={
                  <LockOutlined
                    rev="icon-rev"
                    className="site-form-item-icon"
                  />
                }
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </div>
    </Suspense>
  );
};

export default LoginForm;
