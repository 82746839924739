import {
  Button,
  Popconfirm,
  Form,
  Input,
  Alert,
  Space,
  Table,
  Select,
  Modal,
  Radio,
  Row,
  Col,
  List,
  Upload,
  notification,
  Collapse,
  theme,
  Pagination,
  Layout,
  Divider,
} from 'antd';
import { UploadOutlined, DownCircleFilled } from '@ant-design/icons';
import { formatDate } from '../../../common/utility';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../costom-css/monitor.css';
import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import type { UploadFile } from 'antd/es/upload/interface';
import type { UploadProps } from 'antd/es/upload';
import RoleHooks from '../../../hooks/verifyRole';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
const { Content } = Layout;
const { permissionsData } = RoleHooks();
const { Option } = Select;
const { Column } = Table;
const { Panel } = Collapse;
interface DataType {
  key: React.Key;
  name: string;
  accountNum: string;
  uuid: string;
  activity: string;
  croptype: string;
  gradeid: string;
  responsiveness: string;
  activityCycle: string;
  approach: string;
  createdAt?: string;
  Creator?: string;
}
const API_URL = `${process.env.REACT_APP_API_END_POINT}`;

interface Option {
  value: string;
  label: string;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const MonitorPage: React.FC = () => {
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [Dataloading, setDataLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [verifiedAccountNum, setVerifiedAccountNum] = useState('');
  const [verifyMessage, setVerifyMessage] = useState('');
  const [verifyMessageTitle, setVerifyMessageTitle] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitTitle, setSubmitTitle] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [data, setData] = useState<DataType[]>([]);
  const [activities, setActivities] = useState<Option[]>([]);
  const [croptype, setCropType] = useState<Option[]>([]);
  const [score, setScore] = useState<Option[]>([]);
  const [showNotification, setShowNotification] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [fileList, setFileList] = useState<any>([]);
  const [accountBVN, setAccountBVN] = useState('');
  const [total, setTotal] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editID, setEditID] = useState<number>();
  const [iseditModalVisible, setIseditModalVisible] = useState(false);
  const [editfileList, setEditFileList] = useState<UploadFile[]>([]);
  const [isLoadingContent, SetisLoadingContent] = useState(true);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [showDate, SetshowDate] = useState(false);
  const [selectBatch, setSelectBatch] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const pageSize: number = 10;

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length >= 6) {
      UserService.GetRecord('farmers/account-no/' + e.target.value).then(
        (response) => {
          setVerifiedAccountNum('success');
          setVerifyMessage(
            'Farmer: ' +
              response.data.data.last_name +
              ' ' +
              response.data.data.first_name
          );
          setVerifyMessageTitle(
            'Agent: ' +
              response.data.data.User.first_name +
              ' ' +
              response.data.data.User.last_name
          );
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setVerifiedAccountNum('error');
          setVerifyMessage(resMessage);
        }
      );
    } else {
      setVerifiedAccountNum('');
    }
  };
  const showModal = () => {
    setOpen(true);
  };
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const LoadData = () => {
    SetisLoadingContent(true);

    UserService.GetRecord('monitor/farmers').then(
      (result) => {
        if (result.data) {
          setTotal(result.data.data.length);
          setData(
            result.data.data.map((option: any) => ({
              key: option.id,
              name: option.Farmer.last_name + ' ' + option.Farmer.first_name,
              accountNum: option.Farmer.accountNum,
              uuid: option.Farmer.uuid,
              activity: option.Activity.name,
              croptype: option.CropType.name,
              gradeid: option.gradeID,
              responsiveness: option.farmerResponsivenessID,
              activityCycle: option.activityCycleID,
              approach: option.generalApproachID,
              createdAt: formatDate(option.createdAt),
              Creator:
                option.Creator.first_name + ' ' + option.Creator.last_name,
            }))
          );
          SetisLoadingContent(false);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setData([]);
        SetisLoadingContent(false);
      }
    );
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
    setPageLoaded(true);

    UserService.GetRecord('utilities').then((result) => {
      setActivities(
        result.data.activity.map((option: any) => ({
          value: option.id,
          label: option.name,
        }))
      );
      setCropType(
        result.data.croptype.map((option: any) => ({
          value: option.id,
          label: option.name,
        }))
      );
      setScore(
        result.data.score.map((option: any) => ({
          value: option.level,
          label: option.name,
        }))
      );
      if (showNotification) {
        notification.success({
          message: submitTitle,
          description: submitMessage,
        });
      }
    });
    LoadData();
  }, []);
  const handleCancel = () => {
    setOpen(false);
    setIseditModalVisible(false);
  };
  const handleDelete = (key: any) => {
    UserService.DeleteRecord('monitor/farmers/' + key).then(
      (response) => {
        LoadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };
  const onFinish = (values: any) => {
    const formData = new FormData();
    setLoading(true);
    setLoading(false);
    formData.append('generalApproachID', values.generalApproachID);
    formData.append('uuid', values.accountBVN);
    formData.append('activityID', values.activityID);
    formData.append('croptypeID', values.croptypeID);
    formData.append('gradeID', values.gradeID);
    formData.append('farmerResponsivenessID', values.farmerResponsivenessID);
    formData.append('activityCycleID', values.activityCycleID);
    if (values.backdate && !!permissionsData.includes('back-date-monitor')) {
      formData.append('backDate', values.backdate);
    }

    if (fileList.length > 0) {
      fileList.forEach((file: any) => {
        formData.append('files', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }

    UserService.PostRecord(formData, 'monitor/farmers').then(
      (response) => {
        form.resetFields();
        setFileList([]);
        LoadData();
        setLoading(false);
        setOpen(false);
        setSubmitMessage('Submission was successful');
        setSubmitTitle('Record Success');
        setSubmitBadge('success');
        setShowNotification(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setSubmitMessage(resMessage);
        setSubmitTitle('Record failed');
        setSubmitBadge('error');
        setShowNotification(true);
      }
    );
  };

  const beforeUpload = (file: any) => {
    // Perform any necessary processing or validation on the file here
    // If the file is valid, return true to allow the upload to proceed
    // If the file is invalid, return false to prevent the upload
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg';
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      setSubmitMessage('You can only upload JPG/PNG/SVG file!');
      setSubmitTitle('Image Validation');
      setSubmitBadge('error');
      setCanSubmit(false);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      setSubmitMessage('Image must smaller than 2MB!');
      setSubmitTitle('Image Validation');
      setSubmitBadge('error');
      setCanSubmit(false);

      return false;
    }
    setCanSubmit(true);
    let fileList = [...file.fileList];
    fileList = fileList.slice(-3); // Limit the number of uploaded files to 3
    fileList = fileList.map((file) => ({
      ...file,
      status: 'done',
      // url: file.response?.url, // Add the URL of the uploaded file to the file object
    }));
    setFileList(fileList);
    return false;
  };
  const EditbeforeUpload: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    // Perform any necessary processing or validation on the file here
    // If the file is valid, return true to allow the upload to proceed
    // If the file is invalid, return false to prevent the upload
    try {
      newFileList.map((file) => {
        const isJpgOrPng =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/svg';
        if (!isJpgOrPng) {
          // message.error('You can only upload JPG/PNG file!');
          setSubmitMessage('You can only upload JPG/PNG/SVG file!');
          setSubmitTitle('Image Validation');
          setSubmitBadge('error');
          setEditFileList([]);
          // setCanUpdate(false);
          return false;
        }
        if (file.size) {
          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            setSubmitMessage('Image must smaller than 5MB!');
            setSubmitTitle('Image Validation');
            setSubmitBadge('error');
            setEditFileList([]);
            return false;
          }
        }
        const metaData = new FormData();
        let cols: any = [];
        if (file.originFileObj) {
          metaData.append('file', file.originFileObj, file.name); // Append each file individually to the FormData object
          UserService.UpdateRecord(metaData, 'monitor/uploads/' + editID).then(
            (response: any) => {
              if (response.data.data.Uploads.length > 0) {
                for (let i = 0; i < response.data.data.Uploads.length; i++) {
                  cols.push({
                    uid: response.data.data.Uploads[i].id,
                    name: response.data.data.Uploads[i].objectKey,
                    status: 'done',
                    url:
                      API_URL +
                      'monitor/upload?searchkey=' +
                      response.data.data.Uploads[i].objectKey,
                  });
                }
                cols.push({
                  uid: file.uid,
                  name: file.name,
                  status: 'done',
                  percent: 100,
                });
              }
              setEditFileList(cols);
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
        // return false
      });

      // setCanUpdate(true);
      return false;
    } catch (error) {}
  };

  const handleEdit = (key: any) => {
    editform.resetFields();
    setEditID(key);
    UserService.GetRecord('monitor/farmers/' + key).then((result) => {
      if (result.data) {
        editform.setFieldsValue({
          activityID: result.data.data.activityID,
          croptypeID: result.data.data.croptypeID,
          gradeID: result.data.data.gradeID,
          farmerResponsivenessID: result.data.data.farmerResponsivenessID,
          activityCycleID: result.data.data.activityCycleID,
          generalApproachID: result.data.data.generalApproachID,
        });
        let cols: any = [];
        const datas = result.data.data;
        if (datas.Uploads.length > 0) {
          for (let i = 0; i < datas.Uploads.length; i++) {
            cols.push({
              uid: datas.Uploads[i].id,
              name: datas.Uploads[i].objectKey,
              status: 'done',
              url:
                API_URL +
                'monitor/upload?searchkey=' +
                datas.Uploads[i].objectKey,
            });
          }
        }
        setEditFileList(cols);
        setIseditModalVisible(true);
      }
    });
  };
  const handleAccountnoChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setAccountBVN(e.target.value);
  };
  const handleDateRangeChange = (ranges: any) => {
    setDateRange([ranges.selection]);
  };
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.resetFields();
  };
  const searchData = () => {
    SetisLoadingContent(true);
    const formattedStartDate = showDate
      ? format(dateRange[0].startDate, 'yyyy-MM-dd')
      : '';
    const formattedEndDate = showDate
      ? format(dateRange[0].endDate, 'yyyy-MM-dd')
      : '';
    UserService.GetRecord(
      'monitor/chart?page=monitor&accountBVN=' +
        accountBVN +
        '&from=' +
        formattedStartDate +
        '&to=' +
        formattedEndDate
    ).then(
      (result) => {
        setTotal(result.data.data.length);
        setData(
          result.data.data.map((option: any) => ({
            key: option.id,
            name: option.Farmer.last_name + ' ' + option.Farmer.first_name,
            accountNum: option.Farmer.accountNum,
            uuid: option.Farmer.uuid,
            activity: option.Activity.name,
            croptype: option.CropType.name,
            gradeid: option.gradeID,
            responsiveness: option.farmerResponsivenessID,
            activityCycle: option.activityCycleID,
            approach: option.generalApproachID,
            createdAt: formatDate(option.createdAt),
            Creator: option.Creator.first_name + ' ' + option.Creator.last_name,
          }))
        );
        SetisLoadingContent(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setTotal(0);
        setData([]);
        SetisLoadingContent(false);
      }
    );
  };
  const useDateHandler = () => {
    SetshowDate(!showDate);
  };
  // Define a function to render a page of data
  const renderPage = (pageData: any) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={option.name + ' ' + option.createdAt}
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <a onClick={() => handleEdit(option.key)}>Edit</a>
                    <Popconfirm
                      title="Sure to delete this user?"
                      onConfirm={() => handleDelete(option.key)}
                    >
                      <a>Delete</a>
                    </Popconfirm>
                  </Space>
                </>
              }
              bordered
              dataSource={[
                'UUID: ' + option.uuid,
                'Name: ' + option.name,
                'Activity: ' + option.activity,
                'CropType: ' + option.croptype,
                'Grade: ' + option.gradeid,
                'Responsiveness: ' + option.responsiveness,
                'Activity Cycle: ' +
                  option.activityCycle +
                  'Approach: ' +
                  option.approach,
                'Created By: ' + option.Creator,
              ]}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  //to update monitor records

  const UpdateMonitor = () => {
    if (!canUpdate) return false;
    editform
      .validateFields()
      .then((values) => {
        // Do something with the form values
        setLoading(true);
        UserService.UpdateRecord(values, 'monitor/farmers/' + editID).then(
          (response) => {
            setSubmitMessage('Submission was successful');
            setSubmitTitle('Record Success');
            setSubmitBadge('success');
            form.resetFields();
            setIseditModalVisible(false);
            LoadData();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setSubmitMessage(resMessage);
            setSubmitTitle('Record failed');
            setSubmitBadge('error');
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const uploadButton = (
    <div>
      <PlusOutlined rev="icon-rev" />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const removeIMG = (value: any) => {
    UserService.DeleteRecord('monitor/upload/' + value.uid).then(
      (response) => {
        const index = editfileList.indexOf(value);
        const newFileList = editfileList.slice();
        newFileList.splice(index, 1);
        setEditFileList(newFileList);
        return true;
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return false;
      }
    );
  };
  const handleUpload = async (file: any) => {
    // handle upload logic here
    try {
      const isCSV = file.file.type === 'text/csv';
      if (!isCSV) {
        setSubmitMessage('You can only upload CSV file!');
        setSubmitTitle('CSV Validation');
        setSubmitBadge('error');
        return false;
      }
      const formData = new FormData();
      formData.append('file', file.file, file.file.name); // Append each file individually to the FormData object
      SetisLoadingContent(true);
      UserService.PostRecord(formData, 'monitor/batch/upload').then(
        (response: any) => {
          setSubmitMessage(response.data.message);
          setSubmitTitle('Activity');
          SetisLoadingContent(false);
          LoadData();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setSubmitMessage(resMessage);
          setSubmitTitle('Activity');
          SetisLoadingContent(false);
        }
      );
    } catch (error) {}
  };
  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                {/* setSelectBatch */}
                <a
                  type="primary"
                  style={{
                    backgroundColor: '#000',
                    color: '#fff',
                    margin: '10px',
                    padding: '10px',
                  }}
                  onClick={() => {
                    setSelectBatch(!selectBatch);
                  }}
                >
                  {selectBatch
                    ? 'Click to View Upload Batch'
                    : 'Click to View Single Upload'}
                </a>
                <Divider>
                  {selectBatch ? (
                    <>
                      <Upload
                        beforeUpload={() => {
                          return false;
                        }}
                        onChange={handleUpload}
                      >
                        <Button icon={<UploadOutlined rev="icon-rev" />}>
                          Click to Upload CSV
                        </Button>
                      </Upload>
                      <br></br>

                      <a
                        href="/Sample/activityTest.csv"
                        download="activityTest.csv"
                      >
                        <Button icon={<DownCircleFilled rev="icon-rev" />}>
                          Download Demo Record
                        </Button>
                      </a>
                    </>
                  ) : (
                    <Button onClick={showModal}>
                      Click to Upload Single Form
                    </Button>
                  )}
                </Divider>
                {submitMessage != '' ? (
                  <Alert
                    message={submitTitle}
                    description={submitMessage}
                    closable
                    onClose={onClose}
                  />
                ) : (
                  ''
                )}
                <Modal
                  open={open}
                  title="Add Monitor Record"
                  onCancel={handleCancel}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                  ]}
                >
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="AddRecord"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                    scrollToFirstError
                  >
                    <Space>
                      {submitMessage && submitBadge === 'success' && (
                        <Alert
                          message={submitTitle}
                          description={submitMessage}
                          type="success"
                          closable
                          onClose={onClose}
                        />
                      )}
                      {submitMessage && submitBadge === 'error' && (
                        <Alert
                          message={submitTitle}
                          description={submitMessage}
                          type="error"
                          closable
                          onClose={onClose}
                        />
                      )}
                    </Space>
                    <Form.Item name="accountBVN" label="Farmer Account BVN">
                      <Input onChange={handleInputChange} />
                    </Form.Item>
                    <Form.Item label="Response: ">
                      {verifiedAccountNum === 'success' ? (
                        <>
                          <Alert message={verifyMessage} type="success" />
                          <Alert
                            style={{ marginTop: 5 }}
                            message={verifyMessageTitle}
                            type="success"
                          />
                        </>
                      ) : null}
                      {verifiedAccountNum === 'error' ? (
                        <Alert message={verifyMessage} type="error" />
                      ) : null}
                    </Form.Item>
                    {verifiedAccountNum === 'success' ? (
                      <>
                        {permissionsData.includes('back-date-monitor') && (
                          <Form.Item name="backdate" label="Set Date">
                            <Input />
                          </Form.Item>
                        )}
                        <Form.Item
                          name="activityID"
                          label="Activity"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Activity!',
                            },
                          ]}
                        >
                          <Select placeholder="select Activity">
                            {activities.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="croptypeID"
                          label="Crop Type"
                          rules={[
                            {
                              required: true,
                              message: 'Please select CropType!',
                            },
                          ]}
                        >
                          <Select placeholder="select your CropType">
                            {croptype.map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Grade"
                          name="gradeID"
                          className="collection-create-form_last-form-item"
                        >
                          <Radio.Group style={{ width: '100%' }}>
                            <Row>
                              {score.map((option) => (
                                <Col key={option.value}>
                                  <Radio value={option.value}>
                                    {option.label}
                                  </Radio>
                                </Col>
                              ))}
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          label="Farmers Responsiveness"
                          name="farmerResponsivenessID"
                          className="collection-create-form_last-form-item"
                        >
                          <Radio.Group style={{ width: '100%' }}>
                            <Row>
                              {score.map((option) => (
                                <Col key={option.value}>
                                  <Radio value={option.value}>
                                    {option.label}
                                  </Radio>
                                </Col>
                              ))}
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          label="Activity Per Cycle"
                          name="activityCycleID"
                          className="collection-create-form_last-form-item"
                        >
                          <Radio.Group style={{ width: '100%' }}>
                            <Row>
                              {score.map((option) => (
                                <Col key={option.value}>
                                  <Radio value={option.value}>
                                    {option.label}
                                  </Radio>
                                </Col>
                              ))}
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          label="General Approach"
                          name="generalApproachID"
                          className="collection-create-form_last-form-item"
                        >
                          <Radio.Group style={{ width: '100%' }}>
                            <Row>
                              {score.map((option) => (
                                <Col key={option.value} span={8}>
                                  <Radio value={option.value}>
                                    {option.label}
                                  </Radio>
                                </Col>
                              ))}
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name="image" label="Image">
                          <Upload
                            name="files"
                            beforeUpload={beforeUpload}
                            fileList={fileList}
                            multiple
                            onChange={(info: any) => setFileList(info.fileList)}
                          >
                            <Button>Click to Upload</Button>
                          </Upload>
                        </Form.Item>
                      </>
                    ) : null}
                    <Form.Item
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {verifiedAccountNum === 'success' ? (
                        <Button
                          type="primary"
                          loading={loading}
                          htmlType="submit"
                          disabled={!canSubmit}
                        >
                          Submit
                        </Button>
                      ) : null}
                    </Form.Item>
                  </Form>
                </Modal>
                <Modal
                  title="Edit Monitor Record"
                  open={iseditModalVisible}
                  onOk={UpdateMonitor}
                  onCancel={handleCancel}
                >
                  <Form form={editform}>
                    <Space>
                      {submitMessage && submitBadge === 'success' && (
                        <Alert
                          message={submitTitle}
                          description={submitMessage}
                          type="success"
                          closable
                          onClose={onClose}
                        />
                      )}
                      {submitMessage && submitBadge === 'error' && (
                        <Alert
                          message={submitTitle}
                          description={submitMessage}
                          type="error"
                          closable
                          onClose={onClose}
                        />
                      )}
                    </Space>

                    <Form.Item
                      name="activityID"
                      label="Activity"
                      rules={[
                        { required: true, message: 'Please select Activity!' },
                      ]}
                    >
                      <Select placeholder="select Activity">
                        {activities.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="croptypeID"
                      label="Crop Type"
                      rules={[
                        { required: true, message: 'Please select CropType!' },
                      ]}
                    >
                      <Select placeholder="select your CropType">
                        {croptype.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Grade"
                      name="gradeID"
                      className="collection-create-form_last-form-item"
                    >
                      <Radio.Group style={{ width: '100%' }}>
                        <Row>
                          {score.map((option) => (
                            <Col key={option.value}>
                              <Radio value={option.value}>{option.label}</Radio>
                            </Col>
                          ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="Farmers Responsiveness"
                      name="farmerResponsivenessID"
                      className="collection-create-form_last-form-item"
                    >
                      <Radio.Group
                        style={{ width: '100%' }}
                        // defaultValue={formData.farmerResponsivenessID}
                      >
                        <Row>
                          {score.map((option) => (
                            <Col key={option.value}>
                              <Radio value={option.value}>{option.label}</Radio>
                            </Col>
                          ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="Activity Per Cycle"
                      name="activityCycleID"
                      className="collection-create-form_last-form-item"
                    >
                      <Radio.Group style={{ width: '100%' }}>
                        <Row>
                          {score.map((option) => (
                            <Col key={option.value}>
                              <Radio value={option.value}>{option.label}</Radio>
                            </Col>
                          ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="General Approach"
                      name="generalApproachID"
                      className="collection-create-form_last-form-item"
                    >
                      <Radio.Group style={{ width: '100%' }}>
                        <Row>
                          {score.map((option) => (
                            <Col key={option.value} span={8}>
                              <Radio value={option.value}>{option.label}</Radio>
                            </Col>
                          ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="image" label="Image">
                      <Upload
                        name="image"
                        beforeUpload={() => {
                          return false;
                        }}
                        onChange={EditbeforeUpload}
                        listType="picture-card"
                        fileList={editfileList}
                        onRemove={removeIMG}
                      >
                        {editfileList.length >= 8 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </Form>
                </Modal>

                <Input
                  className="selcteAccountNo"
                  placeholder="Enter farmer's ID"
                  onChange={handleAccountnoChange}
                />
                <div
                  id="range-picker-container"
                  className="range-picker-container"
                >
                  {showDate && (
                    <DateRange
                      ranges={dateRange}
                      onChange={handleDateRangeChange}
                    />
                  )}
                  <Button type="primary" onClick={useDateHandler}>
                    {showDate ? 'Hide Date' : 'Show Date'}
                  </Button>
                </div>
                <Button
                  loading={Dataloading}
                  className="searchbtn"
                  type="primary"
                  onClick={searchData}
                >
                  Search
                </Button>
                {isLoadingContent ? (
                  <ScaleLoader
                    color={'#000'}
                    loading={isLoadingContent}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <>
                    {!isMobile ? (
                      <Table
                        dataSource={data}
                        scroll={{ x: true }}
                        className="monitorTable"
                      >
                        <Column
                          title="Farmer Name"
                          dataIndex="name"
                          key="name"
                        />
                        <Column title="Farmer ID" dataIndex="uuid" key="uuid" />
                        <Column
                          title="Activity"
                          dataIndex="activity"
                          key="activity"
                        />
                        <Column
                          title="Crop Type"
                          dataIndex="croptype"
                          key="croptype"
                        />
                        <Column
                          title="Grade ID"
                          dataIndex="gradeid"
                          key="gradeid"
                        />
                        <Column
                          title="Responsiveness"
                          dataIndex="responsiveness"
                          key="responsiveness"
                        />
                        <Column
                          title="Activity Cycle"
                          dataIndex="activityCycle"
                          key="activityCycle"
                        />
                        <Column
                          title="Approach"
                          dataIndex="approach"
                          key="approach"
                        />
                        <Column
                          title="Created By"
                          dataIndex="Creator"
                          key="Creator"
                        />
                        <Column
                          title="Created At"
                          dataIndex="createdAt"
                          key="createdAt"
                        />

                        <Column
                          title="Action"
                          key="action"
                          render={(_: any, record: DataType) => (
                            <Space size="middle">
                              <a onClick={() => handleEdit(record.key)}>Edit</a>
                              <Popconfirm
                                title="Sure to delete this user?"
                                onConfirm={() => handleDelete(record.key)}
                              >
                                <a>Delete</a>
                              </Popconfirm>
                            </Space>
                          )}
                        />
                      </Table>
                    ) : null}
                    {/* Render the current page of data */}
                    {isMobile ? (
                      <>
                        {/* Render the current page of data */}
                        {renderPage(
                          data.slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                        )}
                        {/* Render pagination component */}
                        <Pagination
                          total={total}
                          pageSize={pageSize}
                          current={currentPage}
                          onChange={(page) => setCurrentPage(page)}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default MonitorPage;
