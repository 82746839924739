import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Modal,
  Row,
  Table,
  List,
  Collapse,
  Upload,
  theme,
  Pagination,
  Space,
  Form,
  Alert,
  Timeline,
  Layout,
} from 'antd';
import UserService from '../../../services/user.service';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { CaretRightOutlined, FileImageOutlined } from '@ant-design/icons';
import { formatDate } from '../../../common/utility';
import { Input, Button } from 'antd';
import RoleHooks from '../../../hooks/verifyRole';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';

const { Content } = Layout;
const { permissionsData } = RoleHooks();

const { Column } = Table;
const gutters: Record<PropertyKey, number> = {};
const vgutters: Record<PropertyKey, number> = {};
const colCounts: Record<PropertyKey, number> = {};
const { Panel } = Collapse;
[8, 16, 24, 32, 40, 48].forEach((value, i) => {
  gutters[i] = value;
});
[8, 16, 24, 32, 40, 48].forEach((value, i) => {
  vgutters[i] = value;
});
[2, 3, 4, 6, 8, 12].forEach((value, i) => {
  colCounts[i] = value;
});
const API_URL = `${process.env.REACT_APP_API_END_POINT}`;

interface DataTypeTable {
  key: React.Key;
  name: string;
  accountNum: string;
  activity: string;
  croptype: string;
  gradeid: string;
  responsiveness: string;
  activityCycle: string;
  approach: string;
  createdAt: string;
  Uploads: [];
}
const data = [
  {
    names: '',
    Grade: 0,
    farmerResponsiveness: 0,
    activityCycle: 0,
    generalApproach: 0,
  },
];
const MonitorRecord = () => {
  const [form] = Form.useForm();
  const [chartData, setChartData] = useState(data);
  const [farmername, setFarmerName] = useState('');
  const [phone, setPhone] = useState('');
  const [farmersex, setFarmerSex] = useState('');
  const [farmeremail, setFarmerEmail] = useState('');
  const [accountBVN, setAccountBVN] = useState('');
  const [agentName, setAgentName] = useState('');
  const [dataTable, setDataTable] = useState<DataTypeTable[]>([]);
  const [gallery, setGallery] = useState<[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [gutterKey] = useState(1);
  const [vgutterKey] = useState(1);
  const [colCountKey] = useState(2);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [monitorIMG, setMonitorIMG] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitTitle, setSubmitTitle] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [farmerID, setFarmerID] = useState(0);
  const [comments, setComment] = useState<any>([]);
  const [commentsMedia, setCommentMedia] = useState<any>([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [isLoading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [Dataloading, setDataLoading] = useState(false);
  const [isLoadingContent, SetisLoadingContent] = useState(false);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [isloadingIMG, SetisloadingIMG] = useState(false);
  const [showDate, SetshowDate] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const pageSize: number = 10;
  const colCount = colCounts[colCountKey];
  const { token } = theme.useToken();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  const searchRecord = () => {
    setDataLoading(true);
    setChartData([]);
    setDataTable([]);
    setPhone('');
    setFarmerEmail('');
    setFarmerSex('');
    setAgentName('');
    setTotal(0);
    setFarmerID(0);
    SetisLoadingContent(true);
    const formattedStartDate = showDate
      ? format(dateRange[0].startDate, 'yyyy-MM-dd')
      : '';
    const formattedEndDate = showDate
      ? format(dateRange[0].endDate, 'yyyy-MM-dd')
      : '';
    if (accountBVN === '') return 0;
    UserService.GetRecord(
      'monitor/chart?page=chart&accountBVN=' +
        accountBVN +
        '&from=' +
        formattedStartDate +
        '&to=' +
        formattedEndDate
    ).then(
      (result) => {
        setDataLoading(false);
        setFarmerID(result.data.farmer.id);
        if (permissionsData.includes('can-comment')) {
          loadComment(result.data.farmer.id);
        }

        setChartData(
          result.data.data.map((option: any) => ({
            name: convertdate(option.createdAt),
            Grade: option.gradeID,
            farmerResponsiveness: option.farmerResponsivenessID,
            activityCycle: option.activityCycleID,
            generalApproach: option.generalApproachID,
          }))
        );
        setFarmerName(
          result.data.farmer.last_name + ' ' + result.data.farmer.first_name
        );
        setPhone('Farmer Phone: ' + result.data.farmer.phone);
        setFarmerEmail('Email: ' + result.data.farmer.email);
        setFarmerSex('Gender: ' + result.data.farmer.sex);
        setAgentName(
          'Agent: ' +
            result.data.farmer.User.first_name +
            ' ' +
            result.data.farmer.User.last_name
        );
        setTotal(result.data.data.length);

        setDataTable(
          result.data.data.map((option: any) => ({
            key: option.id,
            name: option.Farmer.last_name + ' ' + option.Farmer.first_name,
            accountNum: option.Farmer.accountNum,
            activity: option.Activity.name,
            croptype: option.CropType.name,
            gradeid: option.gradeID,
            responsiveness: option.farmerResponsivenessID,
            activityCycle: option.activityCycleID,
            createdAt: option.createdAt,
            approach: option.generalApproachID,
            Uploads: option.Uploads,
          }))
        );
        SetisLoadingContent(false);
      },
      (error) => {
        setFarmerID(0);
        setDataLoading(false);
        SetisLoadingContent(false);
      }
    );
  };
  const showIMG = (img: string) => {
    setVisible(true);
    setMonitorIMG(img);
    setCommentMedia('');
  };
  const viewSelectedMonitorIMG = (monitorID: any) => {
    setGallery([]);
    SetisloadingIMG(true);
    UserService.GetRecord('monitor/farmers/' + monitorID).then((result) => {
      if (result.data) {
        let cols: any = [];
        const datas = result.data.data;
        if (datas.Uploads.length > 0) {
          for (let i = 0; i < datas.Uploads.length; i++) {
            cols.push(
              <Col
                key={datas.Uploads[i].id.toString()}
                span={!isMobile ? 24 / colCount : 24}
              >
                <Card>
                  <img
                    onClick={() =>
                      showIMG(
                        API_URL +
                          'monitor/upload?searchkey=' +
                          datas.Uploads[i].objectKey
                      )
                    }
                    src={
                      API_URL +
                      'monitor/upload?searchkey=' +
                      datas.Uploads[i].objectKey
                    }
                    style={{ width: '100%', height: '150px' }}
                  />
                  <p>
                    {datas.Farmer.first_name +
                      ' ' +
                      datas.Farmer.last_name +
                      ' - ' +
                      formatDate(datas.createdAt)}
                  </p>
                </Card>
              </Col>
            );
          }
        }
        setGallery(cols);
        SetisloadingIMG(false);
      }
    });
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
    setPageLoaded(true);
  }, []);

  const handleAccountnoChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setAccountBVN(e.target.value);
  };
  const handleDateRangeChange = (ranges: any) => {
    setDateRange([ranges.selection]);
  };
  const convertdate = (newDate: string) => {
    var date = new Date(newDate);
    var dateString = date.toISOString().substring(0, 10);
    return dateString;
  };
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const beforeUpload = (file: any) => {
    // Perform any necessary processing or validation on the file here
    // If the file is valid, return true to allow the upload to proceed
    // If the file is invalid, return false to prevent the upload
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg';
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      setSubmitMessage('You can only upload JPG/PNG/SVG file!');
      setSubmitTitle('Image Validation');
      setSubmitBadge('error');
      setCanSubmit(false);
      return false;
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      setSubmitMessage('Image must smaller than 5MB!');
      setSubmitTitle('Image Validation');
      setSubmitBadge('error');
      setCanSubmit(false);

      return false;
    }
    setCanSubmit(true);
    // setFileUpload([...fileUpload, file]);
    // setFileList([...fileList, file])
    let fileList = [...file.fileList];
    fileList = fileList.slice(-3); // Limit the number of uploaded files to 3
    fileList = fileList.map((file) => ({
      ...file,
      status: 'done',
      // url: file.response?.url, // Add the URL of the uploaded file to the file object
    }));
    setFileList(fileList);
    return false;
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const onSendMessage = (values: any) => {
    const formData = new FormData();
    formData.append('message', values.message);
    formData.append('farmerID', farmerID + '');
    if (fileList.length > 0) {
      fileList.forEach((file: any) => {
        formData.append('files', file.originFileObj, file.name); // Append each file individually to the FormData object
      });
    }
    UserService.PostRecord(formData, 'comments').then(
      (response) => {
        setSubmitMessage('Submission was successful');
        setSubmitTitle('Record Success');
        setSubmitBadge('success');
        loadComment(farmerID);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSubmitMessage('Submission failed');
        setSubmitTitle('Record failed');
        setSubmitBadge('error');
      }
    );
  };
  const multipleIMG = (imgData: any) => {
    let cols = [];
    if (imgData.length > 0) {
      for (let i = 0; i < imgData.length; i++) {
        cols.push(
          <Card key={i}>
            <img
              src={API_URL + 'monitor/upload?searchkey=' + imgData[i].objectKey}
              style={{ width: '100%', height: '150px' }}
            />
          </Card>
        );
      }
    }
    setCommentMedia(cols);
    setVisible(true);
    setMonitorIMG('');
  };
  const loadComment = (farmerID: any) => {
    UserService.GetRecord('comments/' + farmerID).then((result) => {
      let timeline: any = [];
      result.data.data.forEach(async (comments: any) => {
        setComment(timeline);
        timeline.push({
          children: (
            <>
              <p>{comments.message}</p>{' '}
              {comments.CommentMedia.length > 0 && (
                <FileImageOutlined
                  rev="icon-rev"
                  onClick={() => multipleIMG(comments.CommentMedia)}
                />
              )}
            </>
          ),
        });
      });
    });
  };
  const renderPage = (pageData: any) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer, marginTop: '20px' }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={option.name + ' ' + formatDate(option.createdAt)}
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              bordered
              footer={
                <>
                  {option.Uploads.length > 0 ? (
                    <Space>
                      <Button
                        onClick={() => viewSelectedMonitorIMG(option.key)}
                      >
                        View Images
                      </Button>
                    </Space>
                  ) : (
                    <Space>
                      <Button disabled={true}>No Image</Button>
                    </Space>
                  )}
                </>
              }
              dataSource={[
                'Name: ' + option.name,
                'Account Number: ' + option.accountNum,
                'Activity: ' + option.activity,
                'CropType: ' + option.croptype,
                'Grade: ' + option.gradeid,
                'Responsiveness: ' + option.responsiveness,
                'Activity Cycle: ' + option.activityCycle,
                'Approach: ' + option.approach,
              ]}
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };
  const useDateHandler = () => {
    SetshowDate(!showDate);
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <h2>Search Farmer Activity</h2>

                <Modal
                  open={visible}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                  ]}
                >
                  {monitorIMG ? (
                    <img
                      src={monitorIMG}
                      alt="Modal Image"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : null}

                  {commentsMedia}
                </Modal>
                <Input
                  className="selcteAccountNo"
                  placeholder="Enter farmer's BVN"
                  onChange={handleAccountnoChange}
                />
                <div
                  id="range-picker-container"
                  className="range-picker-container"
                >
                  {showDate && (
                    <DateRange
                      ranges={dateRange}
                      onChange={handleDateRangeChange}
                    />
                  )}
                  <Button type="primary" onClick={useDateHandler}>
                    {showDate ? 'Hide Date' : 'Show Date'}
                  </Button>
                </div>

                <Button
                  className="searchbtn"
                  loading={Dataloading}
                  type="primary"
                  onClick={searchRecord}
                >
                  Search
                </Button>

                {total > 0 ? (
                  <Row>
                    <Col span={24}>
                      <Card title={farmeremail} bordered={false}>
                        <h3>{farmername}</h3>
                        <h4>{phone}</h4>
                        <h4>{farmersex}</h4>
                        <h4>{agentName}</h4>
                      </Card>
                    </Col>
                  </Row>
                ) : null}
                {/* <div style={{ width: '100%' }}> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                >
                  {total > 0 ? (
                    <LineChart
                      width={isMobile ? 300 : 600}
                      height={350}
                      data={chartData}
                      margin={{ top: 20, right: 0, left: 0, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Grade"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                      <Line
                        type="monotone"
                        dataKey="farmerResponsiveness"
                        stroke="#82ca9d"
                      />
                      <Line
                        type="monotone"
                        dataKey="activityCycle"
                        stroke="#0d0c0c"
                      />
                      <Line
                        type="monotone"
                        dataKey="generalApproach"
                        stroke="#eb4034"
                      />
                    </LineChart>
                  ) : null}
                </div>
                {isLoadingContent ? (
                  <ScaleLoader
                    color={'#000'}
                    loading={isLoadingContent}
                    // size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <>
                    {!isMobile ? (
                      <Table
                        dataSource={dataTable}
                        scroll={{ x: true }}
                        className="monitorTable"
                        style={{ marginTop: '20px' }}
                      >
                        <Column
                          title="Farmer Name"
                          dataIndex="name"
                          key="name"
                        />
                        <Column
                          title="Account No"
                          dataIndex="accountNum"
                          key="accountNum"
                        />
                        <Column
                          title="Activity"
                          dataIndex="activity"
                          key="activity"
                        />
                        <Column
                          title="Crop Type"
                          dataIndex="croptype"
                          key="croptype"
                        />
                        <Column
                          title="Grade ID"
                          dataIndex="gradeid"
                          key="gradeid"
                        />
                        <Column
                          title="Responsiveness"
                          dataIndex="responsiveness"
                          key="responsiveness"
                        />
                        <Column
                          title="Activity Cycle"
                          dataIndex="activityCycle"
                          key="activityCycle"
                        />
                        <Column
                          title="Approach"
                          dataIndex="approach"
                          key="approach"
                        />
                        <Column
                          title="View"
                          key="view_image"
                          render={(_: any, record: DataTypeTable) =>
                            record.Uploads.length > 0 ? (
                              <Space size="middle">
                                <Button
                                  onClick={() =>
                                    viewSelectedMonitorIMG(record.key)
                                  }
                                >
                                  View Images
                                </Button>
                              </Space>
                            ) : (
                              <Space size="middle">
                                <Button disabled={true}>No Images</Button>
                              </Space>
                            )
                          }
                        />
                      </Table>
                    ) : null}
                    {/* Render the current page of data */}
                    {isMobile ? (
                      <>
                        {/* Render the current page of data */}
                        {renderPage(
                          dataTable.slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                        )}
                        {/* Render pagination component */}
                        <Pagination
                          total={total}
                          pageSize={pageSize}
                          current={currentPage}
                          onChange={(page) => setCurrentPage(page)}
                        />
                      </>
                    ) : null}
                    {isloadingIMG ? (
                      <ScaleLoader
                        color={'#000'}
                        loading={isloadingIMG}
                        // size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <Row gutter={[gutters[gutterKey], vgutters[vgutterKey]]}>
                        {gallery}
                      </Row>
                    )}
                    {permissionsData.includes('can-comment') && !!farmerID ? (
                      <>
                        <Space style={{ margin: '20px' }}>
                          {submitMessage && submitBadge === 'success' && (
                            <Alert
                              message={submitTitle}
                              description={submitMessage}
                              type="success"
                              closable
                              onClose={onClose}
                            />
                          )}
                          {submitMessage && submitBadge === 'error' && (
                            <Alert
                              message={submitTitle}
                              description={submitMessage}
                              type="error"
                              closable
                              onClose={onClose}
                            />
                          )}
                        </Space>
                        <Form
                          form={form}
                          name="commentForm"
                          style={formStyle}
                          onFinish={onSendMessage}
                        >
                          <Form.Item
                            name="message"
                            label="Leave a comment:"
                            rules={[
                              { required: true, message: 'Enter Comment' },
                            ]}
                          >
                            <Input.TextArea showCount />
                          </Form.Item>
                          <Form.Item name="image">
                            <Upload
                              name="files"
                              beforeUpload={beforeUpload}
                              fileList={fileList}
                              onChange={(info: any) =>
                                setFileList(info.fileList)
                              }
                            >
                              <Button>Click to Upload</Button>
                            </Upload>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={isLoading}
                              disabled={!canSubmit}
                            >
                              Send Message
                            </Button>
                          </Form.Item>
                        </Form>
                        <Collapse
                          bordered={false}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined
                              rev="icon-rev"
                              rotate={isActive ? 90 : 0}
                            />
                          )}
                          style={{ background: token.colorBgContainer }}
                        >
                          <Panel
                            header={
                              'Tap to view Comments on ' +
                              farmername +
                              ' Performance'
                            }
                            key="1"
                            style={panelStyle}
                          >
                            <Timeline items={comments} />
                          </Panel>
                        </Collapse>
                      </>
                    ) : null}
                  </>
                )}
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default MonitorRecord;
