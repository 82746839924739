import React, { useState, useEffect } from 'react';
import {
  Table,
  Layout,
  Tag,
  Popconfirm,
  Space,
  Col,
  Row,
  Button,
  Form,
  Input,
  Modal,
  Checkbox,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import UserService from '../../services/user.service';
import CustomSideBar from './inc/CustomSideBar';
import TopBar from './inc/TopBar';

const { Content } = Layout;
interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface DataType {
  id: React.Key;
  name: string;
  RoleHasPermissions: [];
}

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

interface Option {
  id: string;
  name: string;
}
interface State {
  open: boolean;
  data: DataType[];
  permissionData: any[];
  selectedRolePermissions: number[];
  UpdateorInsert: number;
  roleName: string;
  PageLoaded: boolean;
}
export default class Role extends React.Component {
  state: State = {
    open: false,
    data: [],
    permissionData: [],
    selectedRolePermissions: [],
    UpdateorInsert: 0,
    roleName: '',
    PageLoaded: false,
  };
  columns: ColumnsType<DataType> = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Permissions',
      dataIndex: 'permission',
      key: 'permission',
      render: (text, record) => (
        <>
          {record.RoleHasPermissions.map((permission, index) => (
            <Tag color="magenta">{permission['Permission']['name']}</Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => this.handleDelete(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
          <a onClick={() => this.handleEdit(record.id)}>Edit</a>
        </Space>
      ),
    },
  ];
  handleDelete = (key: React.Key) => {
    UserService.DeleteRecord('roles/' + key).then(
      (response) => {},
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  handleEdit = (key: React.Key) => {
    this.state.UpdateorInsert = key as number;
    UserService.GetRecord('roles/' + key).then((result) => {
      if (result.data) {
        this.setState({
          open: true,
          selectedRolePermissions: [],
        });
        let temparray = [];
        //Assigning set permission id
        for (const roleHasPermission of result.data.data.RoleHasPermissions) {
          temparray.push(roleHasPermission.Permission.id);
        }
        this.setState({
          selectedRolePermissions: temparray,
          roleName: result.data.data.name,
        });
      }
    });
  };

  handleCreate = (values: any) => {
    UserService.PostRecord(values, 'roles').then(
      (response) => {
        this.loadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    this.state.open = false;
  };
  handleUpdate = (values: any) => {
    UserService.UpdateRecord(values, 'roles/' + this.state.UpdateorInsert).then(
      (response) => {
        this.loadData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    this.state.open = false;
  };
  componentDidMount() {
    UserService.GetRecord('permissions').then((result) => {
      if (result.data) {
        this.setState({ permissionData: result.data.data });
      } else {
      }
    }); //fetch all permissions
    this.loadData(); //load roles
    const tableData: DataType[] = this.state.data;
    this.state.PageLoaded = true;
  }
  loadData = () => {
    UserService.GetRecord('roles').then((result) => {
      if (result.data) {
        this.setState({ data: result.data.data });
      }
    });
  };
  CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
  }) => {
    const [form] = Form.useForm();
    let options: Option[] = this.state.permissionData;
    const [defaultChecked, setDefaultChecked] = useState<number[]>();
    useEffect(() => {
      form.setFieldsValue({
        name: this.state.roleName,
        permissions: this.state.selectedRolePermissions,
      });
    }, [open]);
    return (
      <Modal
        open={open}
        title="Set Roles and Permission"
        okText="Submit"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {});
        }}
        className="modalName"
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            name: this.state.roleName,
            permissions: defaultChecked,
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Input Name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="permissions"
            className="collection-create-form_last-form-item"
          >
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {options.map((option) => (
                  <Col key={option.id}>
                    <Checkbox value={option.id}>
                      {option.name.replace(/-/g, '_')}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  render() {
    return (
      <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <CustomSideBar />
          <Layout className="site-layout">
            <TopBar />
            <Content style={{ margin: '20px' }}>
              <Button
                type="primary"
                onClick={() => {
                  this.state.UpdateorInsert = 0;
                  this.setState({
                    open: true,
                  });
                }}
              >
                Add Role
              </Button>
              <this.CollectionCreateForm
                open={this.state.open}
                onCreate={
                  this.state.UpdateorInsert > 0
                    ? this.handleUpdate
                    : this.handleCreate
                }
                onCancel={() => {
                  this.setState({
                    open: false,
                  });
                }}
              />
              <Table
                columns={this.columns}
                dataSource={this.state.data}
                scroll={{ x: 1500, y: 300 }}
                rowKey={(record) => record.id}
              />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
