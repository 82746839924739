import React, { useState, useEffect } from 'react';
import UserService from '../../../services/user.service';
import { Layout, Form, Input, Alert, Space, Button, Card } from 'antd';
import RoleHooks from '../../../hooks/verifyRole';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import ScaleLoader from 'react-spinners/ScaleLoader';
const { Content } = Layout;
const { uid } = RoleHooks();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Profile = () => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitBadge, setSubmitBadge] = useState('');
  const [PageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
        UserService.GetRecord('users/' + uid).then(async (result) => {
          if (result.data) {
            const {
              first_name = '',
              last_name = '',
              phone = '',
              email = '',
              houseNum = '',
              streetName = '',
              city = '',
              address = '',
            } = result.data.data;
            form.setFieldsValue({
              first_name: first_name,
              last_name: last_name,
              phone: phone,
              email: email,
              houseNum: houseNum,
              streetName: streetName,
              city: city,
              address: address,
            });
          }
        });
      } catch (error) {}
    };
    getUserData();
    setPageLoaded(true);
  }, []);

  const handleSubmit = (values: any) => {
    // handle form submission
    setLoading(true);
    UserService.UpdateRecord(values, 'users').then(
      (response) => {
        setLoading(false);
        setSubmitBadge('success');
        setSubmitMessage('Profile Updated!');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSubmitBadge('error');
        setSubmitMessage('Profile Update Failed!');
      }
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Card
                  title="Profile"
                  className="adjust-responsive"
                  style={{ margin: '0 auto' }}
                  id="Profile"
                >
                  <Space>
                    {submitMessage && submitBadge === 'success' && (
                      <Alert
                        message="Profile Update"
                        description={submitMessage}
                        type="success"
                        closable
                      />
                    )}
                    {submitMessage && submitBadge === 'error' && (
                      <Alert
                        message="Profile Update"
                        description={submitMessage}
                        type="error"
                        closable
                      />
                    )}
                  </Space>
                  <Form {...formItemLayout} form={form} scrollToFirstError>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your First Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Last Name!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="email">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your phone number!',
                          max: 13,
                          min: 11,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 20 }}
                      name="houseNum"
                      label="House Number"
                      rules={[
                        {
                          message: 'Please input your house number!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 20 }}
                      name="streetName"
                      label="Street Name"
                      rules={[
                        {
                          message: 'Please input your street name!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 20 }}
                      name="city"
                      label="City"
                      rules={[
                        {
                          message: 'Please input your city name!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 20 }}
                      name="address"
                      label="Address"
                      rules={[
                        {
                          message: 'Please input your address!',
                          whitespace: false,
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        loading={loading}
                        key="submit"
                        type="primary"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              handleSubmit(values);
                            })
                            .catch((info) => {});
                        }}
                      >
                        Save Changes
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Profile;
