import React, { useState, useEffect } from 'react';
import { formatDate } from '../../../common/utility';
import { Row, Col, Input, Button, Layout, Card, message, Space } from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import LoadRecordComponent from './component/loadRecordComponent';
import './style/style.css';
const { Content } = Layout;

interface Calendar {
  id: number;
  title: string;
  maxDuration: number;
  farmCalendarMetaData: any;
}
interface Product {
  value: number;
  label: string;
}
interface Meta {
  key?: number | null;
  id?: number | null;
  activityId?: number | null;
  farmCalendarID: number;
  FarmCalendarActivity?: any;
}
interface Locations {
  id: number;
  name: string;
  description: string;
  totalNumberOfHectare: number;
  farmCalendarID: number;
  startDate: string;
  farmCalendar: any;
}
const CalendarViewAdminComponent = () => {
  const [loading, setLoading] = useState(false);
  const [editCalendarId, setEditCalendarId] = useState<number | null>(null);
  const [currentLocation, setCurrentLocation] = useState<Locations>();
  const [listCalendarMeta, setListCalendarMeta] = useState<Product[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<Calendar>();
  const [listCalendarData, setListCalendarData] = useState<Meta[]>([]);
  const [calendarMeta, setCalendarMeta] = useState<Meta[]>([]);
  const [locations, setLocations] = useState<Locations[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);

  useEffect(() => {
    fetchLocation();
  }, []);
  const colorSetter = (originalRgbaColor: string, opacity: any) => {
    const percent = opacity / 100;
    if (percent > 1) {
      opacity = 1;
    } else {
      opacity = percent.toFixed(2);
    }
    const rgbaParts = originalRgbaColor.match(/\d+/g);

    if (rgbaParts && rgbaParts.length >= 4) {
      const updatedRgbaColor = `rgba(${rgbaParts[0]}, ${rgbaParts[1]}, ${rgbaParts[2]}, ${opacity})`;
      return updatedRgbaColor;
    } else if (rgbaParts && rgbaParts.length === 3) {
      const updatedRgbaColor = `rgba(${rgbaParts[0]}, ${rgbaParts[1]}, ${rgbaParts[2]}, ${opacity})`;
      return updatedRgbaColor;
    }
  };
  const fetchCalendarMeta = (id: number) => {
    UserService.GetRecord('calender/calendar-meta/' + id).then(
      (response: any) => {
        setListCalendarMeta(
          response.data.data.map((option: any) => ({
            label: option.FarmCalendarActivity.name,
            value: option.id,
          }))
        );
        setCalendarMeta(response.data.data);
      }
    );
  };
  const handleSetCalendar = (location: Locations) => {
    setEditCalendarId(location.farmCalendar.id);
    handleViewCalendarRecord(location);
    setSelectedCalendar(location.farmCalendar);
    fetchCalendarMeta(location.farmCalendar.id);
  };

  const fetchLocation = () => {
    UserService.GetRecord('calender/location').then(
      (response: any) => {
        setShowTable(false);
        setLocations(response.data.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };

  const handleViewCalendarRecord = (location: any) => {
    UserService.GetRecord(
      'calender/calendar-data/' +
        location.farmCalendar.id +
        '?location=' +
        location.id
    ).then(
      (response: any) => {
        setListCalendarData(response.data.data);
        message.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const addMonthsToDate = (date: string, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate
      .toLocaleString('en-US', { year: 'numeric', month: 'long' })
      .replace(' ', '');
  };

  const getMonthData = (metaID: number, selectMonth: string) => {
    const arrayData = [...listCalendarData];
    // Create an object to hold grouped records
    const recordsByMonthAndWeek: any = {};
    let i = 0;
    // Loop through arrayData and group and filter records by month and farmCalendarID
    arrayData.forEach((data: any) => {
      if (data.farmCalendarMetaDataID === metaID) {
        const createdAt = new Date(data.createdAt);
        const monthYear = createdAt
          .toLocaleString('en-US', { year: 'numeric', month: 'long' })
          .replace(' ', '');
        const weekNumber = Math.floor((createdAt.getDate() - 1) / 7) + 1; // Calculate week number
        if (!recordsByMonthAndWeek[monthYear]) {
          recordsByMonthAndWeek[monthYear] = {};
        }
        if (!recordsByMonthAndWeek[monthYear][weekNumber]) {
          recordsByMonthAndWeek[monthYear][weekNumber] = [];
        }
        recordsByMonthAndWeek[monthYear][weekNumber].push(data);
        i++;
      }
    });
    let weekRecord = [];
    for (const key in recordsByMonthAndWeek[selectMonth]) {
      let weekTotal = 0;
      for (var j = 0; j < recordsByMonthAndWeek[selectMonth][key].length; j++) {
        weekTotal += parseFloat(
          recordsByMonthAndWeek[selectMonth][key][j]['numberOfHectare']
        );
      }
      weekRecord.push(weekTotal);
    }
    return weekRecord;
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>View Calender</h1>
            <div className="m-5"></div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}

            <div className="card-body">
              <Space direction="vertical">
                <Button onClick={() => setShowTable(false)}>
                  View Location
                </Button>
                <Button onClick={() => setViewDetails(!viewDetails)}>
                  View Data Details
                </Button>
              </Space>
              {!viewDetails && (
                <>
                  {currentLocation && showTable && (
                    <>
                      <div style={{ marginBottom: '10px' }}></div>
                      <div style={{ overflowX: 'auto' }}>
                        <table
                          border={1}
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                            border: '1px solid #ccc',
                          }}
                        >
                          <thead>
                            <tr>
                              <th></th>

                              {selectedCalendar &&
                                selectedCalendar.maxDuration && (
                                  <>
                                    {Array.from(
                                      { length: selectedCalendar.maxDuration },
                                      (_, index) => (
                                        <th>
                                          M{index + 1}{' '}
                                          <small>
                                            {currentLocation &&
                                              addMonthsToDate(
                                                currentLocation.startDate,
                                                index
                                              )}
                                          </small>
                                        </th>
                                      )
                                    )}
                                  </>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              {selectedCalendar &&
                                selectedCalendar.maxDuration && (
                                  <>
                                    {Array.from(
                                      { length: selectedCalendar.maxDuration },
                                      (_, index) => (
                                        <td>
                                          <tr style={{ width: '100%' }}>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W1
                                            </td>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W2
                                            </td>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W3
                                            </td>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W4
                                            </td>
                                          </tr>
                                        </td>
                                      )
                                    )}
                                  </>
                                )}
                            </tr>
                            <tr>
                              <td>
                                <b>Activity</b>
                              </td>
                              {selectedCalendar &&
                                selectedCalendar.maxDuration &&
                                Array.from(
                                  { length: selectedCalendar.maxDuration },
                                  (_, index) => <td></td>
                                )}
                            </tr>
                            {calendarMeta &&
                              calendarMeta.map((meta: any) => {
                                let activityNo = 0;

                                return (
                                  <tr>
                                    <td>{meta.FarmCalendarActivity.name}</td>
                                    {selectedCalendar &&
                                      currentLocation &&
                                      selectedCalendar.maxDuration &&
                                      Array.from(
                                        {
                                          length: selectedCalendar.maxDuration,
                                        },
                                        (_, index) => (
                                          <td>
                                            <tr>
                                              {getMonthData(
                                                meta.id,
                                                addMonthsToDate(
                                                  currentLocation.startDate,
                                                  index
                                                )
                                              ).map((value) => {
                                                let currentLoc =
                                                  currentLocation &&
                                                  (
                                                    (value /
                                                      currentLocation.totalNumberOfHectare) *
                                                    100
                                                  ).toFixed();
                                                activityNo = currentLoc
                                                  ? parseFloat(currentLoc) +
                                                    activityNo
                                                  : 0;
                                                return (
                                                  <td
                                                    style={{
                                                      backgroundColor:
                                                        colorSetter(
                                                          meta
                                                            .FarmCalendarActivity
                                                            .color,
                                                          activityNo
                                                        ),
                                                      padding: '5px',
                                                    }}
                                                  >
                                                    {activityNo}%
                                                  </td>
                                                );
                                              })}
                                            </tr>
                                          </td>
                                        )
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                    </>
                  )}
                  {locations && !showTable && (
                    <>
                      <Row gutter={[16, 16]}>
                        {locations.map((location: any) => (
                          <Col
                            key={location.id}
                            span={8}
                            xs={24}
                            sm={24}
                            md={8}
                            lg={8}
                            xl={8}
                          >
                            <div
                              className="card"
                              onClick={() => {
                                handleSetCalendar(location);
                                setCurrentLocation(location);
                                setShowTable(true);
                              }}
                            >
                              <div className="card-content">
                                <h2 className="card-title">{location.name}</h2>
                                <p className="card-description">
                                  Start Date: {formatDate(location.startDate)}
                                </p>
                                <p className="card-description">
                                  Hectar: {location.totalNumberOfHectare}
                                </p>
                                <p className="card-description">
                                  {location.description}
                                </p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </>
              )}
            </div>
            {viewDetails && (
              <LoadRecordComponent propsList={listCalendarData} />
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default CalendarViewAdminComponent;
