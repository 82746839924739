import UserService from '../../../services/user.service';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { generatePDF } from '../../../library/PDFexport';
import { FilePdfOutlined } from '@ant-design/icons';
import {
  Button,
  Layout,
  Alert,
  Form,
  Input,
  Card,
  message,
  Result,
} from 'antd';
import { Link } from 'react-router-dom';

import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
const TextArea = Input;

const { Content } = Layout;

interface selectOptions {
  value: number;
  label: string;
  price: number;
  measurement: number;
}
const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const FarmerTransaction: React.FC = (props) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [gettingOTP, setGettingOTP] = useState(false);
  const [itemList, setItemList] = useState<selectOptions[]>([]);
  const [resMessage, setResMessage] = useState<{
    message: string;
    tag: 'success' | 'error' | 'info' | 'warning';
  }>();
  const [farmerID, setFarmerID] = useState('');
  const [amount, setAmount] = useState(0);
  const [sentOTP, setSentOTP] = useState(0);
  const [completetxn, setCompletetxn] = useState(0);
  const [messageError, setMessageError] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);


  const [naration, setNaration] = useState('');
  const [measurment, setMeasurment] = useState('');
  const [itemNames, setItemNames] = useState('');


  const [result, setResult] = useState<{
    accountNumber: string;
    amount: number;
    measurement: string;
    reference: string;
    status: string;
  }>();

  const getOTP = () => {
    if (farmerID.length < 6) {
      return false;
    }
    setGettingOTP(true);
    UserService.GetRecord('transaction/farmer/generate-OTP/' + farmerID).then(
      (response) => {
        message.success(response.data.message);
        setSentOTP(1);
        setGettingOTP(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setGettingOTP(false);
      }
    );
  };
  const onFinish = (values: any) => {
    setSubmitting(true);
    values.farmer = farmerID;
    values.item = values.item.value;
    values.naration =naration;

    UserService.PostRecord(values, 'transaction/farmer/transaction').then(
      (response) => {
        form.resetFields();
        message.success(response.data.message);
        setSubmitting(true);
        setCompletetxn(1);
        setResult(response.data.data);
        setMessageError('');
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
        setMessageError(resMessage);
      }
    );
  };
  const handleItemChange = (value: any) => {
    setSentOTP(0);
    setItemPrice(value.price)
    setAmount(quantity * value.price)
    setMeasurment(value.measurement)
    setItemNames(value.label)
    setNaration(`Purchase of ${quantity} ${value.measurement} of ${itemNames}`)
  };

  const loadItem = async () => {
    UserService.GetRecord('items').then(
      (result: any) => {
        if (result.data) {
          setItemList(
            result.data.data.map((option: any) => ({
              value: option.id,
              label: option.name,
              price: option.price,
              measurement:option.measurement
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          message.error(resMessage)
      }
    );
  };
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.length >= 6) {
      setSentOTP(0);
      setFarmerID(e.target.value);
      UserService.GetRecord('farmers/account-farmer-no/' + e.target.value).then(
        (result: any) => {
          if (result.data) {
            setResMessage({
              message:
                 result.data.data.accountNumber,
              tag: 'success',
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setResMessage({ message: resMessage, tag: 'error' });
        }
      );
    }
  };

  useEffect(() => {
    loadItem();
  }, []);
  const handleInputChange = (event:any) => {
    // Ensure that the entered value is a valid number
    const newValue = parseFloat(event.target.value);
    // Update the state with the new valueUpdate the state with the new valUpdate the state with the Update the state wiUpdate the stUpdate
    if (!isNaN(newValue)) {
      setQuantity(newValue)
      setAmount(itemPrice * newValue)
      setNaration(`Purchase of ${newValue} ${measurment} of ${itemNames}`)
    }else{
      alert('Quantity must be a number')
    }
  };

  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <div style={{ margin: '5px' }}>
          <Button type="primary" size="large" >
              <Link to="/admin/loan">Input Support</Link>
            </Button>
            <Button type="default" size="large" >
              <Link to="/farmer/transfer">Commodity Purchase</Link>
            </Button>
            </div>
            <Card
              title="Farmers' Transaction"
              className="adjust-responsive"
              style={{ margin: '0 auto' }}
              id="screen"
            >
              {completetxn && result ? (
                <Result
                  status="success"
                  title="Request Sent!"
                  extra={
                    <>
                      <p>Amount:{result.amount}</p>
                      <p>Account Number:{result.accountNumber}</p>
                      <p>Reference:{result.reference}</p>
                      <p>Status:{result.status}</p>
                      <p>Naration:{naration}</p>

                      <Button
                        type="primary"
                        onClick={() => {
                          setCompletetxn(0);
                        }}
                      >
                        Try another transaction
                      </Button>
                      <Button
                        key="pdf"
                        onClick={() => {
                          generatePDF('screen');
                        }}
                      >
                        Export PDF
                        <FilePdfOutlined rev="icon-rev" />
                      </Button>
                    </>
                  }
                  subTitle="An admin will review your submission before completing the transaction."
                />
              ) : (
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 600 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Enter Farmer's ID"
                    name="farmer"
                    rules={[{ message: "Please Enter Farmer's ID!" }]}
                  >
                    <Input onChange={onChange} />
                    <span>
                      {resMessage && (
                        <Alert
                          message={resMessage.message}
                          type={resMessage.tag}
                        />
                      )}
                    </span>
                  </Form.Item>

                  <Form.Item
                    label="Select Item"
                    name="item"
                    rules={[
                      { required: true, message: 'Please select an item!' },
                    ]}
                  >
                    <Select options={itemList} onChange={handleItemChange} />
                  </Form.Item>

                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      { required: true, message: 'Please input quantity!' },
                    ]}
                  >
                    <Input type="number"  onChange={handleInputChange} min={0} />
                  </Form.Item>

                  <Form.Item
                    label={`Amount payable`}
                    name="amount"
                    rules={[
                      { required: true, message: 'Please input amount!' },
                    ]}
                  >
                    <span>{amount}</span>
                  </Form.Item>
                  <Form.Item
                    label="Naration"
                    name="naration"
                    rules={[
                      { required: true, message: 'Please input naration!' },
                    ]}
                  >
                    <span>{naration}</span>
                    {/* <TextArea placeholder="Naration" /> */}
                  </Form.Item>
                  {messageError && (
                    <Alert message={messageError} type="error" />
                  )}
                  {sentOTP ? (
                    <>
                      <Form.Item
                        label="Enter Pin"
                        name="pin"
                        rules={[
                          {
                            required: true,
                            max: 4,
                            message: 'Please input transaction pin!',
                          },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item
                        label="Enter OTP"
                        name="otp"
                        rules={[
                          {
                            required: true,
                            max: 6,
                            message: 'Please input amount!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Initiate
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                          type="link"
                          disabled={gettingOTP}
                          htmlType="button"
                          onClick={() => getOTP()}
                        >
                          Send OTP Again
                        </Button>
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                          type="primary"
                          disabled={gettingOTP}
                          htmlType="button"
                          onClick={() => getOTP()}
                        >
                          Send OTP
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              )}
            </Card>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default FarmerTransaction;
