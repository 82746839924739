const RoleHooks = () => {
  let isAdmin = false;
  let isAgent = false;
  let isSupervisor = false;
  let status = 'Guest';
  let name = '';
  let uid = 0;
  const jsonAuth = localStorage.getItem('user');
  let permissionsData: any = [];
  if (jsonAuth) {
    const user = JSON.parse(jsonAuth).data;
    name = user.first_name + ' ' + user.last_name;
    uid = user.id;
    if (user.Role) {
      user.Role.RoleHasPermissions.map((Haspermissions: any) => {
        permissionsData.push(Haspermissions.Permission.name);
      });
      isAgent = user.Role.name === 'Agent';
      isAdmin = user.Role.name === 'Admin';
      isSupervisor = user.Role.name === 'Supervisor';
      status = user.Role.name;
    }
  }
  return {
    name: name,
    isAdmin,
    isAgent,
    isSupervisor,
    status,
    uid,
    permissionsData,
  };
};
export default RoleHooks;
