import React, { useRef, useState, useEffect } from 'react';
import {
  CaretRightOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  Alert,
  Select,
  Space,
  Modal,
  Radio,
  Badge,
  theme,
  Pagination,
  InputNumber,
  Layout,
  Card,
  Row,
  Col,
  Timeline,
  Table,
  Result,
} from 'antd';
import { formatDate } from '../../../common/utility';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { List } from 'antd';
import { Checkbox, Divider } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import { Tooltip } from '@mui/material';
import { ExportExcel } from '../../../library/Excelexport';
import { generatePDF } from '../../../library/PDFexport';

import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import Highlighter from 'react-highlight-words';
const { Meta } = Card;
const { TextArea } = Input;
const API_URL = `${process.env.REACT_APP_API_END_POINT}`;

interface SelectOption {
  value: string;
  label: string;
}

const { Content } = Layout;
const { Panel } = Collapse;
interface DataType {
  id: number;
  Item: {
    name: string;
  };
  quantity: number;
  supplier: string;
  User: {
    first_name: string;
    last_name: string;
  };
  unitprice: number;
}
interface orderDetail {
  id: number;
  destinationAddress: string;
  phone: string;
}
interface orderData {
  key: number;
  farmerName: string;
  destinationAddress: string;
  phone: string;
  supplierName: string;
  itemName: string;
  agentName: string;
  orderReference: string;
  quantity: number;
  totalAmount: number;
  createdAt: string;
  status: string;
  expectedDeliveryDate: string;
  TrackOrders: [];
}
type DataIndex = keyof orderData;
interface timeline {
  children: string;
  label: string;
}

const OrderComponent: React.FC = (props) => {
  const [message, setMessage] = useState('');
  const [form] = Form.useForm();
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPageActivity] = useState(1);
  const [submitBadge, setSubmitBadge] = useState('error');
  const [PageLoaded, setPageLoaded] = useState(false);
  const [isopenModal, SetisopenModal] = useState(false);
  const [isopenModalProgress, setIsopenModalProgress] = useState(false);

  const [options, SetOptions] = useState<SelectOption[]>([]);
  const [submitting, SetSubmitting] = useState(false);
  const [editID, SeteditID] = useState<number>(0);
  const [itemID, SetitemID] = useState<number>(0); //
  const [quantity, Setquantity] = useState(0); //
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [cannotSubmit, setCanNotSubmit] = useState(true);
  const [selectedDetailsOptions, setSelectedDetailsOptions] =
    useState('new-address');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState();
  const [farmer, setFarmer] = useState<number>(0);
  const [optionsFarmer, setOptionsFarmer] = useState<SelectOption[]>([]);
  const [showAddresDetails, setShowAddresDetails] = useState(false);
  const [loadingSupplierItem, setLoadingSupplierItem] = useState(false);
  const [farmerdata, setFarmerdata] = useState<orderDetail[]>([]);
  const [loadingFarmerdata, setLoadingFarmerdata] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(0);
  const [orderData, setOrderData] = useState<orderData[]>([]);
  const [selectedOrderData, setSelectedOrderData] = useState<orderData>();
  const [checkedSearch, setCheckedSearch] = useState<CheckboxValueType[]>([]);
  const [orderTrack, setOrderTrack] = useState<timeline[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);

  const [otp, setOtp] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [takeOTP, setTakeOTP] = useState(false);

  const plainOptions = [
    { label: 'Item Name' },
    { label: 'Farmer Name' },
    { label: 'Supplier Name' },
    { label: 'orderReference' },
    { label: 'order Phone' },
  ];
  const pageSize: number = 10;
  const { token } = theme.useToken();
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  const validate = () => {
    if (
      itemID > 0 &&
      quantity > 0 &&
      quantity != null &&
      selectedItemId > 0 &&
      farmer != null
    ) {
      setCanNotSubmit(false);
      if (
        selectedDetailsOptions === 'new-address' &&
        typeof address === 'undefined' &&
        address === null &&
        typeof phone === 'undefined' &&
        phone === null
      ) {
        setCanNotSubmit(true);
      } else {
        setCanNotSubmit(false);
      }
    } else {
      setCanNotSubmit(true);
    }
  };
  const LoadOrderDetails = (Order: orderData) => {
    setIsopenModalProgress(true);
    setSelectedOrderData(Order);
    setTakeOTP(false);
    setOrderCompleted(false);
    setOrderTrack(
      Order.TrackOrders.map((option: any) => ({
        children: option.address,
        label: option.arrivalTime,
      }))
    );
  };
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      values.detailsoption = selectedDetailsOptions;
      values.supplierItemID = selectedItemId;
      values.detailID = selectedDetails;
      const endpoint =
        editID === 0
          ? UserService.PostRecord(values, 'order')
          : UserService.UpdateRecord(values, 'supplier/items/' + editID);
      endpoint.then(
        (response) => {
          LoadData();
          setMessage('Suppliers Item Saved!');
          setSubmitBadge('success');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setSubmitBadge('error');
        }
      );
    });
  };
  const LoadData = (search?: string) => {
    const searchParams = new URLSearchParams();
    checkedSearch.map((query: any) => searchParams.append('checked[]', query));
    const queryString = searchParams.toString();
    const searchurl = search
      ? `order?search=${search}&${queryString}`
      : `order?${queryString}`;
    UserService.GetRecord(searchurl).then(
      (result) => {
        if (result.data) {
          setTotalData(result.data.data.length);
          setOrderData(
            result.data.data.map((option: any) => ({
              key: option.id,
              farmerName:
                option.Farmer.first_name + ' ' + option.Farmer.last_name,
              destinationAddress: option.OrderDetail.destinationAddress,
              phone: option.OrderDetail.phone,
              supplierName: option.supplierItem
                ? option.supplierItem.User.first_name +
                  ' ' +
                  option.supplierItem.User.last_name
                : '',
              itemName: option.Item.name,
              agentName: option.User.first_name + ' ' + option.User.last_name,
              orderReference: option.orderReference,
              quantity: option.quantity,
              totalAmount: option.totalAmount,
              createdAt: formatDate(option.createdAt),
              status: option.status,
              TrackOrders: option.TrackOrders,
              expectedDeliveryDate: option.expectedDeliveryDate,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setMessage(resMessage);
        setOrderData([]);
      }
    );
  };
  const LoadDataItem = async () => {
    UserService.GetRecord('items').then((result) => {
      if (result.data) {
        SetOptions(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  };
  const LoadDataFarmer = async () => {
    UserService.GetRecord('farmers').then((result) => {
      if (result.data) {
        setOptionsFarmer(
          result.data.map((option: any) => ({
            value: option.id,
            label:
              option.first_name + ' ' + option.last_name + ' ' + option.bvn,
          }))
        );
      }
    });
  };
  const handleCancel = () => {
    SetisopenModal(false);
  };
  const handleCancelProgress = () => {
    setIsopenModalProgress(false);
  };

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const searchItem: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const search = e.target.value;
    LoadData(search);
  };
  const onQuantity = (value: number | null) => {
    if (!value) return Setquantity(1);
    Setquantity(value);
  };
  useEffect(() => {
    getSupplierItems();
  }, [quantity, itemID]);

  const onItemset = (value: number) => {
    SetitemID(value);
    setCanNotSubmit(true);
  };
  const onFarmer = (value: number) => {
    setFarmer(value);
    onFarmerSelected(value);
  };
  const getSupplierItems = () => {
    if (itemID === 0 && quantity < 1) return false;
    loadMoreData();
  };
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoadingSupplierItem(true);
    setLoading(true);
    fetch(API_URL + 'order/view/item?item=' + itemID + '&quantity=' + quantity)
      .then((res) => res.json())
      .then((body) => {
        setData([...body.result]);
        setLoading(false);
        setSelectedItemId(0);
        setLoadingSupplierItem(false);
      })
      .catch(() => {
        setLoading(false);
        setLoadingSupplierItem(false);
      });
  };
  useEffect(() => {
    LoadDataItem();
    LoadData();
    LoadDataFarmer();
    setPageLoaded(true);
  }, []);
  const onChangeDetails = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedDetailsOptions(value);
  };
  const onAddress = (value: any) => {
    setAddress(value);
  };
  const onPhone = (value: any) => {
    setPhone(value);
  };
  useEffect(() => {
    validate();
    if (selectedDetailsOptions === 'new-address') {
      setShowAddresDetails(true);
    } else {
      setShowAddresDetails(false);
    }
  }, [
    selectedDetailsOptions,
    quantity,
    itemID,
    selectedItemId,
    phone,
    address,
    farmer,
  ]);
  const handleOtpChange = (e: any) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };
  const handleSendOTP = () => {
    setOrderCompleted(true);
  };

  const onFarmerSelected = (farmerID: number) => {
    setLoadingFarmerdata(true);
    UserService.GetRecord('order/details/' + farmerID).then((result) => {
      if (result.data) {
        setLoadingFarmerdata(false);

        setFarmerdata(
          result.data.data.map((option: orderDetail) => ({
            id: option.id,
            destinationAddress: option.destinationAddress,
            phone: option.phone,
          }))
        );
      }
    });
  };
  const onsearchChange = (checkedValues: any) => {
    setCheckedSearch(checkedValues);
  };
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<orderData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev="icon-rev" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev="icon-rev"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<orderData> = [
    {
      title: 'Farmer Name',
      dataIndex: 'farmerName',
      key: 'farmerName',
      width: '30%',
      ...getColumnSearchProps('farmerName'),
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
      width: '20%',
      ...getColumnSearchProps('itemName'),
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: '20%',
      ...getColumnSearchProps('supplierName'),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      width: '20%',
      ...getColumnSearchProps('agentName'),
    },
    {
      title: 'Order Reference',
      dataIndex: 'orderReference',
      key: 'orderReference',
      width: '20%',
      ...getColumnSearchProps('orderReference'),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',

      ...getColumnSearchProps('totalAmount'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
      ...getColumnSearchProps('quantity'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Expected Delivery',
      dataIndex: 'expectedDeliveryDate',
      key: 'expectedDeliveryDate',
      width: '20%',
      ...getColumnSearchProps('expectedDeliveryDate'),
    },
    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',
      width: '20%',
      render: (_: any, record: orderData) => (
        <Space>
          <Button
            onClick={(e) => {
              LoadOrderDetails(record);
            }}
          >
            View Order
          </Button>
        </Space>
      ),
    },
  ];
  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={option.farmerName + ' ' + option.createdAt}
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <Button
                      onClick={() => {
                        LoadOrderDetails(option);
                      }}
                    >
                      View Order
                    </Button>
                  </Space>
                </>
              }
              dataSource={[
                'Item : ' + option.itemName,
                'Quantity : ' + option.quantity,
                'Total Payable : ' + option.totalAmount,
                'Reference : ' + option.orderReference,
                'Phone : ' + option.phone,
                'Address : ' + option.destinationAddress,
                'Supplier :' + option.supplierName,
                'Agent :' + option.agentName,
                'Agent :' + option.agentName,
                'Expected Delivery :' + option.expectedDeliveryDate,
                'Status : ' + (option.status === 'pending') ? (
                  <Badge status="warning" text={option.status} />
                ) : option.status === 'rejected' ? (
                  <Badge status="error" text={option.status} />
                ) : option.status === 'completed' ? (
                  <Badge text={option.status} status="success" />
                ) : (
                  <Badge text={option.status} status="processing" />
                ),
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <>
                  <Modal
                    title="Make Order"
                    open={isopenModal}
                    onCancel={handleCancel}
                    footer={[
                      <Button
                        key="submit"
                        onClick={handleSubmit}
                        type="primary"
                        loading={submitting}
                        disabled={cannotSubmit}
                      >
                        Make Order
                      </Button>,
                      <Button key="back" onClick={handleCancel}>
                        Return
                      </Button>,
                    ]}
                  >
                    <Space>
                      {message && submitBadge === 'success' && (
                        <Alert
                          message="Success"
                          description={message}
                          type="success"
                          closable
                          onClose={onClose}
                        />
                      )}
                      {message && submitBadge === 'error' && (
                        <Alert
                          message="Error"
                          description={message}
                          type="error"
                          closable
                          onClose={onClose}
                        />
                      )}
                    </Space>
                    <Form form={form}>
                      <Form.Item
                        name="farmesr"
                        label="Select Farmer"
                        rules={[
                          { required: true, message: 'Please select farmer!' },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: '100%' }}
                          placeholder="Select Items"
                          options={optionsFarmer}
                          onChange={onFarmer}
                        />
                      </Form.Item>
                      <Form.Item
                        name="itemID"
                        label="Item"
                        rules={[
                          { required: true, message: 'Please select Item!' },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: '100%' }}
                          placeholder="Select Items"
                          options={options}
                          onChange={onItemset}
                        />
                      </Form.Item>
                      <Form.Item name="quantity" label="Quantity">
                        <InputNumber onChange={onQuantity} />
                      </Form.Item>
                      <>
                        {loadingSupplierItem && (
                          <Card
                            style={{ width: 300, marginTop: 16 }}
                            loading={loadingSupplierItem}
                          >
                            <Meta
                              title="Card title"
                              description="This is the description"
                            />
                          </Card>
                        )}
                        <h3>Select Supplier</h3>
                        {data.map((item: DataType) => (
                          <Card
                            style={{
                              width: 300,
                              marginTop: 16,
                              background:
                                selectedItemId === item.id ? 'grey' : 'none',
                              padding: '5px',
                              margin: '5px',
                            }}
                            loading={loading}
                            key={item.id}
                            onClick={() => {
                              setSelectedItemId(item.id);
                            }}
                          >
                            <Meta
                              title={
                                item.User.first_name + ' ' + item.User.last_name
                              }
                              description={
                                <>
                                  <div>Item Name: {item.Item.name}</div>
                                  <div>Quantity: {item.quantity}</div>{' '}
                                  <div>Unit Price: NGN{item.unitprice}</div>
                                  <div>
                                    Payable: NGN{item.unitprice * quantity}
                                  </div>
                                </>
                              }
                            />
                          </Card>
                        ))}
                      </>
                      {selectedItemId ? (
                        <>
                          <Form.Item
                            name="expectedDeliveryDate"
                            label="Expected Delivery"
                          >
                            <Input type="datetime-local" />
                          </Form.Item>
                          <Form.Item
                            name="detailsoption"
                            label="Delivery Details"
                          >
                            <Radio.Group
                              defaultValue={'new-address'}
                              onChange={onChangeDetails}
                            >
                              <Radio value={'new-address'}>
                                Create Order Details
                              </Radio>
                              <Radio value={'existing-address'}>
                                Select Existing Details
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          {showAddresDetails ? (
                            <>
                              <Form.Item name="phone" label="Contact Phone">
                                <Input
                                  placeholder="Contact Phone"
                                  allowClear
                                  onChange={onPhone}
                                />
                              </Form.Item>
                              <Form.Item
                                name="destinationAddress"
                                label="Destination Address"
                              >
                                <TextArea
                                  placeholder="Enter Destination Address"
                                  allowClear
                                  onChange={onAddress}
                                />
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h3>Select Previous Details</h3>
                              {loadingFarmerdata && (
                                <Card
                                  style={{ width: 300, marginTop: 16 }}
                                  loading={loadingFarmerdata}
                                >
                                  <Meta
                                    title="Card title"
                                    description="This is the description"
                                  />
                                </Card>
                              )}
                              {farmerdata.map((data: orderDetail) => (
                                <Card
                                  style={{
                                    width: 300,
                                    marginTop: 16,
                                    background:
                                      selectedDetails === data.id
                                        ? 'grey'
                                        : 'none',
                                    padding: '5px',
                                    margin: '5px',
                                  }}
                                  loading={loading}
                                  key={data.id}
                                  onClick={() => {
                                    setSelectedDetails(data.id);
                                  }}
                                >
                                  <Meta
                                    title={data.phone}
                                    description={data.destinationAddress}
                                  />
                                </Card>
                              ))}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Form>
                  </Modal>

                  <Modal
                    title="View Order Details"
                    open={isopenModalProgress}
                    onCancel={handleCancelProgress}
                    footer={[
                      <Button
                        onClick={() => {
                          generatePDF('orderDetail');
                        }}
                      >
                        Export PDF
                        <FilePdfOutlined rev="icon-rev" />
                      </Button>,
                      selectedOrderData &&
                        selectedOrderData.status != 'Completed' && (
                          <Button
                            key="complete"
                            onClick={() => setTakeOTP(true)}
                          >
                            Complete
                          </Button>
                        ),
                      <Button key="back" onClick={handleCancelProgress}>
                        Return
                      </Button>,
                    ]}
                  >
                    <Form form={form} id="orderDetail">
                      <h2>Order Information</h2>
                      <p>
                        <b>Item</b> :{' '}
                        {selectedOrderData && selectedOrderData.itemName}
                      </p>
                      <p>
                        <b>Agent Name</b> :{' '}
                        {selectedOrderData && selectedOrderData.agentName}
                      </p>
                      <p>
                        <b>Supplier Name</b> :{' '}
                        {selectedOrderData && selectedOrderData.supplierName}
                      </p>
                      <p>
                        <b>Farmer Name</b> :{' '}
                        {selectedOrderData && selectedOrderData.farmerName}
                      </p>
                      <p>
                        <b>Order Reference</b> :{' '}
                        {selectedOrderData && selectedOrderData.orderReference}
                      </p>
                      <p>
                        <b>Quantity</b> :{' '}
                        {selectedOrderData && selectedOrderData.quantity}
                      </p>
                      <p>
                        <b>Total Amount</b> :{' '}
                        {selectedOrderData && selectedOrderData.totalAmount}
                      </p>
                      <p>
                        <b>Status</b> :{' '}
                        {selectedOrderData && selectedOrderData.status}
                      </p>
                      <p>
                        <b>Phone</b> :{' '}
                        {selectedOrderData && selectedOrderData.phone}
                      </p>
                      <p>
                        <b>Address</b> :{' '}
                        {selectedOrderData &&
                          selectedOrderData.destinationAddress}
                      </p>
                      <p>
                        <b>Delivery Time</b> :{' '}
                        {selectedOrderData &&
                          selectedOrderData.expectedDeliveryDate}
                      </p>
                      {orderTrack && <h2> Delivery Time Line</h2>}
                    </Form>
                    <Timeline mode="left" items={orderTrack} />
                    {takeOTP && (
                      <Input
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={handleOtpChange}
                        addonAfter={
                          <Button onClick={handleSendOTP}>Send OTP</Button>
                        }
                      />
                    )}
                    {orderCompleted && (
                      <div className="order-complete">
                        <Result
                          status="success"
                          title="Order Completed Successfully"
                          subTitle="Your order has been completed and farmer debitted!"
                        />
                      </div>
                    )}
                  </Modal>
                  <Button
                    style={{ margin: '10px' }}
                    type="primary"
                    onClick={() => {
                      SetisopenModal(true);
                      SeteditID(0);
                    }}
                  >
                    Create Order
                  </Button>
                  <p>
                    <div style={{ overflowX: 'auto' }}></div>
                    <Divider />
                    {isMobile && (
                      <>
                        <Checkbox.Group
                          style={{ width: '100%' }}
                          onChange={onsearchChange}
                        >
                          <Row>
                            {plainOptions.map((plainOptionData, index) => (
                              <Col span={8} key={index}>
                                <Checkbox
                                  value={plainOptionData.label.replaceAll(
                                    ' ',
                                    ''
                                  )}
                                >
                                  {plainOptionData.label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                        <div />
                        <Input
                          style={{ margin: '5px' }}
                          placeholder="Search Order"
                          onChange={searchItem}
                        />
                      </>
                    )}
                  </p>
                  <Tooltip title="Export Excel">
                    <Button
                      onClick={() => {
                        ExportExcel({
                          excelDelta: orderData,
                          fileName: 'orderDetails',
                        });
                      }}
                    >
                      Export Excel
                      <FileExcelOutlined rev="icon-rev" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Export Excel">
                    <Button
                      onClick={() => {
                        generatePDF();
                      }}
                    >
                      Export PDF
                      <FilePdfOutlined rev="icon-rev" />
                    </Button>
                  </Tooltip>
                  <div id="report">
                    {!isMobile ? (
                      <Table
                        columns={columns}
                        dataSource={orderData}
                        scroll={{ x: 'max-content', y: 400 }}
                      />
                    ) : (
                      <>
                        {/* Render the current page of data */}
                        {renderPage(
                          orderData.slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          ),
                          'order'
                        )}
                        {/* Render pagination component */}
                        <Pagination
                          total={totalData}
                          pageSize={pageSize}
                          current={currentPage}
                          onChange={(page) => setCurrentPageActivity(page)}
                        />
                      </>
                    )}
                  </div>
                </>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default OrderComponent;
