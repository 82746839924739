import './App.css';
import React, { useEffect } from 'react';
import Login from './components/auth/login.component';
import ChangePassword from './components/Pages/User/Change.component';
import Role from './components/Pages/Role.component';
import Dashboard from './components/Pages/Dashboard.component';
import Agents from './components/Pages/bankingAgent/Agents.component';
import UserPage from './components/Pages/User/User.component';
import Farmer from './components/Pages/farmer/Farmer.component';
import FarmerLogin from './components/Pages/farmer/FarmerLogin.component';
import MonitorPage from './components/Pages/monitor/Monitor.component';
import MonitorRecord from './components/Pages/monitor/MonitorRecord.component';
import Profile from './components/Pages/User/Profile.component';
import VariableComponent from './components/Pages/monitor/Variable.component';
import ItemComponent from './components/Pages/orderModule/Item.component';
import SupplierItemComponent from './components/Pages/orderModule/SupplierInventory.component';
import OrderComponent from './components/Pages/orderModule/Order.component';
import SupplierOrder from './components/Pages/orderModule/SupplierOrder.component';
import RiderComponent from './components/Pages/orderModule/Rider.component';
import StateComponent from './components/Pages/state/State.component';
import BankingAgent from './components/Pages/external/bankingAgent.component';
import GuideAdmin from './components/Pages/Guide/admin/guideComponent';
import GuideComponent from './components/Pages/Guide/admin/guideViewComponent';
import Documentation from './components/Pages/Guide/external/documentation';

import ActivityVariableComponent from './components/Pages/calender/activityVariableComponent';
import CalendarComponent from './components/Pages/calender/calendarComponent';
import CalendarLocationComponent from './components/Pages/calender/calendarLocationComponent';
import AssignCalendarLocationComponent from './components/Pages/calender/calendarAssignUser';
import CalendarViewComponent from './components/Pages/calender/calendarViewComponent';
import CalendarViewAdminComponent from './components/Pages/calender/calendarViewAdminComponent';
import FarmerTransaction from './components/Pages/Transaction/farmerTransaction';
import ViewTransaction from './components/Pages/Transaction/viewTransaction';
import PinTransaction from './components/Pages/User/Pin.component';
import FarmerAccount from './components/Pages/farmer/FarmerAccount.component';
import LoanComponent from './components/Pages/Loan/loanComponent';
import Quize from './components/Pages/Guide/Quize/Quize.component';
import ExternalQuizeModule from './components/Pages/Guide/Quize/QuizeExternal.component';

import AdminQuestionEntry from './components/Pages/Guide/Quize/QuizeAdmin.component';
import AdminQuestionTopicEntry from './components/Pages/Guide/Quize/QuizeTopic.component';
import ExistingBankingAgent from './components/Pages/external/existingUserBankingAgent.component';

import AdminInventoryPage from './components/Pages/inventory/adminScreen';
import Inventorywarehouse from './components/Pages/inventory/warehouselocation';
import Inventoryoperation from './components/Pages/inventory/warehouseoperation';
import Inventorylog from './components/Pages/inventory/warehouseLog';
import Training from './components/Pages/Guide/external/training';
import ViewAnswers from './components/Pages/Guide/Quize/viewAnswers';

import UserService from './services/user.service';
import { Layout } from 'antd';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import RoleHooks from './hooks/verifyRole';
import './theme.less';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CardTable from './components/Pages/Card/CardComponent'
const { Footer } = Layout;
const { permissionsData } = RoleHooks();
export default function App() {
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      // Create a new Web Worker instance with inline code
      const worker = new Worker(
        URL.createObjectURL(
          new Blob(
            [
              `
        // Background code
        self.addEventListener('message', function(event) {
          // Perform background tasks
          // ...
          // Send a message back to the main thread
          self.postMessage('Background task completed');
        });
        self.postMessage('Background task completed');

        // Start the background task
        setInterval(function() {
          // Perform background tasks every 5 minutes
          // ...

          // Send a message back to the main thread
          self.postMessage('Background task completed');
        }, 1 * 60 * 1000); // 1 minutes
        `,
            ],
            { type: 'text/javascript' }
          )
        )
      );

      // Listen for messages from the worker
      worker.addEventListener('message', function (event) {
        try {
          ///
          UserService.GetRecord('banking/agent/notification').then(
            async (result) => {
              if (result.data) {
                if (result.data.data === true && 'Notification' in window) {
                  const permission = await Notification.requestPermission();
                  if (permission === 'granted') {
                    new Notification('NOMA Notification', {
                      body: 'Pending banking agents are waiting for your approval.',
                    });
                    const playSound = () => {
                      const audio = new Audio('./sound/notification.wav');
                      audio.addEventListener('canplaythrough', (event) => {
                        audio.play();
                      });
                    };
                    playSound();
                  }
                }
              }
            }
          );
          // if (permissionsData.includes('auditor')) {
          //   UserService.GetRecord('transaction/transaction-notification').then(
          //     async (result) => {
          //       if ('Notification' in window) {
          //         const permission = await Notification.requestPermission();
          //         if (permission === 'granted') {
          //           new Notification('NOMA Notification', {
          //             body: 'Approve pending transactions.',
          //           });
          //           const playSound = () => {
          //             const audio = new Audio('./sound/notification.wav');
          //             audio.addEventListener('canplaythrough', (event) => {
          //               audio.play();
          //             });
          //           };
          //           playSound();
          //         }
          //       }
          //     }
          //   );
          // }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      });

      // Clean up the worker when the component unmounts
      return () => {
        worker.terminate();
      };
    }
  }, []);

  return (
    <>
      <div
        style={{
          marginBottom: '60px',
        }}
      >
        <Contents permissionsData={permissionsData} />
        <Footer
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            textAlign: 'center',
          }}
        >
          NOMA ©2023 Created by NOMA Engineering
        </Footer>
      </div>
    </>
  );
}
function Contents({ permissionsData: any = [] }) {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/user/change-password" element={<ChangePassword />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/pin" element={<PinTransaction />} />

        <Route
          path="/account/users"
          element={
            <ProtectedRoute isAllowed={!!permissionsData.includes('view-user')}>
              <UserPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/farmers/activity"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData &&
                permissionsData.includes('create-monitor-records')
              }
            >
              <MonitorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('system-variable')
              }
            >
              <VariableComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farmers"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('view-farmers')
              }
            >
              <Farmer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/roles"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('view-roles')
              }
            >
              <Role />
            </ProtectedRoute>
          }
        />

        <Route
          path="/activity/charts"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData &&
                permissionsData.includes('view-monitor-records')
              }
            >
              <MonitorRecord />
            </ProtectedRoute>
          }
        />
        {/*  dispatch-rider  */}
        <Route
          path="/order/items-list"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('create-order')
              }
            >
              <ItemComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/supplier/items-list"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('supplier')
              }
            >
              <SupplierItemComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order/item"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('create-order')
              }
            >
              <OrderComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/supplier/order"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('supplier')
              }
            >
              <SupplierOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dispatch-rider"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('dispatch-rider')
              }
            >
              <RiderComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/state-variable"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('system-variable')
              }
            >
              <StateComponent />
            </ProtectedRoute>
          }
        />
        {/*  */}
        <Route
          path="/banking-Agents"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData &&
                permissionsData.includes('view-banking-agent')
              }
            >
              <Agents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farmers-bank-account"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('card-agent')
              }
            >
              <FarmerAccount />
            </ProtectedRoute>
          }
        />

        <Route path="/documentation" element={<Documentation />} />
        <Route path="/training/:title" element={<Training />} />
        <Route path="/card" element={<CardTable />} />


        <Route path="/admin/view-guide" element={<GuideComponent />} />
        <Route
          path="/admin/calendar/admin-view-calendar"
          element={<CalendarViewAdminComponent />}
        />
        <Route
          path="/admin/calendar/view-calendar"
          element={<CalendarViewComponent />}
        />
        <Route
          path="/admin/calendar/assign-location"
          element={<AssignCalendarLocationComponent />}
        />
        <Route
          path="/admin/calendar/location"
          element={<CalendarLocationComponent />}
        />
        <Route
          path="/admin/calendar/activity"
          element={<ActivityVariableComponent />}
        />
        <Route path="/admin/calendar/create" element={<CalendarComponent />} />

        <Route path="/admin-guide" element={<GuideAdmin />} />
        <Route
          path="/admin-quize/:topicName"
          element={<AdminQuestionEntry />}
        />
        <Route path="/quize" element={<Quize />} />
        <Route path="/quize-topic" element={<AdminQuestionTopicEntry />} />

        <Route
          path="/edit-banking-agent/:user_id"
          element={<ExistingBankingAgent />}
        />


        <Route path="/admin-guide/:id" element={<GuideAdmin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/farmer/login" element={<FarmerLogin />} />
        <Route path="/register/banking-agent/:id" element={<BankingAgent />} />
        <Route
          path="/farmer/transfer"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData && permissionsData.includes('pos-agent')
              }
            >
              <FarmerTransaction />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transaction/view"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData &&
                (permissionsData.includes('pos-agent') ||
                  permissionsData.includes('auditor'))
              }
            >
              <ViewTransaction />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/loan"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData &&
                (permissionsData.includes('loan-agent') ||
                  permissionsData.includes('root'))
              }
            >
              <LoanComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/inventory"
          element={
            <ProtectedRoute
              isAllowed={
                !!permissionsData &&
                (permissionsData.includes('manage-inventory') ||
                permissionsData.includes('view-all-store-record') ||
                  permissionsData.includes('root'))
              }
            >
              <Inventoryoperation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/inventory-warehouse"
          element={
            <ProtectedRoute
            isAllowed={
              !!permissionsData &&
              (permissionsData.includes('view-all-store-record') ||
                permissionsData.includes('root'))
            }
          >
            <Inventorywarehouse />
          </ProtectedRoute>

          }
        />
        <Route
          path="/admin/inventory-warehouse/log"
          element={

            <ProtectedRoute
            isAllowed={
              !!permissionsData &&
              (permissionsData.includes('manage-inventory') ||
              permissionsData.includes('view-all-store-record') ||
                permissionsData.includes('root'))
            }
          >
           <Inventorylog />
          </ProtectedRoute>
          }
        />
        <Route
          path="/admin/warehouse-store"
          element={

            <ProtectedRoute
            isAllowed={
              !!permissionsData &&
              (
              permissionsData.includes('view-all-store-record') ||
                permissionsData.includes('root'))
            }
          >
           <AdminInventoryPage />
          </ProtectedRoute>
          }
        />



        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </div>
  );
}

const ProtectedRoute: any = ({
  isAllowed = false,
  redirectPath = '/login',
  children = null,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};
