import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Form, List, message, Radio } from 'antd';
import CustomSideBar from '../../inc/CustomSideBar';
import TopBar from '../../inc/TopBar';
import { Layout } from 'antd';
import UserService from '../../../../services/user.service';
import { useParams } from 'react-router-dom';
const { Content } = Layout;
const AdminQuestionEntry = () => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState<any>([]);
  const [questions, setQuestion] = useState([]);
  const { topicName } = useParams();
  const [correctOptions, setCorrectOptions] = useState(null);

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleOptionChange = (index: any, value: any) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleRemoveOption = (index: any) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const fetchQuestion = async () => {
    try {
      const endpoint = UserService.GetRecord(`quize/question/${topicName}`);
      endpoint.then(
        (response) => {
          setQuestion(response.data.data);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
          setQuestion([]);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteQuestions = async (topicId: number) => {
    try {
      const endpoint = UserService.DeleteRecord(`quize/question/${topicId}`);
      endpoint.then(
        (response) => {
          message.success('Question topic deleted successfully');
          fetchQuestion();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
        }
      );
    } catch (error) {
      console.error(error);
      message.error('Failed to delete question topic');
    }
  };
  const handleCorrectOptionChange = (index: any, value: any) => {
    // const updatedCorrectOptions = [correctOptions];
    // updatedCorrectOptions[index] = value;
    setCorrectOptions(index);
  };
  const handleFormSubmit = (values: any) => {
    try {
      const payload = {
        question: values.question,
        options: options,
        topic: topicName,
        answer: correctOptions,
      };
      const endpoint = UserService.PostRecord(payload, 'quize');
      endpoint.then(
        (response) => {
          message.success('Question added successfully');
          form.resetFields();
          setOptions([]);
          fetchQuestion();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          message.error(resMessage);
        }
      );
    } catch (error) {
      console.error(error);
      message.error('Failed to add question');
    }
  };
  useEffect(() => {
    fetchQuestion();
  }, []);
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>Admin Question Entry </h1>

            <Card
              title={`${topicName?.replace(/-/g, ' ')}`}
              style={{ width: '600px', margin: '20px auto' }}
            >
              <Form form={form} onFinish={handleFormSubmit}>
                <Form.Item
                  label="Question"
                  name="question"
                  rules={[
                    { required: true, message: 'Please enter a question' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Options">
                  <List
                    dataSource={options}
                    renderItem={(option: any, index: any) => (
                      <List.Item key={index}>
                        <Input
                          placeholder={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                          style={{ width: '80%', marginRight: '10px' }}
                        />
                        <Form.Item initialValue={false} valuePropName="checked">
                          <Radio
                            name={`correctOption-${index}`}
                            onChange={(e) =>
                              handleCorrectOptionChange(index, e.target.checked)
                            }
                          >
                            Correct
                          </Radio>
                        </Form.Item>

                        <Button
                          type="dashed"
                          onClick={() => handleRemoveOption(index)}
                        >
                          Remove
                        </Button>
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    onClick={handleAddOption}
                    style={{ marginTop: '10px' }}
                  >
                    Add Option
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
            <Card
              title="Entered Questions"
              style={{ width: '600px', margin: '20px auto' }}
            >
              <List
                dataSource={questions}
                renderItem={(item: any) => (
                  <List.Item
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p style={{ fontWeight: 'bold' }}>{item.question}</p>
                    <ul
                      style={{
                        listStyleType: 'none',
                        paddingLeft: 0,
                        alignContent: 'start',
                        textAlign: 'left',
                      }}
                    >
                      {item.QuestionOptions.map((option: any) => (
                        <li key={option.id} style={{ margin: '5px 0' }}>
                          {option.option} {option.answer ? 'Answer' : ''}
                        </li>
                      ))}
                    </ul>
                    <Button
                      type="link"
                      danger
                      onClick={() => handleDeleteQuestions(item.id)}
                    >
                      Delete Question
                    </Button>
                  </List.Item>
                )}
              />
            </Card>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminQuestionEntry;
