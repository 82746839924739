import React, { useState, useEffect, useRef } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  Alert,
  Select,
  List,
  Space,
  Table,
  theme,
  Pagination,
  Layout,
  Modal,
} from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import type { SelectProps } from 'antd';

import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import Highlighter from 'react-highlight-words';
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

interface DataTypeTable {
  key: React.Key;
  itemID: number;
  status: string;
  quantity: number;
  unitprice: number;
  item: string;
}
type DataIndex = keyof DataTypeTable;

const SupplierItemComponent: React.FC = (props) => {
  const [message, setMessage] = useState('');
  const [form] = Form.useForm();
  const [itemData, setItemData] = useState<DataTypeTable[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPageActivity] = useState(1);
  const [submitBadge, setSubmitBadge] = useState('error');
  const [submitTitle] = useState('');
  const [submitMessage] = useState('');
  const [PageLoaded, setPageLoaded] = useState(false);
  const [isopenModal, SetisopenModal] = useState(false);
  const [options, SetOptions] = useState<SelectProps['options']>([]);
  const [submitting] = useState(false);
  const [editID, SeteditID] = useState<number>(0);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [isMobile, setIsMobile] = useState(false);
  const pageSize: number = 10;
  const { token } = theme.useToken();
  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const endpoint =
        editID === 0
          ? UserService.PostRecord(values, 'supplier/items')
          : UserService.UpdateRecord(values, 'supplier/items/' + editID);
      endpoint.then(
        (response) => {
          form.resetFields();
          LoadData();
          setMessage('Suppliers Item Saved!');
          setSubmitBadge('success');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setSubmitBadge('error');
        }
      );
    });
  };
  const LoadData = (search?: string) => {
    const searchurl = search
      ? 'supplier/items?search=' + search
      : 'supplier/items';
    UserService.GetRecord(searchurl).then(
      (result) => {
        if (result.data) {
          setTotalData(result.data.data.length);
          setItemData(
            result.data.data.map((option: any) => ({
              key: option.id,
              itemID: option.itemID,
              status: option.status,
              quantity: option.quantity,
              unitprice: option.unitprice,
              item: option.Item.name,
            }))
          );
        }
      },
      (error) => {
        setItemData([]);
      }
    );
  };
  const LoadDataItem = async (search?: string) => {
    UserService.GetRecord('items').then((result) => {
      if (result.data) {
        SetOptions(
          result.data.data.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))
        );
      }
    });
  };
  const handleCancel = () => {
    SetisopenModal(false);
  };
  const handleEdit = (editData: any) => {
    SetisopenModal(true);
    SeteditID(editData.key);
    form.setFieldsValue({
      itemID: editData.itemID,
      quantity: editData.quantity,
      status: editData.status,
      unitprice: editData.unitprice,
    });
  };
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  useEffect(() => {
    LoadDataItem();
    LoadData();
    setPageLoaded(true);
  }, []);
  const searchItem: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const search = e.target.value;
    LoadData(search);
  };
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataTypeTable> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev="icon-rev" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev="icon-rev"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataTypeTable> = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      width: '30%',
      ...getColumnSearchProps('item'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '20%',
      ...getColumnSearchProps('quantity'),
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitprice',
      key: 'unitprice',
      width: '20%',
      ...getColumnSearchProps('unitprice'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },

    {
      title: 'Action',
      dataIndex: 'key',
      key: 'key',
      width: '20%',
      render: (_: any, record: DataTypeTable) => (
        <Space>
          <Button
            onClick={(e) => {
              handleEdit(record);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel header={option.item} key={index} style={panelStyle}>
            <List
              size="small"
              footer={
                <>
                  <Space>
                    <Button
                      onClick={() => {
                        handleEdit(option);
                      }}
                    >
                      Edit
                    </Button>
                  </Space>
                </>
              }
              dataSource={[
                'Quantity : ' + option.quantity,
                'Unit Price : ' + option.unitprice,
                'Status : ' + option.status,
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <Space>
                  {message && submitBadge === 'success' && (
                    <Alert
                      message="Success"
                      description={message}
                      type="success"
                      closable
                      onClose={onClose}
                    />
                  )}
                  {message && submitBadge === 'error' && (
                    <Alert
                      message="Error"
                      description={message}
                      type="error"
                      closable
                      onClose={onClose}
                    />
                  )}
                </Space>
                <>
                  <Modal
                    title="Add Items"
                    open={isopenModal}
                    // onOk={UpdateMonitor}
                    onCancel={handleCancel}
                    footer={[
                      <Button
                        key="submit"
                        onClick={handleSubmit}
                        type="primary"
                        loading={submitting}
                        disabled={submitting}
                      >
                        Submit
                      </Button>,
                      <Button key="back" onClick={handleCancel}>
                        Return
                      </Button>,
                    ]}
                  >
                    <Form form={form}>
                      <Space>
                        {submitMessage && submitBadge === 'success' && (
                          <Alert
                            message={submitTitle}
                            description={submitMessage}
                            type="success"
                            closable
                            onClose={onClose}
                          />
                        )}
                        {submitMessage && submitBadge === 'error' && (
                          <Alert
                            message={submitTitle}
                            description={submitMessage}
                            type="error"
                            closable
                            onClose={onClose}
                          />
                        )}
                      </Space>

                      <Form.Item
                        name="itemID"
                        label="Item"
                        rules={[
                          { required: true, message: 'Please select Item!' },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{ width: '100%' }}
                          placeholder="Select Items"
                          options={options}
                        />
                      </Form.Item>
                      <Form.Item name="quantity" label="Quantity">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                          { required: true, message: 'Please select Status!' },
                        ]}
                      >
                        <Select placeholder="select Item Status">
                          <Option value="Enable">Enable</Option>
                          <Option value="Disable">Disable</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name="unitprice" label="Unit Price">
                        <Input />
                      </Form.Item>
                    </Form>
                  </Modal>

                  <Button
                    style={{ margin: '10px' }}
                    type="primary"
                    onClick={() => {
                      SetisopenModal(true);
                      SeteditID(0);
                    }}
                  >
                    {' '}
                    Add Items
                  </Button>
                  <p>
                    <Input
                      style={{ margin: '20px' }}
                      className="selcteAccountNo"
                      placeholder="Search Item"
                      onChange={searchItem}
                    />
                  </p>
                  <>
                    {!isMobile ? (
                      <Table columns={columns} dataSource={itemData} />
                    ) : (
                      <>
                        {renderPage(
                          itemData.slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          ),
                          'items'
                        )}
                        {/* Render pagination component */}
                        <Pagination
                          total={totalData}
                          pageSize={pageSize}
                          current={currentPage}
                          onChange={(page) => setCurrentPageActivity(page)}
                        />
                      </>
                    )}
                  </>
                </>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default SupplierItemComponent;
