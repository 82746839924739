import React, { useRef, useState, useEffect } from 'react';
import {
  CaretRightOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Input,
  Space,
  message,
  theme,
  Pagination,
  Layout,
  Table,
} from 'antd';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import { List } from 'antd';
import { Divider } from 'antd';
import { Tooltip } from '@mui/material';
import { ExportExcel } from '../../../library/Excelexport';
import { generatePDF } from '../../../library/PDFexport';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import Highlighter from 'react-highlight-words';
const { Content } = Layout;
const { Panel } = Collapse;
interface agentType {
  key: React.Key;
  uuid?: string;
  name?: string;
  bank?: string;
  accountNumber?: string;
  phone?: string;
  email?: string;
  atmCardNo?: string;
}

type DataIndex = keyof agentType;
const FarmerAccount: React.FC = (props) => {
  const [totalData, setTotalData] = useState(0);
  const [BatchNumber, setBatchNumber] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [PageLoaded, setPageLoaded] = useState(false);
  const [farmerData, setFarmerData] = useState<agentType[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [pageSize, setPageSize] = useState(10);
  const [success, setSuccess] = useState<string>('');
  const [errorMsg, setError] = useState<string>('');
  const { token } = theme.useToken();

  const exportExcel = () => {
    ExportExcel({
      excelDelta: farmerData,
      fileName: 'FarmersAccount',
    });
  };
  const setBatch = () => {
    UserService.GetRecord('farmers/card/reset-card-status/' + BatchNumber).then(
      (result) => {
        if (result.data) {
          setSuccess('data found!');
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
        setFarmerData([]);
      }
    );
  };
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBatchNumber(e.target.value);
  };
  const LoadData = () => {
    UserService.GetRecord('farmers/bank/get-farmer-by-account-details').then(
      (result) => {
        if (result.data) {
          setSuccess('data found!');
          setTotalData(result.data.data.length);
          setFarmerData(
            result.data.data.map((option: any, index: number) => ({
              key: index + 1,
              uuid: option.uuid,
              name: option.first_name + ' ' + option.last_name,
              accountNumber: option.farmerAccountDetail.accountNumber,
              bank: option.farmerAccountDetail.Bank.name,
              phone: option.phone,
              email: option.email,
              atmCardNo: option.farmerAccountDetail.atmCardNo,
            }))
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
        setFarmerData([]);
      }
    );
  };

  useEffect(() => {
    if (success !== '') {
      message.success(success);
    }
  }, [success]);
  useEffect(() => {
    if (errorMsg !== '') {
      message.error(errorMsg);
    }
  }, [errorMsg]);
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  useEffect(() => {
    setPageLoaded(true);
  }, []);
  useEffect(() => {
    LoadData();
  }, [currentPage, pageSize]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);
  }, []);
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<agentType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined rev="icon-rev" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        rev="icon-rev"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns: ColumnsType<agentType> = [
    {
      title: 'SN',
      dataIndex: 'key',
      key: 'key',
      ...getColumnSearchProps('key'),
    },
    {
      title: 'Farmer ID',
      dataIndex: 'uuid',
      key: 'uuid',
      ...getColumnSearchProps('uuid'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      ...getColumnSearchProps('accountNumber'),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      key: 'bank',
      ...getColumnSearchProps('bank'),
    },
  ];

  // Define a function to render a page of data
  const renderPage = (pageData: any, page: string) => {
    return (
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rev="icon-rev" rotate={isActive ? 90 : 0} />
        )}
        style={{ background: token.colorBgContainer }}
      >
        {pageData.map((option: any, index: number) => (
          <Panel
            header={option.firstName + ' ' + option.lastName}
            key={index}
            style={panelStyle}
          >
            <List
              size="small"
              footer={
                <>
                  <Space></Space>
                </>
              }
              dataSource={[
                'Name: ' + option.name,
                'Phone: ' + option.phone,
                'Email: ' + option.email,
                'Account Number: ' + option.accountNumber,
              ]}
              bordered
              renderItem={(item: any) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <>
      {PageLoaded ? (
        <div className="App">
          <Layout style={{ minHeight: '100vh' }}>
            <CustomSideBar />
            <Layout className="site-layout">
              <TopBar />
              <Content style={{ margin: '20px' }}>
                <div style={{ overflowX: 'auto' }}></div>
                <Divider />

                <Tooltip style={{ margin: '5px' }} title="Export Excel">
                  <Button
                    onClick={() => {
                      exportExcel();
                    }}
                  >
                    Export Excel
                    <FileExcelOutlined rev="icon-rev" />
                  </Button>
                </Tooltip>
                <Tooltip style={{ margin: '5px' }} title="Export Excel">
                  <Button
                    onClick={() => {
                      generatePDF();
                    }}
                  >
                    Export PDF
                    <FilePdfOutlined rev="icon-rev" />
                  </Button>
                </Tooltip>
                <Divider />
                <div style={{ margin: '5px' }} title="Export Excel">
                  <Input onChange={onChange} />
                  <Button
                    onClick={() => {
                      setBatch();
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <Divider />

                <div id="report">
                  {!isMobile ? (
                    <Table
                      columns={columns}
                      dataSource={farmerData}
                      scroll={{ x: 'max-content' }}
                      pagination={false}
                    />
                  ) : (
                    <>{renderPage(farmerData, 'order')}</>
                  )}
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="login-form-wrapper">
          <ScaleLoader
            color={'#fff'}
            loading={!PageLoaded}
            // size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};
export default FarmerAccount;
