import React, { useState, useEffect } from 'react';
import { formatDate } from '../../../common/utility';
import {
  Button,
  Layout,
  Card,
  message,
  Modal,
  Popconfirm,
  Space,
  Select,
  InputNumber,
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './component/loadRecordComponent';
import { MinusCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import UserService from '../../../services/user.service';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomSideBar from '../inc/CustomSideBar';
import TopBar from '../inc/TopBar';
import '../../../asset/style.css';
import RoleHooks from '../../../hooks/verifyRole';
import LoadRecordComponent from './component/loadRecordComponent';
import './style/style.css';
const { Meta } = Card;

const { Content } = Layout;
const { permissionsData } = RoleHooks();

interface Calendar {
  id: number;
  title: string;
  maxDuration: number;
  farmCalendarMetaData: any;
}
interface Product {
  value: number;
  label: string;
}
interface Meta {
  key?: number | null;
  id?: number | null;
  activityId?: number | null;
  farmCalendarID: number;
  FarmCalendarActivity?: any;
}
interface Locations {
  id: number;
  name: string;
  description: string;
  totalNumberOfHectare: number;
  farmCalendarID: number;
  startDate: string;
  farmCalendar: any;
}
const CalendarViewComponent = () => {
  const [loading, setLoading] = useState(false);

  const [openAddData, setOpenAddData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editCalendarId, setEditCalendarId] = useState<number | null>(null);
  const [currentLocation, setCurrentLocation] = useState<Locations>();
  const [selectedMeta, setSelectedMeta] = useState('');
  const [numberOfHectare, setNumberOfHectare] = useState(0);
  const [listCalendarMeta, setListCalendarMeta] = useState<Product[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<Calendar>();
  const [listCalendarData, setListCalendarData] = useState<Meta[]>([]);
  const [calendarMeta, setCalendarMeta] = useState<Meta[]>([]);
  const [locations, setLocations] = useState<Locations[]>([]);
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [content, setContent] = useState('');
  const handleChange = (value: any) => {
    setContent(value);
  };
  const toolbarOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['color'], // Add color option
      [{ align: [] }], // Add align options
      ['clean'],
    ],
  };
  const handleChangeHectare = (e: any) => {
    setNumberOfHectare(e);
  };

  useEffect(() => {
    fetchLocation();
  }, []);
  const colorSetter = (originalRgbaColor: string, opacity: any) => {
    const percent = opacity / 100;
    if (percent > 1) {
      opacity = 1;
    } else {
      opacity = percent.toFixed(2);
    }
    const rgbaParts = originalRgbaColor.match(/\d+/g);

    if (rgbaParts && rgbaParts.length >= 4) {
      const updatedRgbaColor = `rgba(${rgbaParts[0]}, ${rgbaParts[1]}, ${rgbaParts[2]}, ${opacity})`;
      return updatedRgbaColor;
    } else if (rgbaParts && rgbaParts.length === 3) {
      const updatedRgbaColor = `rgba(${rgbaParts[0]}, ${rgbaParts[1]}, ${rgbaParts[2]}, ${opacity})`;
      return updatedRgbaColor;
    }
  };

  const handleSetCalendar = (calendare: Calendar) => {
    setEditCalendarId(calendare.id);
    fetchCalendarMeta(calendare.id);
    handleViewCalendarRecord(calendare.id);
    setSelectedCalendar(calendare);
  };

  const fetchLocation = () => {
    UserService.GetRecord(
      'calender/location/assign/user/?mylocation=true'
    ).then(
      (response: any) => {
        if (response.data.data.length === 1) {
          setCurrentLocation(response.data.data[0].FarmCalendarLocation);
          handleSetCalendar(
            response.data.data[0].FarmCalendarLocation.farmCalendar
          );
        } else {
          setLocations(response.data.data);
        }
        setLocations(response.data.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const fetchCalendarMeta = (id: number) => {
    UserService.GetRecord('calender/calendar-meta/' + id).then(
      (response: any) => {
        setListCalendarMeta(
          response.data.data.map((option: any) => ({
            label: option.FarmCalendarActivity.name,
            value: option.id,
          }))
        );
        setCalendarMeta(response.data.data);
      }
    );
  };
  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };
  const handleAddCalendarRecord = () => {
    UserService.PostRecord(
      {
        selectedDate,
        farmCalendarMetaDataID: selectedMeta,
        numberOfHectare: numberOfHectare,
        farmCalendarLocationID: currentLocation ? currentLocation.id : 0,
        content,
      },
      'calender/calendar-data'
    ).then(
      (response: any) => {
        handleViewCalendarRecord(editCalendarId);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const handleDeleteCalendarRecord = (id: number) => {
    UserService.DeleteRecord('calender/calendar-data/' + id).then(
      (response: any) => {
        message.success(response.data.message);
        handleViewCalendarRecord(editCalendarId);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const handleViewCalendarRecord = (calendarID: any) => {
    UserService.GetRecord('calender/calendar-data/' + calendarID).then(
      (response: any) => {
        setListCalendarData(response.data.data);
        message.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error(resMessage);
      }
    );
  };
  const addMonthsToDate = (date: string, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate
      .toLocaleString('en-US', { year: 'numeric', month: 'long' })
      .replace(' ', '');
  };

  const getMonthData = (metaID: number, selectMonth: string) => {
    const arrayData = [...listCalendarData];
    // Create an object to hold grouped records
    const recordsByMonthAndWeek: any = {};
    let i = 0;

    // Loop through arrayData and group and filter records by month and farmCalendarID
    arrayData.forEach((data: any) => {
      if (data.farmCalendarMetaDataID === metaID) {
        const createdAt = new Date(data.createdAt);
        const monthYear = createdAt
          .toLocaleString('en-US', { year: 'numeric', month: 'long' })
          .replace(' ', '');
        const weekNumber = Math.floor((createdAt.getDate() - 1) / 7) + 1; // Calculate week number
        if (!recordsByMonthAndWeek[monthYear]) {
          recordsByMonthAndWeek[monthYear] = {};
        }
        if (!recordsByMonthAndWeek[monthYear][weekNumber]) {
          recordsByMonthAndWeek[monthYear][weekNumber] = [];
        }
        recordsByMonthAndWeek[monthYear][weekNumber].push(data);
        i++;
      }
    });
    let weekRecord = [];
    for (const key in recordsByMonthAndWeek[selectMonth]) {
      let weekTotal = 0;
      for (var j = 0; j < recordsByMonthAndWeek[selectMonth][key].length; j++) {
        weekTotal += parseFloat(
          recordsByMonthAndWeek[selectMonth][key][j]['numberOfHectare']
        );
      }
      weekRecord.push(weekTotal);
    }
    console.log(selectMonth, weekRecord);

    return weekRecord;
  };
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <CustomSideBar />
        <Layout className="site-layout">
          <TopBar />
          <Content style={{ margin: '20px' }}>
            <h1>View Calender</h1>
            <div className="m-5"></div>
            {loading && (
              <ScaleLoader
                color={'#000'}
                loading={true}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            {!viewDetails && (
              <>
                <div className="card-body">
                  {currentLocation && (
                    <>
                      <Space direction="vertical">
                        <Button
                          onClick={() => {
                            setOpenAddData(true);
                          }}
                        >
                          Add Record
                        </Button>
                      </Space>
                      <div style={{ marginBottom: '10px' }}></div>
                      <div style={{ overflowX: 'auto' }}>
                        <table
                          border={1}
                          style={{
                            borderCollapse: 'collapse',
                            width: '100%',
                            border: '1px solid #ccc',
                          }}
                        >
                          <thead>
                            <tr>
                              <th></th>

                              {selectedCalendar &&
                                selectedCalendar.maxDuration && (
                                  <>
                                    {Array.from(
                                      { length: selectedCalendar.maxDuration },
                                      (_, index) => (
                                        <th>
                                          M{index + 1}{' '}
                                          <small>
                                            {currentLocation &&
                                              addMonthsToDate(
                                                currentLocation.startDate,
                                                index
                                              )}
                                          </small>
                                        </th>
                                      )
                                    )}
                                  </>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              {selectedCalendar &&
                                selectedCalendar.maxDuration && (
                                  <>
                                    {Array.from(
                                      { length: selectedCalendar.maxDuration },
                                      (_, index) => (
                                        <td>
                                          <tr style={{ width: '100%' }}>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W1
                                            </td>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W2
                                            </td>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W3
                                            </td>
                                            <td
                                              style={{
                                                width: '25%',
                                                margin: '10%',
                                              }}
                                            >
                                              W4
                                            </td>
                                          </tr>
                                        </td>
                                      )
                                    )}
                                  </>
                                )}
                            </tr>
                            <tr>
                              <td>
                                <b>Activity</b>
                              </td>
                              {selectedCalendar &&
                                selectedCalendar.maxDuration &&
                                Array.from(
                                  { length: selectedCalendar.maxDuration },
                                  (_, index) => <td></td>
                                )}
                            </tr>
                            {calendarMeta &&
                              calendarMeta.map((meta: any) => {
                                let activityNo = 0;

                                return (
                                  <tr>
                                    <td>{meta.FarmCalendarActivity.name}</td>
                                    {selectedCalendar &&
                                      currentLocation &&
                                      selectedCalendar.maxDuration &&
                                      Array.from(
                                        {
                                          length: selectedCalendar.maxDuration,
                                        },
                                        (_, index) => (
                                          <td>
                                            <tr>
                                              {getMonthData(
                                                meta.id,
                                                addMonthsToDate(
                                                  currentLocation.startDate,
                                                  index
                                                )
                                              ).map((value) => {
                                                let currentLoc =
                                                  currentLocation &&
                                                  (
                                                    (value /
                                                      currentLocation.totalNumberOfHectare) *
                                                    100
                                                  ).toFixed();
                                                activityNo = currentLoc
                                                  ? parseFloat(currentLoc) +
                                                    activityNo
                                                  : 0;
                                                return (
                                                  <td
                                                    style={{
                                                      backgroundColor:
                                                        colorSetter(
                                                          meta
                                                            .FarmCalendarActivity
                                                            .color,
                                                          activityNo
                                                        ),
                                                      padding: '5px',
                                                    }}
                                                  >
                                                    {activityNo}%
                                                  </td>
                                                );
                                              })}
                                            </tr>
                                          </td>
                                        )
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                    </>
                  )}
                </div>
                <Modal
                  key="addCalendarData"
                  title="Calendar Data Entry"
                  centered
                  open={openAddData}
                  footer={[
                    <Button
                      loading={isSubmitting}
                      key="save"
                      onClick={() => handleAddCalendarRecord()}
                    >
                      Add Data
                    </Button>,
                    <Button onClick={() => setViewDetails(true)}>
                      View Details <PlayCircleOutlined rev />{' '}
                    </Button>,
                    <Button key="cancel" onClick={() => setOpenAddData(false)}>
                      Cancel
                    </Button>,
                  ]}
                >
                  <div style={{ marginBottom: '10px' }}></div>
                  {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> */}
                  <span style={{ marginRight: '8px', textAlign: 'left' }}>
                    Select Activity
                  </span>
                  <Select
                    showSearch
                    placeholder="Select activity"
                    optionFilterProp="children"
                    options={listCalendarMeta}
                    style={{ width: '100%' }}
                    onChange={(value) => setSelectedMeta(value)}
                  />
                  <div style={{ marginBottom: '10px' }}></div>
                  <InputNumber
                    addonBefore="Hectare"
                    placeholder="Number of Hectare"
                    style={{ width: '100%' }}
                    onChange={handleChangeHectare}
                  />

                  {permissionsData.includes('allow-backdate-calendar') && (
                    <>
                      <div style={{ marginBottom: '10px' }}></div>
                      <input
                        type="date"
                        placeholder="Date"
                        style={{ maxWidth: '100px;' }}
                        onChange={handleDateChange}
                      />
                    </>
                  )}
                  <div style={{ marginBottom: '10px' }}></div>

                  <ReactQuill
                    value={content}
                    onChange={handleChange}
                    modules={toolbarOptions}
                  />
                  <div style={{ marginBottom: '10px' }}></div>

                  {listCalendarData &&
                    listCalendarData.map((data: any) => (
                      <div style={{ margin: '5px', textAlign: 'left' }}>
                        <span style={{ marginRight: '8px' }}>
                          <Popconfirm
                            title="Delete Record"
                            description="Are you sure you want to delete this record?"
                            onConfirm={() =>
                              handleDeleteCalendarRecord(data.id)
                            }
                            onOpenChange={() => console.log('open change')}
                          >
                            {' '}
                            <MinusCircleOutlined
                              rev="rev-icon"
                              style={{ fontSize: '16px', color: '#FF0000' }}
                            />
                          </Popconfirm>{' '}
                          {data.farmCalendarMetaDatum.FarmCalendarActivity.name}{' '}
                          - {data.numberOfHectare} - (
                          {formatDate(data.createdAt)}){' '}
                        </span>
                      </div>
                    ))}
                  {/* </div> */}
                </Modal>
              </>
            )}
            {viewDetails && (
              <LoadRecordComponent propsList={listCalendarData} />
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default CalendarViewComponent;
